export const CAS_LOGIN = "CAS_LOGIN";
export const GLOBUS_LOGIN = "GLOBUS_LOGIN";
export const LOG_OUT = "LOG_OUT";
export const GUEST_LOGIN = "GUEST_LOGIN";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const REGISTRATION_REQUIRED = "REGISTRATION_REQUIRED";
export const REGISTRATION_COMPLETED = "REGISTRATION_COMPLETED";
export const REGISTRATION_FAILED = "REGISTRATION_FAILED";
export const SET_EMAIL = "SET_EMAIL";
export const SET_NAME = "SET_NAME";
export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const USER_REGISTER = "USER_REGISTER";

export const DATSETS_CATALOG = {
    "@context": "https://schema.org",
    "@id": "https://p2irc-data-dev.usask.ca/",
    "@type": "DataCatalog",
    "dct:conformsTo": "https://p2irc-data-dev.usask.ca/welcome",
    description: "This catalog is created by Moksedul Islam Mishuk",
    keywords: ["P2irc", "SciDataManager", "scidatamanager"],
    name: "SciDataManager",
    alternateName: ["p2irc data dev"],
    provider: [],
    identifier: ["https://p2irc-data-dev.usask.ca/"],
    url: "https://p2irc-data-dev.usask.ca/",
  
    dataset: [
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/113",
        name: "p2irc default dataset",
        description:
          "This dataset will show the default view of the p2irc dataset.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/113"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/113",
        keywords: ["p2irc", "default", "dataset"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/12",
        name: "Rice Tetep",
        description:
          "The Rice Tetep dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/12"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/12",
        keywords: ["Rice", "Tetep"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/13",
        name: "Rice R600",
        description: "The Rice R600 dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/13"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/13",
        keywords: ["Rice", "R600"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/14",
        name: "Maize B73 RefGen_v4",
        description:
          "The Maize B73 RefGen_v4 dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/14"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/14",
        keywords: ["Maize", "B73", "RefGen_v4"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/15",
        name: "Maize B73 RefGen_v41",
        description:
          "The Maize B73 RefGen_v41 dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/15"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/15",
        keywords: ["Maize", "B73", "RefGen_v41"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/16",
        name: "Maize Zm-B73_AB10-REFERENCE-NAM-1.0b",
        description:
          "Maize Zm-B73_AB10-REFERENCE-NAM-1.0b dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/16"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/16",
        keywords: ["Maize", "Zm-B73_AB10-REFERENCE-NAM-1.0b"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/17",
        name: "Rice LIU XU__IRGC 109232-1",
        description:
          "Rice LIU XU__IRGC 109232-1.0b dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/17"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/17",
        keywords: ["Rice", "LIU XU__IRGC 109232-1"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/18",
        name: "PHIS Maize Field Dataset",
        description:
          "PHIS Maize Field Dataset dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/18"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/18",
        keywords: ["PHIS", "Maize", "Field Dataset"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/19",
        name: "Maize Zm-B73-REFERENCE-NAM-5.0",
        description:
          "Maize Zm-B73-REFERENCE-NAM-5.0 dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/19"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/19",
        keywords: ["Maize", "Zm-B73-REFERENCE-NAM-5.0"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/20",
        name: "PHIS Maize Indoor Image Dataset",
        description:
          "PHIS Maize Indoor Image Dataset dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/20"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/20",
        keywords: ["Maize", "PHIS", "Indoor", "Image Dataset"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/21",
        name: "P2IRC Canola Root Image Dataset",
        description:
          "P2IRC Canola Root Image Dataset dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/21"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/21",
        keywords: ["Root", "Canola", "Image Dataset"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/22",
        name: "Maize Zm-CML69-REFERENCE-NAM-1.0",
        description:
          "Maize Zm-CML69-REFERENCE-NAM-1.0 dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/22"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/22",
        keywords: ["Maize", "Zm-CML69-REFERENCE-NAM-1.0"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/30",
        name: "P2IRC PlotVision Dataset",
        description:
          "P2IRC PlotVision Dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/30"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/30",
        keywords: ["P2IRC", "PlotVision"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/54",
        name: "1001 Genomes SNP Short Indel Tair 100",
        description:
          "1001 Genomes SNP Short Indel Tair 100 dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/54"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/54",
        keywords: ["1001", "Genomes", "SNP", "Short", "Indel", "Tair", "100"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/55",
        name: "2020-06-23 Lentil Herbicide Trial 1",
        description:
          "2020-06-23 Lentil Herbicide Trial 1 dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/55"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/55",
        keywords: ["Lentil", "Herbicide", "Trial 1"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/63",
        name: "VizSciFlow Sequence Dataset",
        description:
          "VizSciFlow Sequence Dataset dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/63"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/63",
        keywords: ["VizSciFlow", "Sequence Dataset"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/67",
        name: "P2IRC Flagship 1 Data",
        description:
          "P2IRC Flagship 1 Data dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/67"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/67",
        keywords: ["P2IRC", "Flagship 1", "Data"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/74",
        name: "Wheat ChineseSpring",
        description:
          "Wheat ChineseSpring dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/74"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/74",
        keywords: ["Wheat", "ChineseSpring"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/78",
        name: "Elasticsearch Testing",
        description:
          "Elasticsearch Testing dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/78"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/78",
        keywords: ["Elasticsearch", "Testing"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/79",
        name: "DevDataset",
        description: "DevDataset dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/79"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/79",
        keywords: ["DevDataset"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
  
      {
        "@context": "https://schema.org/",
        "@type": "Dataset",
        "dct:conformsTo": "https://p2irc-data-dev.usask.ca/dataset/97",
        name: "Mishuk",
        description: "Mishuk dataset is created by Moksedul Islam Mishuk.",
        identifier: ["https://p2irc-data-dev.usask.ca/dataset/97"],
        url: "https://p2irc-data-dev.usask.ca",
        sameAs: "https://p2irc-data-dev.usask.ca/dataset/97",
        keywords: ["Mishuk"],
        license: "https://creativecommons.org/publicdomain/zero/1.0/",
        isAccessibleForFree: true,
        creator: {
          "@type": "Person",
          url: "https://p2irc-data-dev.usask.ca/users",
          name: "Moksedul Islam",
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            telephone: "+8801780575414",
            email: "moksedulislammishuk@gmail.com",
          },
        },
        funder: {
          "@type": "Person",
          sameAs: "https://p2irc-data-dev.usask.ca/",
          name: "Moksedul Islam",
        },
        includedInDataCatalog: {
          "@type": "DataCatalog",
          name: "data.gov",
        },
        temporalCoverage: "2021-11-24/..",
        spatialCoverage: {
          "@type": "Place",
          geo: {
            "@type": "GeoCoordinates",
            latitude: 23.753139,
            longitude: 90.408211,
          },
        },
      },
    ],
  };
  
import React, { Fragment } from "react";
import { Row, Col, Card } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import { InputGroup, Button, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCaretSquareUp,
  faCaretSquareDown,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  updateSearchText,
  hitSearch,
  updateSelectedFilterType,
  updateSelectedFilterValueSelections,
  removeFilters,
  handleYearUpdate,
  searchDropDownFilter,
  searchModal,
  addFilter,
  getFilters,
  cancleFilter,
  getMapLocations,
  removeMapArea,
} from "../../store/searchStore/searchActions";
import { connect } from "react-redux";
import { filterTypes } from "../../store/searchStore/searchActions";
import Select from "react-select";
import PieChartComp from "./pieChart";
import BarChartComp from "./barChart";
import RangeSlider from "./histoslider/RangeSlider";

import MapView, { locationDDL } from "./map/mapView";
import NewFilter from "./newFilter";
import {
  HOME_DATASETS,
  WELCOME_PUBLIC_SEARCH_DATASETS,
} from "../../constants/routes";
import UserBarChart from "./configFilter/barChart";
import UserPieChart from "./configFilter/pieChart";
import { parseFloatFixed } from "../../helperMethods/index";
import { setFilterPanelHeight } from "../../store/uiStore/uiActions";


class Search extends React.Component {
  constructor(props) {
    super(props);
    this.sci_filter = React.createRef();
    this.state = {
      showDropdownFilter: false,
      selectedFilters: [],
      searchText: this.props.searchStore.searchText,
      currUserSearches: [
        // {
        //   type: "crops",
        //   view: "bar",
        // },
        // {
        //   type: "mimetypes",
        //   view: "pie",
        // },
        // {
        //   type: "mimetypes",
        //   view: "bar",
        // },
        // {
        //   type: "crops",
        //   view: "pie",
        // },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    this.props.setFilterPanelHeight(
      this.sci_filter.current.offsetTop + this.sci_filter.current.offsetHeight
    );
  }

  toggleFilter = () => {
    if (!this.props.searchStore.showDropdownFilter) {
      this.props.loadFilters();
    }
    this.props.getMapLocations();
    this.props.searchDropDownFilter();
  };

  renderFilterValueSelections = (searchFilters) => {
    let {
      selectedMimetypes,
      selectedYears,
      selectedLocation,
      crops,
      mimeTypes,
      searchArea,
    } = searchFilters;

    const copyCrops = crops.slice(0, 5);
    const copyMimeTypes = mimeTypes.slice(0, 5);
    return (
      <Col xs={24} sm={24} md={24} lg={24}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {selectedMimetypes.length > 0 && (
            <Col xs="auto" sm="auto" md="auto" lg="auto" className="filter">
              <label>FileType:</label>
              {selectedMimetypes.map((mimetype) => {
                return <label className="filters-label">{mimetype}</label>;
              })}
              <Button
                onClick={() => this.removeFilter(filterTypes.fileType)}
                title="Remove"
                style={{
                  background: "transparent",
                  color: "black",
                  padding: "2px 5px",
                  border: "none",
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{ color: "red" }}
                  onClick={() => this.removeFilter(filterTypes.fileType)}
                />
              </Button>
            </Col>
          )}
          {mimeTypes.length > 0 && (
            <Col xs="auto" sm="auto" md="auto" lg="auto" className="filter">
              <label>File types:</label>
              <label className="filters-label">
                {copyMimeTypes.map((mimeType, index) =>
                  index === copyMimeTypes.length - 1
                    ? mimeType
                    : mimeType + ", "
                )}{" "}
              </label>
              <FontAwesomeIcon
                type="button"
                icon={faTimesCircle}
                style={{ color: "red" }}
                onClick={() => this.removeFilter(filterTypes.mimeTypes)}
                title="Remove"
              ></FontAwesomeIcon>
            </Col>
          )}

          {selectedYears.length > 0 && (
            <Col xs="auto" sm="auto" md="auto" lg="auto" className="filter">
              <label>Year:</label>
              <label className="filters-label">
                {selectedYears[0] === selectedYears[1]
                  ? selectedYears[0]
                  : `${selectedYears[0]} - ${selectedYears[1]}`}
              </label>
              <FontAwesomeIcon
                type="button"
                icon={faTimesCircle}
                style={{
                  color: this.props.searchStore.initYear ? "grey" : "red",
                }}
                onClick={() => this.removeFilter(filterTypes.year)}
                title="Remove"
              ></FontAwesomeIcon>
            </Col>
          )}

{selectedLocation && searchArea === null && (
            <Col xs="auto" sm="auto" md="auto" lg="auto" className="filter">
              <label>Location:</label>
              <label className="filters-label">{selectedLocation.label} </label>
              <FontAwesomeIcon
                type="button"
                icon={faTimesCircle}
                style={{
                  color: selectedLocation.label === "All" ? "grey" : "red",
                }}
                onClick={() => this.removeFilter(filterTypes.location)}
                title="Remove"
              ></FontAwesomeIcon>
            </Col>
          )}
          {searchArea && (
            <Col xs="auto" sm="auto" md="auto" lg="auto" className="filter">
              <label>Location:</label>
              <label className="filters-label">
                {" "}
                {`S-W: (${parseFloatFixed(searchArea[0][0])}, ${parseFloatFixed(
                  searchArea[0][1]
                )}), N-E: (${parseFloatFixed(
                  searchArea[1][0]
                )}, ${parseFloatFixed(searchArea[1][1])})`}
              </label>
              <FontAwesomeIcon
                type="button"
                icon={faTimesCircle}
                style={{
                  color: "red",
                }}
                onClick={() => this.props.removeMapArea()}
                title="Remove"
              ></FontAwesomeIcon>
            </Col>
          )}
          {crops.length > 0 && (
            <Col xs="auto" sm="auto" md="auto" lg="auto" className="filter">
              <label>Crops:</label>
              <label className="filters-label">
                {copyCrops.map((crop, index) =>
                  index === copyCrops.length - 1 ? crop : crop + ", "
                )}{" "}
              </label>
              <FontAwesomeIcon
                type="button"
                icon={faTimesCircle}
                style={{ color: "red" }}
                onClick={() => this.removeFilter(filterTypes.crops)}
                title="Remove"
              ></FontAwesomeIcon>
            </Col>
          )}
        </Row>
      </Col>
    );
  };

  searchTextChangeHandler = (e) => {
    this.setState({ searchText: e.target.value });
    // this.props.updateSearchText(e.target.value);
  };

  searchTextChangeHandler = (e) => {
    this.setState({ searchText: e.target.value });
    // this.props.updateSearchText(e.target.value);
  };

  searchOnEnterHit = (e) => {
    if (e.keyCode === 13) {
      this.props.updateSearchText(this.state.searchText);
      this.hitSearch();
    }
  };

  hitSearch = () => {
    this.props.updateSearchText(this.state.searchText);
    this.props.hitSearch();
  };

  handleFilterTypesSelection = (selectedOption) => {
    this.props.updateSelectedFilterType(selectedOption);
  };

  handleChangeMapLocation = (selectedOptions) => {
    this.props.updateSelectedFilterValueSelections(selectedOptions, "location");
    this.props.removeMapArea();
  };

  removeFilter = (filterType) => {
    this.props.removeFilters(filterType);
  };

  searchModal = () => {
    // this.toggleFilter()
    this.props.searchModal();
    this.props.hitSearch();
    // this.props.searchDropDownFilter()
  };

  appendFilter = (newFilter) => {
    this.props.addFilter(newFilter);
  };

  render() {
    // const { crops, mimetypes } = this.props.searchStore;
    const { searchArea } = this.props.searchStore.searchFilters;

    return (
      <div className="mb-3">
        {this.props.visualization && (
          <Row className="search">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-1">
              <InputGroup size="lg">
                <FormControl
                  type="text"
                  placeholder="Search in SciDataManager"
                  value={
                    this.props.tour.tourStatus
                      ? "Flagship"
                      : this.state.searchText
                  }
                  onChange={(e) => this.searchTextChangeHandler(e)}
                  onKeyUp={(e) => this.searchOnEnterHit(e)}
                />
                <InputGroup.Prepend>
                  <Button
                    title="Search"
                    onClick={this.hitSearch}
                    style={{ margin: 0 }}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </InputGroup.Prepend>
                {this.props.location?.pathname === HOME_DATASETS ||
                this.props.location?.pathname ===
                  WELCOME_PUBLIC_SEARCH_DATASETS ? (
                  <InputGroup.Prepend>
                    <Button
                      title="Filter"
                      variant={
                        this.props.searchStore.yearDomain
                          ? "success"
                          : "primary"
                      }
                      onClick={this.toggleFilter}
                      style={{ margin: 0 }}
                    >
                      <FontAwesomeIcon
                        icon={
                          this.props.searchStore.showDropdownFilter ||
                          this.props.tour.showDropdownFilter
                            ? faCaretSquareUp
                            : faCaretSquareDown
                        }
                      ></FontAwesomeIcon>
                    </Button>
                  </InputGroup.Prepend>
                ) : null}
              </InputGroup>
            </Col>
          </Row>
        )}
        <div ref={this.sci_filter}>
          {(this.props.searchStore.showDropdownFilter ||
            this.props.tour.showDropdownFilter) && (
            <Fragment>
              {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40}} className='mb-1' style={{ padding: '1em 0', margin: '0 1em', background: '#add8e675', borderRadius: '.3rem' }}> */}
              {/* <Col xs={8} sm={8} md={8} lg={8} className='gutter-row'>
                                    <Select
                                        value={this.props.searchStore.selectedFilterType}
                                        onChange={this.handleFilterTypesSelection}
                                        options={this.props.searchStore.filterTypes}
                                        isMulti={false}
                                        isSearchable={true}
                                        styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                        placeholder='Select filter type'
                                    />
                                </Col>

                                <Col xs={16} sm={16} md={16} lg={16} className='gutter-row'>
                                    <Select
                                        value={this.props.searchStore.selectedFilterValueSelections}
                                        onChange={this.handleSelectedFilterValueSelections}
                                        options={this.props.searchStore.filterValueSelections}
                                        isMulti={true}
                                        isSearchable={true}
                                        styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                        placeholder='Select filter values'
                                    />
                                </Col> */}
              <div
                className="scrolling-wrapper mb-1"
                style={{
                  padding: "1em 0",
                  margin: "0 1em",
                  background: "#add8e675",
                  borderRadius: ".3rem",
                }}
              >
                <Card
                  size="small"
                  bordered={false}
                  title="Filter by File Type"
                  style={{ width: "38em" }}
                >
                  <BarChartComp />
                </Card>

                <Card
                  size="small"
                  bordered={false}
                  title="Filter by Year"
                  style={{ width: "38em", height: "313px" }}
                  extra={
                    <div style={{ display: "flex" }}>
                      {this.props.searchStore.yearUpdate && (
                        <span
                          style={{
                            backgroundColor: "#e6f7ff",
                            border: "1px solid #91d5ff",
                            padding: "0.1em 1em",
                            margin: "0 1em",
                          }}
                        >
                          {this.props.searchStore.yearUpdate[0] !==
                          this.props.searchStore.yearUpdate[1]
                            ? `${this.props.searchStore.yearUpdate[0]} - ${this.props.searchStore.yearUpdate[1]}`
                            : `${this.props.searchStore.yearUpdate[0]}`}
                        </span>
                      )}
                      <Button
                        type="button"
                        variant="outline-secondary"
                        className="btn btn-sm"
                        onClick={() =>
                          this.props.handleYearUpdate({
                            yearUpdate: this.props.searchStore.yearDomain,
                            yearValues: this.props.searchStore.yearDomain,
                          })
                        }
                      >
                        Reset
                      </Button>
                    </div>
                  }
                >
                  <RangeSlider />
                </Card>

                <Card
                  size="small"
                  bordered={false}
                  title="Filter by Location"
                  className="locationDDL"
                  extra={
                    <Select
                      value={this.props.searchStore.selectedLocation}
                      onChange={this.handleChangeMapLocation}
                      options={this.props.searchStore.datasetLocations}
                      isSearchable={true}
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      placeholder="Select Location"
                      // isDisabled={searchArea === null ? false : true}
                    />
                  }
                  style={{ width: "38em" }}
                >
                  <MapView />
                </Card>

                <Card
                  size="small"
                  bordered={false}
                  title="Filter by Crop"
                  style={{ width: "38em" }}
                >
                  <PieChartComp />
                </Card>
                {this.props.searchStore.filters.map((currUserSearch) => {
                  if (currUserSearch.filterType === "filetype") {
                    if (currUserSearch.view === "bar") {
                      return (
                        <UserBarChart
                          name={currUserSearch.name}
                          color={currUserSearch.color}
                          chartData={currUserSearch.subFilterType}
                          filterId={currUserSearch.id}
                        />
                      );
                    } else {
                      return (
                        <UserPieChart
                          name={currUserSearch.name}
                          color={currUserSearch.color}
                          chartData={currUserSearch.subFilterType}
                          filterId={currUserSearch.id}
                        />
                      );
                    }
                  } else if (currUserSearch.filterType === "crop") {
                    if (currUserSearch.view === "bar") {
                      return (
                        <UserBarChart
                          name={currUserSearch.name}
                          color={currUserSearch.color}
                          chartData={currUserSearch.subFilterType}
                          filterId={currUserSearch.id}
                        />
                      );
                    } else {
                      return (
                        <UserPieChart
                          name={currUserSearch.name}
                          color={currUserSearch.color}
                          chartData={currUserSearch.subFilterType}
                          filterId={currUserSearch.id}
                        />
                      );
                    }
                  } else if (currUserSearch.filterType === "keyword") {
                    if (currUserSearch.view === "bar") {
                      return (
                        <UserBarChart
                          name={currUserSearch.name}
                          color={currUserSearch.color}
                          chartData={currUserSearch.subFilterType}
                          filterId={currUserSearch.id}
                        />
                      );
                    } else {
                      return (
                        <UserPieChart
                          name={currUserSearch.name}
                          color={currUserSearch.color}
                          chartData={currUserSearch.subFilterType}
                          filterId={currUserSearch.id}
                        />
                      );
                    }
                  }
                })}

                {this.props.auth.isAuthenticated && (
                  <Card
                    title="New filter"
                    size="small"
                    bordered={false}
                    style={{ width: "38em" }}
                  >
                    <NewFilter appendFilter={this.appendFilter} />
                  </Card>
                )}
              </div>

              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}
                className="mb-1"
                style={{ margin: "0em", fontSize: "small" }}
              >
                <Col>
                  {this.renderFilterValueSelections(
                    this.props.searchStore.searchFilters
                  )}
                </Col>
              </Row>
              {!this.props.searchStore.searchModalIsActive && (
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}
                  className="mb-1"
                  style={{ margin: "0em", fontSize: "small" }}
                >
                  <Col
                    style={{
                      position: "absolute",
                      right: "60px",
                      top: "522px",
                    }}
                    className="search-modal-button"
                  >
                    <Button
                      title="Clear Filter"
                      className="btn btn-danger btn__icon"
                      onClick={() => this.props.cancleFilter()}
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="filter-circle-xmark"
                        className="svg-inline--fa fa-filter-circle-xmark"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        // style={{marginTop: "6px"}}
                      >
                        <path
                          fill="currentColor"
                          d="M382.8 199.1l121.9-146.1C521.1 31.28 506.8 0 479.3 0H32.7C5.213 0-9.965 31.28 7.375 52.19L192 274.8V368c0 7.828 3.812 15.17 10.25 19.66l66.88 46.8C260.7 413.9 256 391.5 256 368C256 288.1 309.6 220.5 382.8 199.1zM432 224C352.5 224 288 288.5 288 368s64.47 144 144 144s144-64.47 144-144S511.5 224 432 224zM488.5 401.9c6.242 6.242 6.252 16.37 .0098 22.62c-6.24 6.242-16.37 6.231-22.62-.0113l-33.91-33.91l-33.91 33.91c-6.242 6.242-16.37 6.253-22.62 .0106s-6.232-16.37 .0098-22.62l33.91-33.91l-33.91-33.91c-6.242-6.242-6.251-16.37-.009-22.62s16.37-6.232 22.62 .0106l33.91 33.91l33.91-33.91c6.242-6.242 16.37-6.254 22.61-.0113s6.233 16.37-.009 22.62l-33.91 33.91L488.5 401.9z"
                        ></path>
                      </svg>
                    </Button>
                  </Col>
                  <Col
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "522px",
                    }}
                    className="search-modal-button"
                  >
                    <Button
                      className="btn__icon"
                      title="In new window"
                      variant="primary"
                      disabled={this.props.tourStatus}
                      onClick={this.searchModal}
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="up-right-from-square"
                        className="svg-inline--fa fa-up-right-from-square"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M384 320c-17.67 0-32 14.33-32 32v96H64V160h96c17.67 0 32-14.32 32-32s-14.33-32-32-32L64 96c-35.35 0-64 28.65-64 64V448c0 35.34 28.65 64 64 64h288c35.35 0 64-28.66 64-64v-96C416 334.3 401.7 320 384 320zM488 0H352c-12.94 0-24.62 7.797-29.56 19.75c-4.969 11.97-2.219 25.72 6.938 34.88L370.8 96L169.4 297.4c-12.5 12.5-12.5 32.75 0 45.25C175.6 348.9 183.8 352 192 352s16.38-3.125 22.62-9.375L416 141.3l41.38 41.38c9.156 9.141 22.88 11.84 34.88 6.938C504.2 184.6 512 172.9 512 160V24C512 10.74 501.3 0 488 0z"
                        ></path>
                      </svg>
                    </Button>
                  </Col>
                </Row>
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchStore: state.search,
    tour: state.tour,
    auth: state.auth,
    visualization: state.visualization.toggleSearchBar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSearchText: (searchText) => dispatch(updateSearchText(searchText)),
    hitSearch: () => dispatch(hitSearch()),
    updateSelectedFilterType: (filterType) =>
      dispatch(updateSelectedFilterType(filterType)),
    updateSelectedFilterValueSelections: (selectedOptions, filterType) =>
      dispatch(
        updateSelectedFilterValueSelections(selectedOptions, filterType)
      ),
    removeFilters: (filterType) => dispatch(removeFilters(filterType)),
    handleYearUpdate: (update) => dispatch(handleYearUpdate(update)),
    searchModal: () => dispatch(searchModal()),
    searchDropDownFilter: () => dispatch(searchDropDownFilter()),
    addFilter: (newFilter) => dispatch(addFilter(newFilter)),
    loadFilters: () => dispatch(getFilters()),
    cancleFilter: () => dispatch(cancleFilter()),
    getMapLocations: () => dispatch(getMapLocations()),
    removeMapArea: () => dispatch(removeMapArea()),
    setFilterPanelHeight: (filterPanelHeight) =>
      dispatch(setFilterPanelHeight(filterPanelHeight)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);

import * as searchActionTypes from "./searchActionTypes";
import { filterTypes } from "./searchActions";

const initialState = {
  searchText: "",
  filterValueSelections: [],
  selectedFilterValueSelections: null,
  selectedFilterType: null,
  mimetypes: [],
  mimeTypesSelectedIndex: [],
  selectedMimetypes: null,
  datasetLocations: [],
  areaTracker: false,
  // datasetStaticLocation: [],
  filteredMarkers: [],
  markers: [],
  datasetYears: null,
  yearDomain: null,
  yearUpdate: null,
  yearValues: null,
  yearCount: null,
  yearData: null,

  selectedYears: null,
  selectedLocation: null,
  variety: [],
  selectedVarieties: null,
  crops: [],
  keywords: [],
  selectedCropsIndex: [],

  searchFilters: {
    searchText: "",
    selectedMimetypes: [],
    selectedYears: [],
    selectedLocation: null,
    selectedVarieties: [],
    crops: [],
    mimeTypes: [],
    selectedDatasetId: null,
    searchArea: null,
  },
  error: null,
  isFilterLoading: false,
  hitSearch: false,
  showDropdownFilter: false,
  searchModalIsActive: false,
  filters: [],
  deleteFilterInitialState: false,
  updateFilterTitleInitialState: false,
  addFilterInitialState: false,
  filterConfig: false,
  initYear: true,
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchActionTypes.GET_FILTERS_REQUEST:
      return {
        ...state,
        isFilterLoading: true,
      };
    case searchActionTypes.HIDE_SHOW_SEARCH_MODAL:
      return {
        ...state,
        searchModalIsActive: !state.searchModalIsActive,
      };
    case searchActionTypes.SEARCH_DROP_DOWN_FILTER:
      return {
        ...state,
        showDropdownFilter: !state.showDropdownFilter,
      };
    case searchActionTypes.SEARCH_DROP_DOWN_FILTER_PAGE_GUIDE:
      return {
        ...state,
        showDropdownFilter: false,
      };
    case searchActionTypes.GET_FILTERS_SUCCESS:
      return {
        ...state,
        filterTypes: action.payload.filterTypes,
        mimetypes: action.payload.mimetypes,
        crops: action.payload.crops,
        keywords: action.payload.keywords,
        datasetYears: action.payload.datasetYears,
        datasetLocations: action.payload.location,
        filters: action.payload.filters,
        isFilterLoading: false,
      };

    case searchActionTypes.GET_FILTERS_FAILED:
      return {
        ...state,
        isFilterLoading: false,
        error: action.payload.error,
      };

    case searchActionTypes.UPDATE_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload.searchText,
        searchFilters: {
          ...state.searchFilters,
          searchText: action.payload.searchText,
        },
      };

    // case searchActionTypes.UPDATE_MIMETYPES:
    //     return{
    //         ...state,
    //         mimetypes: action.payload.mimetypes
    //     }

    case searchActionTypes.INIT_YEAR_FILTER:
      return {
        ...state,
        yearDomain: action.payload.yearRange,
        yearUpdate: action.payload.yearRange,
        yearValues: action.payload.yearRange,

        yearCount: action.payload.count,
        yearData: action.payload.yearData,
      };
    case searchActionTypes.HANDLE_YEAR_UPDATE:
      const yearsVal = action.payload?.yearValues;
      const yearsValStr = yearsVal ? `${yearsVal[0]}${yearsVal[1]}` : null;

      return {
        ...state,
        ...action.payload,
        initYear: yearsValStr == localStorage.getItem("domain_year"),
      };
    case searchActionTypes.UPDATE_SELECTED_MIMETYPES:
      return {
        ...state,
        selectedMimetypes: action.payload.selectedMimetypes,
        searchFilters: {
          ...state.searchFilters,
          selectedMimetypes: action.payload.mimeFilters,
        },
      };

    case searchActionTypes.UPDATE_SELECTED_DATASETYEARS:
      return {
        ...state,
        selectedYears: action.payload.selectedYears,
        searchFilters: {
          ...state.searchFilters,
          selectedYears: action.payload.yearFilters,
        },
      };
    case searchActionTypes.RENDER_MAP:
      return {
        ...state,
        selectedLocation: action.payload,
        searchFilters: {
          ...state.searchFilters,
          selectedLocation: action.payload,
          selectedDatasetId: null,
        },
      };
    case searchActionTypes.UPDATE_SELECTED_DATASET_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload.selectedLocation,
        searchFilters: {
          ...state.searchFilters,
          selectedLocation: action.payload.selectedLocation,
          selectedDatasetId: null,
        },
        hitSearch: !state.hitSearch,
      };

    case searchActionTypes.UPDATE_SELECTED_DATASET_ID:
      return {
        ...state,
        selectedLocation: action.payload.selectedLocation,
        searchFilters: {
          ...state.searchFilters,
          selectedDatasetId: action.payload.selectedDatasetId,
          selectedLocation: action.payload.selectedLocation,
        },
        hitSearch: !state.hitSearch,
      };

    case searchActionTypes.UPDATE_SELECTED_CROPS_DATASETS:
      return {
        ...state,
        // crops:  action.payload.crops,
        selectedCropsIndex: action.payload.selectedCropsIndex,
        searchFilters: {
          ...state.searchFilters,
          crops: action.payload.crops,
        },
      };

    case searchActionTypes.UPDATE_SELECTED_MIME_TYPES_DATASETS:
      return {
        ...state,
        // mimetypes:  action.payload.mimeTypes,
        mimeTypesSelectedIndex: action.payload.selectedIndex,
        searchFilters: {
          ...state.searchFilters,
          mimeTypes: action.payload.mimeTypes,
        },
      };

    case searchActionTypes.UPDATE_MAP_MARKERS:
      return {
        ...state,
        filteredMarkers: action.payload.filteredMarkers,
      };

    case searchActionTypes.FETCH_STATIC_MARKER:
      return {
        ...state,
        markers: action.payload,
      };

    case searchActionTypes.UPDATE_SELECTED_VARIETIES:
      return {
        ...state,
        selectedVarieties: action.payload.selectedVarieties,
        searchFilters: {
          ...state.searchFilters,
          selectedVarieties: action.payload.selectedVarieties,
        },
      };

    case searchActionTypes.HIT_SEARCH:
      return {
        ...state,
        searchText: state.searchText.trim(),
        searchFilters: {
          ...state.searchFilters,
          searchText:
            state.searchFilters.searchText !== ""
              ? state.searchFilters.searchText.trim()
              : state.searchFilters.searchText,
        },
        hitSearch: !state.hitSearch,
      };

    case searchActionTypes.UPDATE_SELECTED_FILTER_TYPE:
      return {
        ...state,
        selectedFilterType: action.payload,
      };

    case searchActionTypes.SET_FILTER_SELECTIONS:
      return {
        ...state,
        filterValueSelections: setFilterValueSelections(action.payload, state),
        selectedFilterValueSelections: updateSelectedFilterValueSelections(
          action.payload,
          state
        ),
      };

    case searchActionTypes.UPDATE_SELECTED_FILTER_VALUE_SELECTIONS:
      return {
        ...state,
        selectedFilterValueSelections: action.payload,
      };

    case searchActionTypes.FILTER_CONFIG:
      return {
        ...state,
        filterConfig: !state.filterConfig,
      };

    case searchActionTypes.ADD_FILTER_REQUEST:
      return {
        ...state,
        addFilterInitialState: true,
      };

    case searchActionTypes.ADD_FILTER_SUCCESS:
      return {
        ...state,
        filters: [...state.filters, action.payload],
        addFilterInitialState: false,
        filterConfig: false,
      };
    case searchActionTypes.ADD_FILTER_FAIL:
      return {
        ...state,
        addFilterInitialState: false,
      };

    case searchActionTypes.DELETE_FILTER_REQUEST:
      return {
        ...state,
        deleteFilterInitialState: true,
      };

    case searchActionTypes.DELETE_FILTER_SUCCESS:
      return {
        ...state,
        filters: state.filters.filter((flt) => flt.id !== action.payload),
        deleteFilterInitialState: false,
      };
    case searchActionTypes.DELETE_FILTER_FAIL:
      return {
        ...state,
        deleteFilterInitialState: false,
      };

    case searchActionTypes.UPDATE_FILTER_TITLE_REQUEST:
      return {
        ...state,
        updateFilterTitleInitialState: true,
      };

    case searchActionTypes.UPDATE_FILTER_TITLE_SUCCESS:
      return {
        ...state,
        filters: state.filters.map((flt) => {
          if (flt.id === action.payload.id) return action.payload;
          else return flt;
        }),
        updateFilterTitleInitialState: false,
      };
    case searchActionTypes.UPDATE_FILTER_TITLE_FAIL:
      return {
        ...state,
        updateFilterTitleInitialState: false,
      };

    case searchActionTypes.CANCLE_FILTER:
      return {
        ...initialState,
        mimetypes: state.mimetypes,
        datasetLocations: state.datasetLocations,
        datasetYears: state.datasetYears,
        crops: state.crops,
        filterTypes: state.filterType,
        hitSearch: !state.hitSearch,
        searchText: state.searchText,
        markers: [...state.markers]
      };

    case searchActionTypes.ADD_MAP_AREA:
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          searchArea: action.payload,
        },
      };

    case searchActionTypes.REMOVE_MAP_AREA:
      return {
        ...state,
        searchFilters: { ...state.searchFilters, searchArea: null },
        areaTracker: !state.areaTracker,
      };

    default:
      return state;
  }
};

export default searchReducer;

const setFilterValueSelections = (filterType, state) => {
  switch (filterType) {
    case filterTypes.fileType:
      return state.mimetypes;

    case filterTypes.year:
      return state.datasetYears;

    case filterTypes.variety:
      return state.variety;

    case filterTypes.location:
      return [];

    default:
      return [];
  }
};

const updateSelectedFilterValueSelections = (filterType, state) => {
  switch (filterType) {
    case filterTypes.fileType:
      return state.selectedMimetypes;

    case filterTypes.year:
      return state.selectedYears;

    case filterTypes.variety:
      return state.selectedVarieties;

    case filterTypes.location:
      return [];

    default:
      return [];
  }
};

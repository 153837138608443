export const GET_PARENT_GROUPS_REQUEST = "GET_PARENT_GROUPS_REQUEST";
export const GET_PARENT_GROUPS_SUCCESS = "GET_PARENT_GROUPS_SUCCESS";
export const GET_PARENT_GROUPS_FAILED = "GET_PARENT_GROUPS_FAILED";

export const GET_GROUPS_REQUEST = "GET_GROUPS_REQUEST";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAILED = "GET_GROUPS_FAILED";

export const GET_SWITCHED_GROUPS_REQUEST = "GET_SWITCHED_GROUPS_REQUEST";
export const GET_SWITCHED_GROUPS_SUCCESS = "GET_SWITCHED_GROUPS_SUCCESS";
export const GET_SWITCHED_GROUPS_FAILED = "GET_SWITCHED_GROUPS_FAILED";

export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_REQUEST";
export const CREATE_GROUP_FAILED = "CREATE_GROUP_FAILED";

export const ADD_NEW_METADATA_REQUEST = "ADD_NEW_METADATA_REQUEST";
export const ADD_NEW_METADATA_SUCCESS = "ADD_NEW_METADATA_SUCCESS";
export const ADD_NEW_METADATA_FAILED = "ADD_NEW_METADATA_FAILED";

export const GET_GROUP_DETAILS_REQUEST = "GET_GROUP_DETAILS_REQUEST";
export const GET_GROUP_DETAILS_SUCCESS = "GET_GROUP_DETAILS_SUCCESS";
export const GET_GROUP_DETAILS_FAILED = "GET_GROUP_DETAILS_FAILED";

export const GET_GROUPS_SEARCH_RESULTS_REQUEST =
  "GET_GROUPS_SEARCH_RESULTS_REQUEST";
export const GET_GROUPS_SEARCH_RESULTS_SUCCESS =
  "GET_GROUPS_SEARCH_RESULTS_SUCCESS";
export const GET_GROUPS_SEARCH_RESULTS_FAILED =
  "GET_GROUPS_SEARCH_RESULTS_FAILED";

export const DISMISS_NUMBER_OF_GROUP_RESULTS =
  "DISMISS_NUMBER_OF_GROUP_RESULTS";
export const SET_NUMBER_OF_GROUP_RESULTS = "SET_NUMBER_OF_GROUP_RESULTS";

export const SELECT_A_GROUP = "SELECT_A_GROUP";

export const EDIT_GROUP_TITLE_REQUEST = "EDIT_GROUP_TITLE_REQUEST";
export const EDIT_GROUP_TITLE_SUCCESS = "EDIT_GROUP_TITLE_SUCCESS";
export const EDIT_GROUP_TITLE_FAILED = "EDIT_GROUP_TITLE_FAILED";

export const EDIT_GROUP_DESCRIPTION_SUCCESS = "EDIT_GROUP_DESCRIPTION_SUCCESS";
export const EDIT_GROUP_ADMIN_SUCCESS = "EDIT_GROUP_ADMIN_SUCCESS";
export const EDIT_PARENT_GROUP_SUCCESS = "EDIT_PARENT_GROUP_SUCCESS";
export const EDIT_GROUP_PERMISSION_SUCCESS = "EDIT_GROUP_PERMISSION_SUCCESS";

export const APPEND_NEW_METADATA = "APPEND_NEW_METADATA";

export const SHOW_MODAL_ADD_EDIT_GROUP = "SHOW_MODAL_ADD_EDIT_GROUP";
export const HIDE_MODAL_ADD_EDIT_GROUP = "HIDE_MODAL_ADD_EDIT_GROUP";

export const SHOW_MODAL_DATA_TEMPLATE = "SHOW_MODAL_DATA_TEMPLATE";
export const HIDE_MODAL_DATA_TEMPLATE = "HIDE_MODAL_DATA_TEMPLATE";

export const SHOW_GROUP_DETAILS_MODAL = "SHOW_GROUP_DETAILS_MODAL";
export const HIDE_GROUP_DETAILS_MODAL = "HIDE_GROUP_DETAILS_MODAL";

export const GROUP_MEMBER_REMOVAL = "GROUP_MEMBER_REMOVAL";
export const UPDATE_OR_DELETE_GROUPS = "UPDATE_OR_DELETE_GROUPS";
export const UPLOAD_DATA_TEMPLATE_FROM_DATSET =
  "UPLOAD_DATA_TEMPLATE_FROM_DATSET";

export const GET_EXPANDED_RESOURCES_NAME = "GET_EXPANDED_RESOURCES_NAME";
export const GET_EXPANDED_RESOURCES_REQUEST = "GET_EXPANDED_RESOURCES_REQUEST";
export const GET_EXPANDED_RESOURCES_SUCCESS = "GET_EXPANDED_RESOURCES_SUCCESS";
export const GET_EXPANDED_RESOURCES = "GET_EXPANDED_RESOURCES";
export const GET_EXPANDED_RESOURCES_FAIL = "GET_EXPANDED_RESOURCES_FAIL";
export const GET_EXPANDED_RESOURCES_DETAILS_REQUEST =
  "GET_EXPANDED_RESOURCES_DETAILS_REQUEST";
export const GET_EXPANDED_RESOURCES_DETAILS_SUCCESS =
  "GET_EXPANDED_RESOURCES_DETAILS_SUCCESS";
export const GET_EXPANDED_RESOURCES_DETAILS_FAIL =
  "GET_EXPANDED_RESOURCES_DETAILS_FAIL";
export const REMOVE_GET_EXPANDED_RESOURCES = "REMOVE_GET_EXPANDED_RESOURCES";
export const APPEND_EXPANDED_RESOURCES_META_DATA =
  "APPEND_EXPANDED_RESOURCES_META_DATA";
export const EDIT_EXPANDED_RESOURCES_DESCRIPTION =
  "EDIT_EXPANDED_RESOURCES_DESCRIPTION";
export const EDIT_EXPANDED_RESOURCES_ADMIN = "EDIT_EXPANDED_RESOURCES_ADMIN";
export const EDIT_EXPANDED_RESOURCES_PARENT_GROUP =
  "EDIT_EXPANDED_RESOURCES_PARENT_GROUP";
export const EDIT_EXPANDED_RESOURCES_ADMINISTRATIVE_GROUP =
  "EDIT_EXPANDED_RESOURCES_ADMINISTRATIVE_GROUP";
export const EDIT_EXPANDED_RESOURCES_PERMISSION =
  "EDIT_EXPANDED_RESOURCES_PERMISSION";
export const GET_EXPANDED_DATSET_DETAILS_REQUEST =
  "GET_EXPANDED_DATSET_DETAILS_REQUEST";
export const GET_EXPANDED_DATSET_DETAILS_SUCCESS =
  "GET_EXPANDED_DATSET_DETAILS_SUCCESS";
export const GET_EXPANDED_DATSET_DETAILS_FAIL =
  "GET_EXPANDED_DATSET_DETAILS_FAIL";
export const GET_EXPANDED_DATASET_PROPERTIES_REQUEST =
  "GET_EXPANDED_DATASET_PROPERTIES_REQUEST";
export const GET_EXPANDED_DATASET_PROPERTIES_SUCCESS =
  "GET_EXPANDED_DATASET_PROPERTIES_SUCCESS";
export const GET_EXPANDED_DATASET_PROPERTIES_FAILED =
  "GET_EXPANDED_DATASET_PROPERTIES_FAILED";

export const EXAPANDED_DATASET_SET_METADATA_TAB_CHANGE_FLAG =
  "EXAPANDED_DATASET_SET_METADATA_TAB_CHANGE_FLAG";
export const EXPANDED_DATASET_SHOW_SUBMIT_METADATA_ALERT_MODAL =
  "EXPANDED_DATASET_SHOW_SUBMIT_METADATA_ALERT_MODAL";
export const EXPANDED_DATASET_RESET_METADATA_TAB_CHANGE_FLAG =
  "EXPANDED_DATASET_RESET_METADATA_TAB_CHANGE_FLAG";
export const EXPANDED_DATASET_HIDE_SUBMIT_METADATA_ALERT_MODAL =
  "EXPANDED_DATASET_HIDE_SUBMIT_METADATA_ALERT_MODAL";

// chonkey action
export const EXPANDED_DATASET_UPDATE_CURRENT_FOLDER =
  "EXPANDED_DATASET_UPDATE_CURRENT_FOLDER";
export const EXPANDED_DATASET_UPDATE_CURRENT_FOLDER_FILES_REQUEST =
  "EXPANDED_DATASET_UPDATE_CURRENT_FOLDER_FILES_REQUEST";
export const EXPANDED_DATASET_UPDATE_CURRENT_FOLDER_FILES_SUCCESS =
  "EXPANDED_DATASET_UPDATE_CURRENT_FOLDER_FILES_SUCCESS";
export const EXAPNDED_DATASET_UPDATE_CURRENT_FOLDER_FILES_FAILED =
  "EXAPNDED_DATASET_UPDATE_CURRENT_FOLDER_FILES_FAILED";
export const EXPANDED_DATASET_UPDATE_CURRENT_FILEMAP =
  "EXPANDED_DATASET_UPDATE_CURRENT_FILEMAP";
export const EXPANDED_DATASET_DATA_COPY_FILES_TO_CLIPBOARD =
  "EXPANDED_DATASET_DATA_COPY_FILES_TO_CLIPBOARD";

// data upload
export const EXPANDED_DATASET_CLOSE_UPLOAD_DRAWER =
  "EXPANDED_DATASET_CLOSE_UPLOAD_DRAWER";
export const EXPANDED_DATASET_ID_NAME_PAIRS_REQUEST =
  "EXPANDED_DATASET_ID_NAME_PAIRS_REQUEST";
export const EXPANDED_DATASET_ID_NAME_PAIRS_SUCCESS =
  "EXPANDED_DATASET_ID_NAME_PAIRS_SUCCESS";
export const EXPANDED_DATASET_ID_NAME_PAIRS_FAILED =
  "EXPANDED_DATASET_ID_NAME_PAIRS_FAILED";

export const EXPANDED_DATASET_VERSIONS_REQUEST =
  "EXPANDED_DATASET_VERSIONS_REQUEST";
export const EXPANDED_DATASET_VERSIONS_SUCCESS =
  "EXPANDED_DATASET_VERSIONS_SUCCESS";
export const EXPANDED_DATASET_VERSIONS_FAILED =
  "EXPANDED_DATASET_VERSIONS_FAILED";
export const EXPANDED_DATASET_OPEN_UPLOAD_DRAWER =
  "EXPANDED_DATASET_OPEN_UPLOAD_DRAWER";

export const EXPANDED_DATASET_DATA_UPLOAD_STARTED =
  "EXPANDED_DATASET_DATA_UPLOAD_STARTED";
export const EXPANDED_DATASET_DATA_UPLOAD_COMPLETED =
  "EXPANDED_DATASET_DATA_UPLOAD_COMPLETED";

export const EXPANDED_DATASET_APPEND_NEW_LINK_TO_DATASET =
  "EXPANDED_DATASET_APPEND_NEW_LINK_TO_DATASET";
export const EXPANDED_DATASET_DATA_UPLOAD_SUB_DIR_RESET =
  "EXPANDED_DATASET_DATA_UPLOAD_SUB_DIR_RESET";
export const EXPANDED_DATASET_APPEND_NEW_METADATA_TO_DATASET =
  "EXPANDED_DATASET_APPEND_NEW_METADATA_TO_DATASET";

//register dataset
// export const EXPANDED_DATASET_REGISTER_DATASET_REQUEST =
//   "EXPANDED_DATASET_REGISTER_DATASET_REQUEST";
// export const EXPANDED_DATASET_REGISTER_DATASET_SUCCESS =
//   "EXPANDED_DATASET_REGISTER_DATASET_SUCCESS";
// export const EXPANDED_DATASET_REGISTER_DATASET_FAILED =
//   "EXPANDED_DATASET_REGISTER_DATASET_FAILED";

//publish dataset

export const UPDATE_EXPANDED_DATASET_STATUS = "UPDATE_EXPANDED_DATASET_STATUS";
export const REMOVE_EXPANDED_RESOURCES = "REMOVE_EXPANDED_RESOURCES";
export const EDIT_EXPANDED_GROUP_TITLE_REQUEST =
  "EDIT_EXPANDED_GROUP_TITLE_REQUEST";
export const EDIT_EXPANDED_GROUP_TITLE_SUCCESS =
  "EDIT_EXPANDED_GROUP_TITLE_SUCCESS";
export const EDIT_EXPANDED_GROUP_TITLE_FAILED =
  "EDIT_EXPANDED_GROUP_TITLE_FAILED";
export const EXPANDED_DATASET_TITLE_EDIT_SUCCESS =
  "EXPANDED_DATASET_TITLE_EDIT_SUCCESS";
export const EXPANDED_DATASET_TITLE_EDIT_FAILED =
  "EXPANDED_DATASET_TITLE_EDIT_FAILED";
export const EXPANDED_DATASET_HANDLE_LIKE_REQUEST =
  "EXPANDED_DATASET_HANDLE_LIKE_REQUEST";
export const EXPANDED_DATASET_HANDLE_LIKE_SUCCESS =
  "EXPANDED_DATASET_HANDLE_LIKE_SUCCESS";
export const EXPANDED_DATASET_HANDLE_LIKE_FAILED =
  "EXPANDED_DATASET_HANDLE_LIKE_FAILED";
export const REMOVE_EXPANDED_RESOURCES_GROUP_MEMEBER =
  "REMOVE_EXPANDED_RESOURCES_GROUP_MEMEBER";

import React, { useReducer, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";

import "../../css/tour.css";
import {
  actionTourStart,
  actionTourMid,
  actionTourEnd,
  actionTourSecondStep,
} from "../../store/tourStore/tourActions";
import {
  getParentGroups,
  showAddEditGroupModal,
  hideAddEditGroupModal,
} from "../../store/groupsStore/groupActions";
import {
  showAddDatasetModal,
  hideAddDatasetModal,
} from "../../store/datasetsStore/datasetActions";
import { searchDropDownFilterPageGuide } from "../../store/searchStore/searchActions";
import { START_TOUR } from "../../store/tourStore/tourActionTypes";
import AddEditGroupModal from "../midPanel/group/addEditGroupModal";
import {
  updateActiveAccordion,
  loadDetails,
  selectADataset,
} from "../../store/datasetsStore/datasetActions";

// Define the steps
const TOUR_STEPS = [
  {
    target: ".search",
    content: "Type in search box to find a dataset.",
    title: "Search",
    disableBeacon: true,
    textAlign: "center",
    placement: "top",
    zIndex: 1000,
  },
  {
    target: ".tour-datasets-list-view",
    content: "Hit search icon to see the matched datasets.",
    title: "Search Result",

    disableBeacon: true,
    textAlign: "center",
    placement: "top",
  },
  {
    target: ".tour-single-dataset-view",
    content: "Click individual dataset tab to expand it for details.",
    title: "Expanded Dataset",

    disableBeacon: true,
    textAlign: "center",
    placement: "bottom",
    styles: {
      options: {
        zIndex: 1000,
      },
    },
  },
  {
    target: ".scrolling-wrapper",
    content: "Click filter button for visual search.",
    title: "Filter",

    disableBeacon: true,
    textAlign: "center",
    placement: "left",
    styles: {
      options: {
        zIndex: 1000,
      },
    },
  },

  {
    target: ".create-dataset",
    content:
      "Click create dataset button to open the dialog for creating a new dataset.",
    title: "Create Dataset",
    placement: "left",
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    disableBeacon: true,
  },
  {
    target: ".add-dataset-modal",
    content: "Select group and name for a new dataset.",
    title: "Create Dataset Dialog",
    placement: "bottom",
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    disableBeacon: true,
  },
];

// // Define our state
const INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
};

// Set up the reducer function
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    // case "RESET":
    //   return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false, stepIndex: 0 };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    // case "RESTART":
    //   return {
    //     ...state,
    //     stepIndex: 0,
    //     run: true,
    //     loading: false,
    //     key: new Date(),
    //   };
    default:
      return state;
  }
};

// Define the Tour component
const Tour = () => {
  const history = useHistory();
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const step = useSelector((state) => state.tour.steps);
  const search = useSelector((state) => state.search);
  const isNewUser = useSelector((state) => state.auth.newUser);
  const tourDispatch = useDispatch();

  const callback = (data) => {
    const { action, index, type, status, lifecycle } = data;
    if (step !== index) {
      tourDispatch(actionTourMid(index));
    }

    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      tourDispatch(actionTourEnd());
      tourDispatch(hideAddDatasetModal());
      tourDispatch(updateActiveAccordion(null));
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      if (action === "next" && index === 1 && lifecycle === "complete") {
        tourDispatch(updateActiveAccordion(-999));
        tourDispatch(loadDetails(-999));
      } else if (index === 2 && action === "prev" && lifecycle === "complete") {
        tourDispatch(updateActiveAccordion(null));
      } else if (action === "next" && index === 2 && lifecycle === "complete") {
        tourDispatch(actionTourSecondStep(true));
      } else if (index === 4 && action === "prev" && lifecycle === "complete") {
        tourDispatch(actionTourSecondStep(true));
      } else if (action === "next" && index === 3 && lifecycle === "complete") {
        tourDispatch(actionTourSecondStep(false));
      }
      if (index === 3 && lifecycle === "complete" && action === "prev") {
        tourDispatch(actionTourSecondStep(false));
      } else if (action === "next" && index === 4 && lifecycle === "complete") {
        tourDispatch(showAddDatasetModal());
      } else if (action === "next" && index === 5 && lifecycle === "complete") {
        tourDispatch(actionTourEnd());
        tourDispatch(hideAddDatasetModal());
        tourDispatch(updateActiveAccordion(null));

        dispatch({ type: "STOP" });
      } else if (action === "prev" && index === 5 && lifecycle === "complete") {
        tourDispatch(hideAddDatasetModal());
      }

      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };
  const startTour = () => {
    const body = document.getElementsByTagName("body")[0];
    tourDispatch(searchDropDownFilterPageGuide());
    tourDispatch(actionTourStart());
    dispatch({ type: "START" });
  };
  useEffect(() => {
    if (isNewUser) {
      history.push("/datasets");
      tourDispatch(searchDropDownFilterPageGuide());
      tourDispatch(actionTourStart());
      dispatch({ type: "START" });
    }
  }, []);

  return (
    <>
      <Link
        to="/datasets"
        // className="btn btn-primary"
        onClick={startTour}
        // style={{ marginRight: "5px" }}
      >
       Interactive Tour
      </Link>
      <JoyRide
        {...tourState}
        callback={callback}
        showSkipButton={true}
        // showProgress
        styles={{
          options: {
            arrowColor: "#e3ffeb",
            backgroundColor: "#e3ffeb",
            overlayColor: "rgba(0, 0, 0, 0.2)",
            // primaryColor: '#000',
            textColor: "#004a14",
            width: 300,
            // zIndex: 1000,
          },
        }}
        locale={{
          last: "End tour",
        }}
        joyrideProps={{ disableScrolling: true }}
      />
    </>
  );
};
export default Tour;

import { useEffect, useState } from "react";
import { useLeaflet } from "react-leaflet";
import L from "leaflet";
import { useDispatch } from "react-redux";
import { selectMapArea,  updateSelectedFilterValueSelections, } from "../../../store/searchStore/searchActions";

export default function AreaSelect({ tracker }) {
  const { map } = useLeaflet();
  const dispatch = useDispatch();
  const [currentLayer, setCurrentLayer] = useState(null);
  const [selectedBounds, setSelectedBounds] = useState(null);

  useEffect(() => {
    if (!map.selectArea) return;
    map.selectArea.enable();
    map.on("areaselected", (e) => {
      // setSelectedBounds((prevState) => prevState === null && e.bounds);
      setSelectedBounds(e.bounds)
    });
  }, [map]);

  useEffect(() => {
    if (!selectedBounds) return;
    // console.log(selectedBounds.toBBoxString()); // lon, lat, lon, lat
    const splitArea = selectedBounds.toBBoxString().split(",");
    const _area = [
      [splitArea[1], splitArea[0]],
      [splitArea[3], splitArea[2]],
    ];
    // console.log(selectedBounds, _area)
    dispatch(selectMapArea(_area));
    dispatch( updateSelectedFilterValueSelections({label: "All", value: "All"}, "location"))
    const layer = L.rectangle(selectedBounds, {
      color: "blue",
      weight: 1,
    });
    if (currentLayer && currentLayer !== null) {
      currentLayer.removeFrom(map);
    }
    layer.addTo(map);
    setCurrentLayer(layer);
  }, [selectedBounds, map, dispatch]);

  useEffect(() => {
    if (currentLayer && currentLayer !== null) {
      currentLayer.removeFrom(map);
    }
    setSelectedBounds(null);
  }, [tracker, setSelectedBounds]);

  return null;
}

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { Button } from "react-bootstrap";
import Tour from "../pageGuide/tour";
import { ABOUT, FAQ, WEB, WIKI } from "../../constants/routes";

const Image = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
    fill="currentColor"
    className="bi bi-caret-down-fill"
    viewBox="0 0 16 16"
  >
    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
  </svg>
);

const HeaderDropdown = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const menu = (
    <Menu className="dropdown__menu">
      <Menu.Item>{isAuthenticated && <Tour />}</Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href={WEB}>
          Web
        </a>
      </Menu.Item>
      <Menu.Item>
        <Link to={WIKI}>Wiki</Link>
      </Menu.Item>
      {/* <Menu.Item>
        <Link to={FAQ}>FAQ</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={ABOUT}>About</Link>
      </Menu.Item> */}
    </Menu>
  );
  return (
    <div>
      <Dropdown overlay={menu}>
        <Button
          style={{
            color: "#fff",
            backgroundColor: "#007bff",
            borderColor: "#007bff",
            padding: "7px 12px",
            margin: "5px 5px 5px 0px",
          }}
        >
          Help {Image}
        </Button>
      </Dropdown>
    </div>
  );
};

export default HeaderDropdown;

import * as locationActionTypes from "./locationActionTypes";

const initialState = {
  currentLocation: { latitude: null, longitude: null },
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case locationActionTypes.FETCH_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: { ...state.currentLocation, ...action.payload },
      };
    default:
      return state;
  }
};

export default locationReducer

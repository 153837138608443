import React, { Fragment } from "react";
import { Card, Modal, Input } from "antd";
import { Row, Col } from "react-bootstrap";
import { InputGroup, Button, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCaretSquareUp,
  faCaretSquareDown,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  updateSearchText,
  hitSearch,
  updateSelectedFilterType,
  updateSelectedFilterValueSelections,
  removeFilters,
  handleYearUpdate,
  searchDropDownFilter,
  searchearchModal,
  filterConfig,
} from "../../store/searchStore/searchActions";
import { connect } from "react-redux";
// import { filterTypes } from "../../store/searchStore/searchActions";
import Select from "react-select";
import PieChartComp from "./pieChart";
import BarChartComp from "./barChart";
import RangeSlider from "./histoslider/RangeSlider";

import MapView, { locationDDL } from "./map/mapView";
import NewFilter from "./newFilter";

import ChartCard from "./sharedComponent/addFilter/chartCard";
import { configSubFilters } from "../../helperMethods/filterParser";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#cce0ff" : null,
      color: "#333333",
    };
  },
};

const labelContainerStyle = {
  display: "grid",
  gridTemplateColumns: "0.1fr 0.1fr 1fr 1fr 1fr",
  alignItems: "center",
};

const colorContainerStyle = {
  height: "20px",
  width: "50px",
  backgroundColor: "blue",
  display: "inline-block",
  marginRight: "10px",
};

const options = [
  { label: "Blue", value: "blue" },
  { label: "Green", value: "green" },
  { label: "Red", value: "red" },
  { label: "Orange", value: "orange" },
  { label: "Yellow", value: "yellow" },
  { label: "Purple", value: "purple" },
];

class ConfigSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: {},
      chart: null,
      filterType: null,
      selectedColor: null,
      subFilterType: [],
      subFilterTypeOptions: [],
      name: null,
    };
  }

  componentDidMount() {
    this.setState({
      color: {},
      chart: null,
      filterType: null,
      selectedColor: null,
      subFilterType: [],
      subFilterTypeOptions: [],
      name: null,
    });
  }

  mapViewByFilterType = () => {
    switch (this.state.filterType.label) {
      case "Filetype":
        return {
          view: this.state.chart,
          name: this.state.name,
          filterType: "filetype",
          color: this.state.selectedColor.value,
          subFilterType: configSubFilters(this.state.subFilterType),
        };
      case "Crop":
        return {
          view: this.state.chart,
          name: this.state.name,
          filterType: "crop",
          color: this.state.selectedColor.value,
          subFilterType: configSubFilters(this.state.subFilterType),
        };
      case "Keyword":
        return {
          view: this.state.chart,
          name: this.state.name,
          filterType: "keyword",
          color: this.state.selectedColor.value,
          subFilterType: configSubFilters(this.state.subFilterType),
        };
      default:
        return {
          // view: this.state.chart,
          // filterType: "filetype",
          // color: this.state.selectedColor.value,
          // subFilterType: configSubFilters(this.state.subFilterType),
        };
    }
  };
  selectedSubFilter = (e) => {
    switch (e.label) {
      case "Filetype":
        return this.props.searchStore.mimetypes.map((mimetype) => {
          return {
            label: mimetype.label,
            value: mimetype.label + "@" + mimetype.value,
          };
        });
      case "Crop":
        return this.props.searchStore.crops.map((crop) => {
          return { label: crop.label, value: crop.label + "@" + crop.value };
        });
      case "Keyword":
        return this.props.searchStore.keywords.map((keyword) => {
          return {
            label: keyword.label,
            value: keyword.label + "@" + keyword.value,
          };
        });
      default:
        return this.props.searchStore.mimetypes.map((mimetype) => {
          return {
            label: mimetype.label,
            value: mimetype.label + "@" + mimetype.value,
          };
        });
    }
  };
  setColor = (view) => {
    this.setState({
      ...this.state,
      color: {
        [view]: "#ccf5ff",
      },
      chart: view,
    });
  };

  subFilterHandler = (e) => {
    this.setState({ ...this.setState, subFilterType: e || [] });
  };

  selectedFilterTypeHandler = (e) => {
    const newSubFilter = this.selectedSubFilter(e);
    this.setState({
      ...this.state,
      filterType: e,
      // subFilterType: newSubFilter,
      subFilterTypeOptions: newSubFilter,
    });
  };
  selectedColorHandler = (e) => {
    this.setState({ ...this.state, selectedColor: e });
  };

  handleClose = () => {
    // e.preventDefault()
    // this.setState({
    //   color: {},
    //   chart: null,
    //   filterType: null,
    //   selectedColor: null,
    //   subFilterType: [],
    //   subFilterTypeOptions: [],
    // });
    this.props.filterConfig();
  };

  appendFilterHandler = () => {
    // console.lg(state)
    this.props.appendFilter(this.mapViewByFilterType());
    // this.handleClose();
  };

  render() {
    const { filterTypes } = this.props.searchStore;
    const filteredFilterTypes = filterTypes.filter(
      (filterType) =>
        filterType.label !== "location" &&
        filterType.label !== "year" &&
        filterType.label !== "filters"
    );

    const updatedFilterTypes = filteredFilterTypes.map((filterType) => {
      const { label } = filterType;
      filterType.label = label.charAt(0).toUpperCase() + label.substring(1);
      return filterType;
    });

    return (
      <Modal
        // className="filter-modal"
        visible={this.props.searchStore.filterConfig}
        onCancel={this.handleClose}
        centered
        width={1000}
        name="Selecet Filter View"
        footer={[
          <Button
            key="apply"
            disabled={
              this.state.chart &&
              this.state.filterType &&
              this.state.selectedColor !== null &&
              this.state.name !== null
                ? false
                : true
            }
            onClick={this.appendFilterHandler}
          >
            Apply
          </Button>,
        ]}
      >
        <div
          style={{
            padding: "1em 1em",
            margin: "0 1em",
            background: "#add8e675",
            borderRadius: ".3rem",
          }}
        >
          <Row>
            <Col xl={6} lg={6} md={6} sm={12}>
              <ChartCard
                identity="bar"
                color={this.state.color.bar}
                imgUrl="https://img.icons8.com/fluency/480/000000/bar-chart.png"
                setColor={this.setColor}
              />
            </Col>

            <Col xl={6} lg={6} md={6} sm={12}>
              <ChartCard
                identity="pie"
                color={this.state.color.pie}
                imgUrl="https://img.icons8.com/fluency/480/000000/pie-chart.png"
                setColor={this.setColor}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {this.state.chart !== null && (
                <Select
                  value={this.state.filterType}
                  onChange={this.selectedFilterTypeHandler}
                  options={updatedFilterTypes}
                  isSearchable={true}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  placeholder="Select Filter Type"
                />
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "5px" }}>
            <Col>
              {this.state.filterType !== null && (
                <Select
                  value={this.state.subFilterType}
                  onChange={this.subFilterHandler}
                  defaultValue={[this.state.subFilterTypeOptions[0]]}
                  options={this.state.subFilterTypeOptions}
                  isSearchable={true}
                  isMulti
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  placeholder="Select Sub-filter Type"
                />
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "5px" }}>
            <Col>
              {this.state.subFilterType.length !== 0 && (
                <Select
                  value={this.state.selectedColor}
                  onChange={this.selectedColorHandler}
                  options={options.map((option) => {
                    return {
                      label: (
                        <div style={{ ...labelContainerStyle }}>
                          <div
                            style={{
                              ...colorContainerStyle,
                              backgroundColor: option.value,
                            }}
                          ></div>
                          <div>{option.label}</div>
                        </div>
                      ),
                      value: option.value,
                    };
                  })}
                  isSearchable={true}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  placeholder="Select Color"
                  styles={colourStyles}
                />
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: "5px" }}>
            {this.state.subFilterType.length !== 0 && (
              <Col>
                {" "}
                <Input
                  style={{ padding: "8px", borderRadius: "4px" }}
                  onChange={(e) =>
                    this.setState({ ...this.state, name: e.target.value })
                  }
                  placeholder="Enter filter name"
                />
              </Col>
            )}
          </Row>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchStore: state.search,
    tour: state.tour,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    filterConfig: () => dispatch(filterConfig()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigSearch);

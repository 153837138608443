import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import { connect } from "react-redux";
import { updateSelectedFilterValueSelections } from "../../store/searchStore/searchActions";

const colors = scaleOrdinal(schemeCategory10).range();

const data = [
  { name: "json", value: 2 },
  { name: "png", value: 3 },
  { name: "jpg", value: 2 },
  { name: "json", value: 5 },
  { name: "sql", value: 8 },
  { name: "octet", value: 2 },
  { name: "zip", value: 3 },
  { name: "pdf", value: 25 },
  { name: "js", value: 5 },
  { name: "docs", value: 20 },
  { name: "text", value: 3 },
];

class BarChartComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      barChartData: this.props.searchStore.mimetypes.map((mime) => {
        return { name: mime.label, value: mime.value };
      }),
    };
  }

  onBarClick = (e, index) => {
    const selectedMymeTypesIndex =
      this.props.searchStore.mimeTypesSelectedIndex;
    const currentActivatedCropsIndex = selectedMymeTypesIndex.includes(index)
      ? selectedMymeTypesIndex.filter((activeIndex) => activeIndex !== index)
      : [...selectedMymeTypesIndex, index];
    const selectedMymeTypes = this.props.searchStore.searchFilters.mimeTypes;
    const currentActivatedMymeTypes = selectedMymeTypes.includes(e.name)
      ? selectedMymeTypes.filter((mymeType) => mymeType !== e.name)
      : [...selectedMymeTypes, e.name];
    this.props.updateSelectedFilterValueSelections(
      {
        mimeTypes: currentActivatedMymeTypes,
        selectedMymeTypesIndex: currentActivatedCropsIndex,
      },
      "mimeTypes"
    );
  };
  render() {
    return (
      <ResponsiveContainer
        width="100%"
        height={this.props.height || 250}
        className="barChart"
      >
        <BarChart
          data={
            this.state.barChartData.length > 0 ? this.state.barChartData : data
          }
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Bar
            // activeIndex={this.state.activeIndex}
            dataKey="value"
            fill="#8884d8"
            stroke="none"
            label={{ position: "top" }}
            onClick={this.onBarClick}
          >
            {this.state.barChartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                className={
                  this.props.searchStore.mimeTypesSelectedIndex.includes(index)
                    ? "barchart-active"
                    : ""
                }
                fill={colors[index % 20]}
              />
            ))}
            {this.state.barChartData.length === 0 &&
              data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  className={
                    this.props.searchStore.mimeTypesSelectedIndex.includes(
                      index
                    )
                      ? "barchart-active"
                      : ""
                  }
                  fill={colors[index % 20]}
                />
              ))}
          </Bar>
          <Legend
          // formatter={this.renderColorfulLegendText}
          // content={this.renderColorfulLegendText}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchStore: state.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedFilterValueSelections: (selectedOptions, filterType) =>
      dispatch(
        updateSelectedFilterValueSelections(selectedOptions, filterType)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarChartComp);

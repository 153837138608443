import React, { Fragment, Suspense } from "react";
import { connect } from "react-redux";
import { createHashHistory } from "history";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Helmet from "react-helmet";
import { getLoginStatus } from "../store/authStore/authActions";
import {
  getFilters,
  getMapLocations,
} from "../store/searchStore/searchActions";
import { loadInvitationNotifications } from "../store/notificationStore/notificationAction";
import { removeSwitchGroupIdFromDataset } from "../store/datasetsStore/datasetActions";
import { fetchCurrentLocation } from "../store/locationStore/locationActions";
import { getCatalog } from "../dataaccess/datasetsDataAccess";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import {
  ABOUT,
  AUTHENTICATED_ROUTES,
  CHECK_SIGNUP,
  CONTACT_US,
  FAQ,
  HOME,
  HOME_DATASETS,
  HOME_PARAMS,
  JBROWSE,
  PAGE404,
  PAGE422,
  PROFILE,
  REGISTER,
  RESET,
  RESET_PASSWORD,
  ROOT,
  SIGNUP,
  SINGLE_DATASET,
  WELCOME,
  WELCOME_PUBLIC_SEARCH_DATASETS,
  WELCOME_PUBLIC_SEARCH_PARAMS,
  WELCOME_TICKET,
  WIKI,
} from "../constants/routes";
import { jbrowseUrl } from "../constants/constants";

import LazyLoadingHeader from "./lazyLoadingHeader";
import Error422 from "./discreteComponents/Error422";
import geolocation from "geolocation";

// import Header from "./header";

import { theme, ThemeProvider } from "@chakra-ui/core";
import "../css/app.css";
import Password from "antd/lib/input/Password";
import { DATSETS_CATALOG } from "../constants/datasetsCatalog";

const Header = React.lazy(() => import("./header"));
const Body = React.lazy(() => import("./body"));
const WelcomeWindow = React.lazy(() => import("./welcomeWindow"));
const ContactUs = React.lazy(() => import("./discreteComponents/contactPage"));
const About = React.lazy(() => import("./discreteComponents/aboutPage"));
const SignUp = React.lazy(() => import("./discreteComponents/SignUp"));
const SignUpCheck = React.lazy(() =>
  import("./discreteComponents/SignUpCheck")
);
const Reset = React.lazy(() => import("./discreteComponents/Reset"));
const ResetPassword = React.lazy(() =>
  import("./discreteComponents/ResetPassword")
);
const Profile = React.lazy(() => import("./discreteComponents/profile"));
const PageNotFound = React.lazy(() =>
  import("./discreteComponents/PageNotFound")
);
const FaqPage = React.lazy(() => import("./discreteComponents/FaqPage"));
const RegisterUser = React.lazy(() =>
  import("./discreteComponents/registerUser")
);
const Wiki = React.lazy(() => import("./wikiPanel/wiki"));
const DatasetDetail = React.lazy(() =>
  import("./midPanel/dataset/datasetDetail")
);

const DataUploadDrawer = React.lazy(() =>
  import("../components/midPanel/dataset/dataUploadDrawer")
);

const ExpandedDataUploadDrawer = React.lazy(() =>
  import("../components/midPanel/dataset/expandedDataUploadDrawer")
);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      catalog: {},
    };
  }
  /**
   * Calls when click Visit Jbrose button in Software tab
   * Redirect to JBrowser website
   * Returns null
   */
  jBroserRedirection = () => {
    window.location.href = jbrowseUrl;
    return null;
  };

  /**
   * Calls after inital rendering
   * Load login status,
   * Load all filter parameters
   * Load all notifications
   */

  // getCatalogHandler = () => {
  //   getCatalog()
  //     .then((res) => {
  //       this.setState({catalog: res.data})
  //     })
  //     .catch((err) => console.log(err));
  // };
  componentDidMount() {
    // this.props.removeSwitchGroupIdFromDataset()
    this.props.getLoginStatus();
    this.props.loadFilters();
    this.props.getMapLocations();

    geolocation.getCurrentPosition((err, position) => {
      if (err) throw err;
      // console.log(position.coords);
      const { latitude, longitude } = position.coords;
      this.props.fetchCurrentLocation({ latitude, longitude });
    });

    // setInterval(this.getCatalogHandler, 10000);
  }

  /**
   * Calls when props or state are updated
   * Accept previous props
   * Show Toast message based on user authentication
   * Show welcome message when user is authenticated
   * Show Login fail message when user isn't authenticated
   */
  componentDidUpdate(prevProps) {
    // this.props.removeSwitchGroupIdFromDataset()
    // Update notification manager state based on user authentication previous and and present satate
    if (
      this.props.authData.isAuthenticated !== prevProps.authData.isAuthenticated
    ) {
      if (this.props.authData.isAuthenticated) {
        setInterval(() => this.props.loadNotification(), 10000);
      } else {
        this.props.getMapLocations();
        NotificationManager.error("You are not logged in");
      }
    }
  }
  render() {
    const loginStatus = localStorage.getItem("loginStatus");

    return (
      <Fragment>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(DATSETS_CATALOG)}
          </script>
        </Helmet>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<LazyLoadingHeader />}>
            <Router>
              <Header />
              {/* <LazyLoadingHeader/> */}
              <Switch>
                <Route exact path={ABOUT} component={About} />
                <Route exact path={CONTACT_US} component={ContactUs} />
                <Route exact path={FAQ} component={FaqPage} />
                <Route exact path={WIKI} component={Wiki} />
                <Route exact path={PAGE404} component={PageNotFound} />
                <Route
                  exact
                  path={PROFILE}
                  render={(props) => {
                    if (loginStatus) {
                      return <Profile {...props} />;
                    } else return <Redirect to={WELCOME} />;
                  }}
                />

                <Route
                  exact
                  path={SIGNUP}
                  render={(props) => {
                    if (this.props.authData.isAuthenticated) {
                      return <Redirect to={HOME_DATASETS} />;
                    } else return <SignUp {...props} />;
                  }}
                />
                <Route
                  exact
                  path={CHECK_SIGNUP}
                  render={(props) => {
                    if (this.props.authData.isAuthenticated) {
                      return <Redirect to={HOME_DATASETS} />;
                    } else return <SignUpCheck {...props} />;
                  }}
                />
                <Route
                  exact
                  path={RESET}
                  render={(props) => {
                    if (this.props.authData.isAuthenticated) {
                      return <Redirect to={HOME_DATASETS} />;
                    } else return <Reset />;
                  }}
                />
                <Route
                  exact
                  path={RESET_PASSWORD}
                  render={(props) => {
                    if (this.props.authData.isAuthenticated) {
                      return <Redirect to={HOME_DATASETS} />;
                    } else return <ResetPassword {...props} />;
                  }}
                />
                {/* <Route path = {SINGLE_DATASET} component /> */}
                <Route
                  path={PAGE422}
                  render={(props) => {
                    if (this.props.authData.isAuthenticated) {
                      return <Redirect to={HOME_DATASETS} />;
                    } else {
                      return <Error422 />;
                    }
                  }}
                />
                <Route
                  path={JBROWSE}
                  render={(props) => {
                    if (localStorage.getItem("loginStatus")) {
                      return this.jBroserRedirection();
                    } else {
                      return <Redirect to={PAGE422} />;
                    }
                  }}
                />

                {/* <Route
                  path={SINGLE_DATASET}
                  render={(props) => {
                    return <DatasetDetail {...props} />;
                  }}
                /> */}
                <Route
                  path={REGISTER}
                  render={(props) => {
                    if (
                      this.props.authData.isAuthenticated &&
                      this.props.authData.isNewUser
                    ) {
                      return <RegisterUser />;
                    } else if (
                      this.props.authData.isAuthenticated &&
                      !this.props.authData.isNewUser
                    ) {
                      return <Redirect to={HOME_DATASETS} />;
                    } else {
                      return <Redirect to={WELCOME} />;
                    }
                  }}
                />
                <Route
                  exact
                  path={ROOT}
                  render={() => {
                    return this.props.authData.isAuthenticated ? (
                      <Redirect to={HOME_DATASETS} />
                    ) : (
                      <Redirect to={WELCOME} />
                    );
                  }}
                />
                <Route
                  exact
                  path={WELCOME}
                  render={(props) => {
                    if (!this.props.authData.isAuthenticated) {
                      return <WelcomeWindow {...props} />;
                    } else {
                      return <Redirect to={HOME_DATASETS} />;
                    }
                  }}
                />

                <Route
                  path={WELCOME_PUBLIC_SEARCH_PARAMS}
                  render={(props) => {
                    return this.props.authData.isAuthenticated ? (
                      <Redirect to={HOME_DATASETS} />
                    ) : (
                      <Body {...props} />
                    );
                  }}
                />
                <Route
                  path={HOME_PARAMS}
                  render={(props) => {
                    if (
                      this.props.authData.isAuthenticated &&
                      this.props.authData.isNewUser
                    ) {
                      return <Redirect to={REGISTER} />;
                    }
                    if (
                      !this.props.authData.isAuthenticated &&
                      AUTHENTICATED_ROUTES.includes(props.location.pathname)
                    ) {
                      return <Redirect to={props.location.pathname} />;
                    } else {
                      return <Body {...props} />;
                    }
                  }}
                />

                <Route
                  path={WELCOME_TICKET}
                  render={(props) => {
                    return this.props.authData.isAuthenticated ? (
                      <Redirect to={HOME_DATASETS} />
                    ) : (
                      <Redirect to={WELCOME_PUBLIC_SEARCH_DATASETS} />
                    );
                  }}
                />

                {/* <Route path="/404" component={PageNotFound} /> */}
                <Route
                  path="*"
                  render={(props) => {
                    return <Redirect to="/404" />;
                  }}
                />
              </Switch>
              <NotificationContainer />
            </Router>

            <DataUploadDrawer />
            <ExpandedDataUploadDrawer />
          </Suspense>
        </ThemeProvider>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authData: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoginStatus: () => dispatch(getLoginStatus()),
    loadFilters: () => dispatch(getFilters()),
    loadNotification: () => dispatch(loadInvitationNotifications()),
    removeSwitchGroupIdFromDataset: () =>
      dispatch(removeSwitchGroupIdFromDataset()),
    getMapLocations: () => dispatch(getMapLocations()),
    fetchCurrentLocation: (location) =>
      dispatch(fetchCurrentLocation(location)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import { getBearerToken, baseUrl, checkAuthorization } from "./baseDataAccess";
import { axiosCall } from "./baseDataAccess";

export const loadFilters = () => {
  const payLoad = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axiosCall
    .get(baseUrl + "/api/v2/datasets/filters", {
      ...payLoad,
    })

    // .then((response) => {
    //   return {
    //     res: response,
    //     success: true,
    //   };
    // })
    // .catch((error) => {
    //   return {
    //     res: error,
    //     success: false,
    //   };
    // });
};

export const addNewFilter = (filter, authToken = null) => {
  let isAuthorized = authToken !== null ? true : checkAuthorization();
  let route = isAuthorized ? "/api/filter/create" : "/api/filter/public/create";

  const payLoad = {
    headers: {
      Authorization: authToken !== null ? authToken : getBearerToken(),
    },
  };

  return axiosCall
    .post(baseUrl + route, filter, {
      ...payLoad,
    })
    .then((response) => {
      return {
        res: response,
        success: true,
      };
    })
    .catch((error) => {
      return {
        res: error,
        success: false,
      };
    });
};

export const dltFilter = (filterId) => {
  let isAuthorized = checkAuthorization();
  let route = isAuthorized ? "/api/filter/delete" : "/api/filter/public/create";

  const payLoad = {
    headers: {
      Authorization: getBearerToken(),
    },
    params: {
      filter_id: filterId,
    },
  };
  return axiosCall
    .post(baseUrl + route, null, {
      ...payLoad,
    })
    .then((response) => {
      return {
        res: response,
        success: true,
      };
    })
    .catch((error) => {
      return {
        res: error,
        success: false,
      };
    });
};

export const updtFilter = (name, filterId) => {
  let isAuthorized = checkAuthorization();
  let route = isAuthorized ? "/api/filter/edit" : "/api/filter/public/edit";

  const payLoad = {
    headers: {
      Authorization: getBearerToken(),
    },
    params: {
      filter_id: filterId,
    },
  };
  return axiosCall
    .post(
      baseUrl + route,
      { name },
      {
        ...payLoad,
      }
    )
    .then((response) => {
      return {
        res: response,
        success: true,
      };
    })
    .catch((error) => {
      return {
        res: error,
        success: false,
      };
    });
};

export const loadMapLoactions = (name, filterId) => {
  // let isAuthorized = checkAuthorization();
  let route = "/api/datasets/location";

  const payLoad = {
    headers: {
      Authorization: getBearerToken(),
    },
    params: {
      filter_id: filterId,
    },
  };
  return axiosCall
    .get(baseUrl + route, payLoad)
    .then((response) => {
      return {
        res: response,
        success: true,
      };
    })
    .catch((error) => {
      return {
        res: error,
        success: false,
      };
    });
};

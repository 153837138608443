export const GET_USER_SEARCH_REQUEST = 'GET_USER_SEARCH_REQUEST'
export const GET_USER_SEARCH_REQUEST_SUCCESS = 'GET_USER_SEARCH_REQUEST_SUCCESS'
export const GET_USER_SEARCH_REQUEST_FAILED = 'GET_USER_SEARCH_REQUEST_FAILED'

export const LOAD_USER_BASIC_INFO_REQUEST = 'LOAD_USER_BASIC_INFO_REQUEST'
export const LOAD_USER_BASIC_INFO_SUCCESS = 'LOAD_USER_BASIC_INFO_SUCCESS'
export const LOAD_USER_BASIC_INFO_FAILED = 'LOAD_USER_BASIC_INFO_FAILED'

export const GET_USER_DATASETS_REQUEST = 'GET_USER_DATASETS_REQUEST'
export const GET_USER_DATASETS_SUCCESS = 'GET_USER_DATASETS_SUCCESS'
export const GET_USER_DATASETS_FAILED = 'GET_USER_DATASETS_FAILED'

export const GET_USER_GROUPS_REQUEST = 'GET_USER_GROUPS_REQUEST'
export const GET_USER_GROUPS_SUCCESS = 'GET_USER_GROUPS_SUCCESS'
export const GET_USER_GROUPS_FAILED = 'GET_USER_GROUPS_FAILED'

export const GET_USER_FOLLOWERS_REQUEST = 'GET_USER_FOLLOWERS_REQUEST'
export const GET_USER_FOLLOWERS_SUCCESS = 'GET_USER_FOLLOWERS_SUCCESS'
export const GET_USER_FOLLOWERS_FAILED = 'GET_USER_FOLLOWERS_FAILED'

export const GET_USER_FOLLOWING_REQUEST = 'GET_USER_FOLLOWING_REQUEST'
export const GET_USER_FOLLOWING_SUCCESS = 'GET_USER_FOLLOWING_SUCCESS'
export const GET_USER_FOLLOWING_FAILED = 'GET_USER_FOLLOWING_FAILED'

export const TOGGLE_USER_FOLLOWER = 'TOGGLE_USER_FOLLOWER'
export const TOGGLE_USER_FOLLOWING = 'TOGGLE_USER_FOLLOWING'
export const TOGGLE_SEARCH_USER_FOLLOW = 'TOGGLE_SEARCH_USER_FOLLOW'

export const DISMISS_NUMBER_OF_USER_RESULTS = 'DISMISS_NUMBER_OF_USER_RESULTS'
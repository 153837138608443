import * as softwareActionTypes from './softwareActionTypes';


const initialState = {
    isWorkflowLoading: false,
    runnableList: null,
    selectedWorkflowName: '',
    currentWfDatasetId: 0,
    workflowList: [],
    error: null,
    showRunVizsciflowModal: false,
}


const softwareReducer = (state = initialState, action) => {
    switch (action.type) {
        case softwareActionTypes.LOAD_WORKFLOW_REQUEST:
            return {
                ...state,
                isWorkflowLoading: true,
                workflowList: []
            }
        case softwareActionTypes.LOAD_WORKFLOW_SUCCESS:
            return {
                ...state,
                isWorkflowLoading: false,
                workflowList: action.payload.workflows
            }
        case softwareActionTypes.LOAD_WORKFLOW_FAILED:
            return {
                ...state,
                isWorkflowLoading: false,
                error: action.payload.error
            }

        case softwareActionTypes.SHOW_MODAL_RUN_VIZSCIFLOW:
            return {
                ...state,
                showRunVizsciflowModal: true
            }
        case softwareActionTypes.HIDE_MODAL_RUN_VIZSCIFLOW:
            return {
                ...state,
                showRunVizsciflowModal: false
            }
        case softwareActionTypes.UPDATE_WORKFLOW_LIST:
            return {
                ...state,
                runnableList: action.payload.runnables
            }
        case softwareActionTypes.SELECT_A_WORKFLOW_NAME:
            return {
                ...state,
                selectedWorkflowName: action.payload.selectedWorkflowName
            }

        case softwareActionTypes.SELECT_RUNNING_WORKFLOW_DATASET:
            return {
                ...state,
                currentWfDatasetId: action.payload.currentWfDatasetId
            }
        default:
            return state;
    }
}

export default softwareReducer;
import React from "react";
import { Bar } from "react-chartjs-2";

class BarChart extends React.Component {
  render() {
    const { data, highlight, domain, count } = this.props;
    
    // calculate frequency of data
    // var counts = {};
    // for (let i = 0; i < 100; i++)
    // counts[data[i]] = counts[data[i]] + 1 || 1;
    
    // debugger
    // // generate data
    // const barDataValues = [];
    // for (let i = 0; i < highlight[1]; i++) {
    //   barDataValues.push(counts[i] || 0);
    // }

    const barData = {
      labels: data,
      datasets: [
        {
          backgroundColor: data.map((val, i) =>
            val >= highlight[0] && val <= highlight[1]
              ? "rgba(135, 206, 235, 1)"
              : "rgba(255, 99, 132, 0.2)"
          ),
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          data: count.map(bar => !bar ? (bar+1) : bar)
        }
      ]
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            display: false
          }
        ],
        yAxes: [
          {
            display: false,
            ticks: {
              min: 0
            }
          }
        ]
      }
    };

    return (
      <Bar data={barData} options={options} onElementsClick={data => data[0] && this.props.setBarData(data[0]._view.label)}/>
    ) 
  }
}

export default BarChart;

import * as groupActionTypes from "./groupActionTypes";
import * as expandedResourceActionTypes from "../expandedResourceStore/expandedResourcesActionType";
import * as dataAccess from "../../dataaccess/groupsDataAccess";
import { NotificationManager } from "react-notifications";
import { showAddDatasetModal } from "../datasetsStore/datasetActions";
import { push } from "react-router-redux";
import store from "../store";
import {
  updateGroupReqSent,
  updateActiveAccordion,
} from "../../store/groupsStore/groupMemberAction";

//Actions
export const actionGetGroupsRequest = () => {
  return {
    type: groupActionTypes.GET_GROUPS_REQUEST,
  };
};

const actionGetGroupsSuccess = (payload) => {
  return {
    type: groupActionTypes.GET_GROUPS_SUCCESS,
    payload: payload,
  };
};

export const actionGetGroupFailed = (payload) => {
  return {
    type: groupActionTypes.GET_GROUPS_FAILED,
    payload: payload,
  };
};

export const actionGetGroupSearchResultsRequest = () => {
  return {
    type: groupActionTypes.GET_GROUPS_SEARCH_RESULTS_REQUEST,
  };
};

const actionGetGroupSearchResultsSuccess = (payload) => {
  return {
    type: groupActionTypes.GET_GROUPS_SEARCH_RESULTS_SUCCESS,
    payload: payload,
  };
};

export const actionGetGroupSearchResultsFailed = (payload) => {
  return {
    type: groupActionTypes.GET_GROUPS_SEARCH_RESULTS_FAILED,
    payload: payload,
  };
};

export const actionGetSwitchedGroupsRequest = () => {
  return {
    type: groupActionTypes.GET_SWITCHED_GROUPS_REQUEST,
  };
};

const actionGetSwitchedGroupsSuccess = (payload) => {
  return {
    type: groupActionTypes.GET_SWITCHED_GROUPS_SUCCESS,
    payload: payload,
  };
};

export const actionGetSwitchedGroupsFailed = (payload) => {
  return {
    type: groupActionTypes.GET_SWITCHED_GROUPS_FAILED,
    payload: payload,
  };
};

export const actionGetGroupDetailsRequest = () => {
  return {
    type: groupActionTypes.GET_GROUP_DETAILS_REQUEST,
  };
};

const actionGetGroupDetailsSuccess = (payload) => {
  return {
    type: groupActionTypes.GET_GROUP_DETAILS_SUCCESS,
    payload: payload,
  };
};

export const actionGetGroupDetailsFailed = (payload) => {
  return {
    type: groupActionTypes.GET_GROUP_DETAILS_FAILED,
    payload: payload,
  };
};

const actionSelectAGroup = (payload) => {
  return {
    type: groupActionTypes.SELECT_A_GROUP,
    payload: payload,
  };
};

const actionDismissNumberOfGroupResults = () => {
  return {
    type: groupActionTypes.DISMISS_NUMBER_OF_GROUP_RESULTS,
  };
};

const actionSetNumberOfGroupResults = (payload) => {
  return {
    type: groupActionTypes.SET_NUMBER_OF_GROUP_RESULTS,
    payload: payload,
  };
};

const actionEditGroupTitleRequest = () => {
  return {
    type: groupActionTypes.EDIT_GROUP_TITLE_REQUEST,
  };
};

const actionEditGroupTitleSuccess = (payload) => {
  return {
    type: groupActionTypes.EDIT_GROUP_TITLE_SUCCESS,
    payload: payload,
  };
};

const actionEditGroupTitleFailed = () => {
  return {
    type: groupActionTypes.EDIT_GROUP_TITLE_FAILED,
  };
};

const actionEditGroupDescriptionSuccess = (payload) => {
  return {
    type: groupActionTypes.EDIT_GROUP_DESCRIPTION_SUCCESS,
    payload: payload,
  };
};

const actionEditGroupAdminSuccess = (payload) => {
  return {
    type: groupActionTypes.EDIT_GROUP_ADMIN_SUCCESS,
    payload: payload,
  };
};

const actionEditGroupPermissionSuccess = (payload) => {
  return {
    type: groupActionTypes.EDIT_GROUP_PERMISSION_SUCCESS,
    payload: payload,
  };
};

const actionCreateNewGroupFailed = () => {
  return {
    type: groupActionTypes.CREATE_GROUP_FAILED,
  };
};

export const actionGetParentGroupsRequest = () => {
  return {
    type: groupActionTypes.GET_PARENT_GROUPS_REQUEST,
  };
};

const actionGetParentGroupsSuccess = (payload) => {
  return {
    type: groupActionTypes.GET_PARENT_GROUPS_SUCCESS,
    payload: payload,
  };
};

export const actionGetParentGroupsFailed = (payload) => {
  return {
    type: groupActionTypes.GET_PARENT_GROUPS_FAILED,
    payload: payload,
  };
};

const actionAppendNewMetadata = (payload) => {
  return {
    type: groupActionTypes.APPEND_NEW_METADATA,
    payload: payload,
  };
};

const actionShowAddEditGroupModal = (payload) => {
  return {
    type: groupActionTypes.SHOW_MODAL_ADD_EDIT_GROUP,
    payload: payload,
  };
};

const actionHideAddEditGroupModal = () => {
  return {
    type: groupActionTypes.HIDE_MODAL_ADD_EDIT_GROUP,
  };
};

const actionShowDataTemplateModal = () => {
  return {
    type: groupActionTypes.SHOW_MODAL_DATA_TEMPLATE,
  };
};

const actionHideDataTemplateModal = () => {
  return {
    type: groupActionTypes.HIDE_MODAL_DATA_TEMPLATE,
  };
};

const actionShowGroupDetailModal = (payload) => {
  return {
    type: groupActionTypes.SHOW_GROUP_DETAILS_MODAL,
    payload,
  };
};

const actionHideGroupDetailModal = () => {
  return {
    type: groupActionTypes.HIDE_GROUP_DETAILS_MODAL,
  };
};

const actionRemoveMember = (payload) => {
  return {
    type: groupActionTypes.GROUP_MEMBER_REMOVAL,
    payload,
  };
};

//Action Creators
export const loadGroups = (pageNum, numOfItems, initialLoading = false) => {
  return (dispatch) => {
    if (initialLoading) {
      dispatch(actionGetGroupsRequest());
    }
    dataAccess.loadGroups(pageNum, numOfItems).then((response) => {
      if (response.success) {
        dispatch(actionGetGroupsSuccess(response.res.data));
        dispatch(updateActiveAccordion(null));

        if (JSON.parse(response.res.data.pageNum) === 1)
          dispatch(setNumberOfGroupResults(response.res.data.numberOfResults));

        return;
      } else {
        NotificationManager.error("Failed to load Groups!", "Error!");
        dispatch(actionGetGroupFailed(response.res));
      }
    });
  };
};

export const loadSwitchedGroups = (
  groupId,
  pageNum,
  numOfItems,
  initialLoading = false
) => {
  return (dispatch) => {
    if (initialLoading) {
      dispatch(actionGetSwitchedGroupsRequest());
    }
    dataAccess
      .loadSwitchedGroups(groupId, pageNum, numOfItems)
      .then((response) => {
        if (response.success) {
          dispatch(actionGetSwitchedGroupsSuccess(response.res.data));
          // dispatch(updateActiveAccordion(null));

          if (JSON.parse(response.res.data.pageNum) === 1)
            dispatch(
              setNumberOfGroupResults(response.res.data.numberOfResults)
            );

          return;
        } else {
          NotificationManager.error("Failed to load Groups!", "Error!");
          dispatch(actionGetSwitchedGroupsFailed(response.res));
        }
      });
  };
};

export const loadGroupSearchResults = (
  searchFilters,
  pageNum,
  numOfItems,
  isInitialLoad = false
) => {
  return (dispatch) => {
    if (isInitialLoad) {
      dispatch(actionGetGroupSearchResultsRequest());
    }
    if (pageNum === 1) {
      dispatch(dismissNumberOfGroupResults());
    }
    dataAccess
      .getGroupsSearchResults(searchFilters, pageNum, numOfItems)
      .then((response) => {
        if (response.success) {
          dispatch(actionGetGroupSearchResultsSuccess(response.res.data));
          dispatch(updateActiveAccordion(null));

          if (JSON.parse(response.res.data.pageNum) === 1)
            dispatch(
              setNumberOfGroupResults(response.res.data.numberOfResults)
            );

          return;
        } else {
          NotificationManager.error("Failed to load results!", "Error!");
          dispatch(actionGetGroupSearchResultsFailed(response.res));
        }
      });
  };
};

export const loadDetails = (isUserGroup, groupId, token = null) => {
  return (dispatch) => {
    dispatch(actionGetGroupDetailsRequest());
    dataAccess.getGroupDetails(isUserGroup, groupId, token).then((response) => {
      if (response.success) {
        if (response.res.data.joinRequest !== undefined)
          dispatch(updateGroupReqSent(response.res.data.joinRequest));

        dispatch(actionGetGroupDetailsSuccess(response.res.data));
        return;
      }
      dispatch(actionGetGroupDetailsFailed(response.res));
    });
  };
};

export const selectAGroup = (groupID) => {
  return (dispatch) => {
    dispatch(actionSelectAGroup({ selectedGroupId: groupID }));
  };
};

export const dismissNumberOfGroupResults = () => {
  return (dispatch) => {
    dispatch(actionDismissNumberOfGroupResults());
  };
};

export const setNumberOfGroupResults = (numOfItems) => {
  return (dispatch) => {
    dispatch(
      actionSetNumberOfGroupResults({ numberOfSearchResults: numOfItems })
    );
  };
};

export const editGroupTitle = (groupId, formData) => {
  let groupTitle = formData.get("name");
  return (dispatch) => {
    dispatch(actionEditGroupTitleRequest());
    dataAccess.editGroupTitle(groupId, formData).then((response) => {
      if (response.success) {
        if (response.res.data.isEdit) {
          dispatch(
            actionEditGroupTitleSuccess({
              groupId: groupId,
              groupTitle: groupTitle,
            })
          );
          return;
        }
      }
    });
    dispatch(actionEditGroupTitleFailed());
  };
};

export const editGroupDescription = (groupId, description) => {
  return (dispatch) => {
    dispatch(
      actionEditGroupDescriptionSuccess({
        groupId: groupId,
        groupDescrption: description,
      })
    );
  };
};

export const editGroupAdmin = (groupId, groupDetails) => {
  return (dispatch) => {
    dispatch(
      actionEditGroupAdminSuccess({
        groupId: groupId,
        updatedGroupDetails: groupDetails,
      })
    );
  };
};

export const editParentGroup = (groupId, groupDetails) => (dispatch) =>
  dispatch({
    type: groupActionTypes.EDIT_PARENT_GROUP_SUCCESS,
    payload: { groupId: groupId, updatedGroupDetails: groupDetails },
  });

export const editGroupPermission = (groupId, groupDetails) => {
  return (dispatch) => {
    dispatch(
      actionEditGroupPermissionSuccess({
        groupId: groupId,
        updatedGroupPermissionDetails: groupDetails,
      })
    );
  };
};

export const createNewGroup = (newGroup, props) => {
  return (dispatch, getState) => {
    dataAccess.saveGroup(newGroup).then((response) => {
      if (response.success) {
        // console.log(response, "Response");
        dispatch(loadGroups(1, getState().groups.numOfItems, true));
        // dispatch(getParentGroups("group"))
        if (props.groupData.createDataset) {
          dispatch(showAddDatasetModal());
          dispatch(getParentGroups("dataset"));
          // props.history.push(`/datasets`);
        } else {
          props.history.push(`/group/${response.res.data.id}`);
        }
        NotificationManager.success("Success!", "New Group Added!");
        return;
      } else {
        NotificationManager.error("Error!", "Something Wrong!");
        dispatch(actionCreateNewGroupFailed({ error: response.res }));
      }
    });
    // console.log(props);
  };
};

export const getParentGroups = (type, groupId, authToken = null) => {
  console.log("SSSSSSSSSSSSSSSSSSS")
  return (dispatch) => {
    dispatch(actionGetParentGroupsRequest());
    dataAccess.getParentGroups(type, groupId, authToken).then((response) => {
      if (response.success) {
        dispatch(
          actionGetParentGroupsSuccess({ data: response.res.data.groups })
        );
        return;
      }
      NotificationManager.error("Error!", "Failed to load groups");
      dispatch(actionGetParentGroupsFailed({ error: response.res }));
    });
  };
};

export const appendNewMetadata = (newMetadata) => {
  return (dispatch) => {
    dispatch(actionAppendNewMetadata(newMetadata));
  };
};

export const showAddEditGroupModal = (isCreateDataset = false) => {
  return (dispatch) => {
    dispatch(actionShowAddEditGroupModal(isCreateDataset));
  };
};

export const hideAddEditGroupModal = () => {
  return (dispatch) => {
    dispatch(actionHideAddEditGroupModal());
  };
};

export const showDataTemplateModal = () => {
  return (dispatch) => {
    dispatch(actionShowDataTemplateModal());
  };
};

export const hideDataTemplateModal = () => {
  return (dispatch) => {
    dispatch(actionHideDataTemplateModal());
  };
};

export const showGroupDetailModal = (isUserGroup, group) => {
  return (dispatch) => {
    dispatch(
      actionShowGroupDetailModal({
        selectedGroupHeader: group,
        isUserGroup: isUserGroup,
      })
    );
  };
};

export const hideGroupDetailModal = () => {
  return (dispatch) => {
    dispatch(actionHideGroupDetailModal());
  };
};

export const removeMember = (groupId, memberId) => {
  return (dispatch) => {
    dataAccess.removeMember(groupId, memberId).then((response) => {
      if (response.success) {
        if (response.res.data.isRemoved) {
          dispatch(
            actionRemoveMember({ groupId: groupId, memberId: memberId })
          );
          NotificationManager.success("Success!", "Member removed!");
        }
      } else {
        NotificationManager.error("Error!", "Failed to remove member!");
      }
    });
  };
};

const updateOrDeleteGroupAction = (groups) => ({
  type: groupActionTypes.UPDATE_OR_DELETE_GROUPS,
  payload: groups,
});

export const updateOrDeleteGroup = (groups) => async (dispatch) => {
  dispatch(updateOrDeleteGroupAction(groups));
};

export const uploadDataTemplateFromDataset = () => (dispatch) =>
  dispatch({ type: groupActionTypes.UPLOAD_DATA_TEMPLATE_FROM_DATSET });

import React from "react";
import { withRouter } from "react-router-dom";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { validateGroupName } from "../../../dataaccess/groupsDataAccess";
import {
  createNewGroup,
  hideAddEditGroupModal,
} from "../../../store/groupsStore/groupActions";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";

const initState = {
  hasParentGroup: false,
  groupName: "",
  parentGroup: "",
  nameError: "",
};
let IsFormValid = true;

class AddEditGroupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  handleClose = () => {
    this.setState(initState);
    this.props.handleModalHideEvent();
  };

  handleValidation = () => {
    IsFormValid = true;

    this.setState({
      nameError: "",
    });

    if (!this.state.groupName) {
      IsFormValid = false;
      this.setState({
        nameError: "Name cannot be empty!",
      });
    }

    return IsFormValid;
  };

  validateUniqueName = (e) => {
    let groupName = e.target.value;

    if (groupName) {
      validateGroupName(groupName).then((response) => {
        if (response.success) {
          if (response.res.data) {
            IsFormValid = false;
            this.setState({
              nameError: "This name is taken. Choose another name!",
            });
          } else {
            IsFormValid = true;
            this.setState({
              nameError: "",
            });
          }
        } else {
          NotificationManager.error(
            "Error!",
            "Failed to validate dataset name!"
          );
        }
      });
    }
  };

  saveGroup = () => {
    let IsFormValid = this.handleValidation();

    if (IsFormValid) {
      this.props.createNewGroup(this.state, this.props);
      this.setState(initState);
      this.props.handleModalHideEvent();
    }
  };

  parentGroups = (groups) => {
    let options = [];
    options.push(
      <option value="" key="0">
        --Select a group--
      </option>
    );

    groups.forEach((aGroup) => {
      options.push(
        <option value={aGroup.id} key={aGroup.id}>
          {aGroup.name}
        </option>
      );
    });

    return options;
  };

  groupNameChangeHandler = (e) => {
    this.setState({
      groupName: e.target.value,
    });
  };

  parentGroupChangeHandler = (e) => {
    this.setState({
      parentGroup: e.target.value,
      hasParentGroup: e.target.value ? true : false,
    });
  };

  render() {
    const { groupName, parentGroup, nameError } = this.state;
    return (
      <Modal
        show={this.props.groupData.showAddEditGroupModal}
        onHide={this.handleClose}
        centered
        size="lg"
      >
        <Modal.Header closeButton className={"modal-header"}>
          <Modal.Title className={"modal-header-text"}>
            Add New Group
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="groupName">
              <Form.Label column sm="3">
                Group Name:<span className="required-mark">*</span>
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder=""
                  onChange={this.groupNameChangeHandler}
                  onKeyUp={this.validateUniqueName}
                />
                {this.state.nameError && (
                  <span style={{ color: "red" }}>{this.state.nameError}</span>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="ddlGroup">
              <Form.Label column sm="3">
                Parent Group:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="select"
                  custom
                  onChange={this.parentGroupChangeHandler}
                >
                  {this.parentGroups(this.props.groupData.parentGroups)}
                </Form.Control>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={this.saveGroup}
            disabled={!groupName  || nameError}
          >
            Create
          </Button>
          <Button variant="danger" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groupData: state.groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleModalHideEvent: () => dispatch(hideAddEditGroupModal()),
    createNewGroup: (newGroup, props) => dispatch(createNewGroup(newGroup, props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddEditGroupModal))

// export default connect(mapStateToProps, mapDispatchToProps)(AddEditGroupModal);

import { useEffect } from "react";
import PropTypes from "prop-types";
import L from "leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { useLeaflet,  } from "react-leaflet";

// const mcg = L;

const MarkerCluster = ({ markers, handleMarkerSelection }) => {
  const { map } = useLeaflet();

  useEffect(() => {
  
    // mcg.clearLayers();
    if (markers) {
     
      // if(map){
      //   map.eachLayer(function (layer) {
      //   map.removeLayer(layer);
      // });
      // }
      // for (let i=0;i<markers.length;i++) {
      //   map.removeLayer(markers[i].coordinate);
      // }
      // points=[];
      L.marker();
      markers.forEach(
        ({ coordinate}) => {
          return L.marker(new L.LatLng(coordinate[0], coordinate[1]))
            .addTo(map)
            // .bindPopup(
            //   new L.Popup().setLatLng(coordinate[0], coordinate[1]).setContent(
            //     `<div>
            //       <span style="font-size: large">${dataset_name}</span> <br>
            //       <span>${group_name}</span> <br>
            //       <span>${address}</span> <br>
            //       <button class="ant-btn ant-btn-sm" id="setLocationByMarker">Apply Filter<span class="id" style="display: none">${dataset_id}<span></button>
            //     </div>`
            //   )
            // );
        }
      );

      // optionally center the map around the markers
      // map.fitBounds(mcg.getBounds());

      // add the marker cluster group to the map
      // map.addLayer(mcg);

      window.addEventListener("click", (e) => {
        if (e.target.id === "setLocationByMarker") {
          handleMarkerSelection({
            id: parseInt(e.target.childNodes[1].innerHTML.split("<")[0]),
            location: e.target.parentElement.childNodes[9].innerText,
          });
        }
      });
    }
  }, [markers, handleMarkerSelection, map]);

 return null;
};

MarkerCluster.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.objectOf(PropTypes.number).isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default MarkerCluster;

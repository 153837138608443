export const LOAD_WORKFLOW_REQUEST = 'LOAD_WORKFLOW_REQUEST';
export const LOAD_WORKFLOW_SUCCESS = 'LOAD_WORKFLOW_SUCCESS';
export const LOAD_WORKFLOW_FAILED = 'LOAD_WORKFLOW_FAILED';

export const SHOW_MODAL_RUN_VIZSCIFLOW = 'SHOW_MODAL_RUN_VIZSCIFLOW';
export const HIDE_MODAL_RUN_VIZSCIFLOW = 'HIDE_MODAL_RUN_VIZSCIFLOW';

export const UPDATE_WORKFLOW_LIST = 'UPDATE_WORKFLOW_LIST';

export const SELECT_A_WORKFLOW_NAME = 'SELECT_A_WORKFLOW_NAME';
export const SELECT_RUNNING_WORKFLOW_DATASET = 'SELECT_RUNNING_WORKFLOW_DATASET';

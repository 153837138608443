import React from "react";
import { ResponsiveContainer } from "recharts";
import { updateSelectedFilterValueSelections, initYearFilter, handleYearUpdate} from '../../../store/searchStore/searchActions';
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { MuiRail, MuiHandle, MuiTrack, MuiTick, TooltipRail } from "./components";
import BarChart from "./BarChart";
import { connect } from "react-redux";

const dataset = [];
for (let i = 0; i < 100; i++) {
  dataset.push({year: 1950 + (i+1), count: Math.floor(Math.random() * 80) + 1});
}

class RangeSlider extends React.Component { 

  handleSelectedFilterValueSelections = (selectedOptions) => {
    this.props.updateSelectedFilterValueSelections(selectedOptions, "year");
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.searchStore.yearValues !== prevProps.searchStore.yearValues){
      this.handleSelectedFilterValueSelections(this.props.searchStore.yearValues)
    }
  }

  componentDidMount(){
    if(!this.props.searchStore.yearDomain){
      const sortedData = this.props.searchStore.datasetYears
                        ? this.props.searchStore.datasetYears.sort((a, b) => a.label - b.label)
                        : dataset.sort((a, b) => a.label - b.label)
      const yearRange = [sortedData[0].label, sortedData[sortedData.length - 1].label];
      const yearData = sortedData.map(data => data.label)
      const count = sortedData.map(data => data.value)

      this.props.initYearFilter(yearRange, count, yearData)
    }
  }

  setBarData = (barValue) => {
    let value = [barValue, barValue]
    this.props.handleYearUpdate({yearUpdate: value, yearValues: value})
  }
  
  render() {
    const { yearDomain, yearValues, yearUpdate, yearData, yearCount } = this.props.searchStore;
    
    return (
      <ResponsiveContainer width="100%" height={this.props.height || 250}>
        <div style={{ height: "100%" }}>
          <BarChart
            data={yearData}
            count={yearCount}
            highlight={yearUpdate}
            domain={yearDomain}
            setBarData={this.setBarData}
          />
          <Slider
            mode={1}
            step={1}
            domain={yearDomain}
            rootStyle={{
              position: "relative",
              width: "100%"
            }}
            onUpdate={yearUpdate => this.props.handleYearUpdate({yearUpdate})}
            onChange={yearValues => this.props.handleYearUpdate({yearValues})}
            values={yearValues}
          >
            <Rail>
              {/* {({ getRailProps }) => <TooltipRail getRailProps={getRailProps} />} */}
              {({ getRailProps }) => <MuiRail getRailProps={getRailProps} />}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => (
                <div className="slider-handles">
                  {handles.map(handle => (
                    <MuiHandle
                      key={handle.id}
                      handle={handle}
                      domain={yearDomain}
                      getHandleProps={getHandleProps}
                    />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks left={false} right={false}>
              {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                  {tracks.map(({ id, source, target }) => (
                    <MuiTrack
                      key={id}
                      source={source}
                      target={target}
                      getTrackProps={getTrackProps}
                    />
                  ))}
                </div>
              )}
            </Tracks>
          </Slider>
        </div>
      </ResponsiveContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      searchStore: state.search
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      updateSelectedFilterValueSelections: (selectedOptions, filterType) => dispatch(updateSelectedFilterValueSelections(selectedOptions, filterType)),
      initYearFilter: (yearRange, yearData, count) => dispatch(initYearFilter(yearRange, yearData, count)),
      handleYearUpdate: (update) => dispatch(handleYearUpdate(update))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RangeSlider)
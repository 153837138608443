import React from 'react';

export default class Error422 extends React.Component{
  render(){
    return(
        <div className='container-full' style={{position:'absolute', height:'100%', width: '100%', textAlign: 'center'}}>
            <h2> Only authenticate user access Jbrowser </h2>
            <span> Please login to visit Jbrowse</span>
        </div>
    )
  }
}
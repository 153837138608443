import axios from "axios";
import {
  baseUrl,
  getBearerToken,
  checkAuthorization,
} from "../dataaccess/baseDataAccess";

export const extractIdFromSearchStringOrCurrentState = (
  searchString,
  currentState
) => (searchString ? parseInt(searchString.split("=")[1]) : currentState);

export const expandedEntity = (entityList, id) =>
  entityList.filter((entity) => entity.id === id)[0];

export const checkUpdateOrDeleteItems = async (url, itemList) => {
  try {
    const response = await axios.post(
      baseUrl + url,
      { data: itemList },

      {
        headers: {
          Authorization: getBearerToken(),
        },
      }
    );
    return response.data;
  } catch (error) {
    return null;
  }

  // return response.json();
};

export const parseFloatFixed = (x) => {
  return Number.parseFloat(x).toFixed(2);
};

export const fromArrayToString = (arrList) => {
  let res = "";
  arrList.forEach((element, index) => {
    if (index === arrList?.length - 1) {
      res += element;
    } else {
      res += element + ",";
    }
  });
  return res;
};

// export const jsonUploader = (e, setState, prevState) => {
//   const ext = e.target.files[0].name.split(".");
//   if (ext[ext.length - 1] === "json") {
//     console.log("here");
//     const fileReader = new FileReader();
//     fileReader.readAsText(e.target.files[0], "UTF-8");
//     fileReader.onload = (e) => {
//       // console.log("Result ===>", JSON.parse(e.target.result));
//       const fields = JSON.parse(e.target.result);
//       const prsRes = {};

//       const templateData = {
//         name: prsRes?.name ? prsRes?.name : "",
//         groupId: prsRes?.groupId ? prsRes?.groupId : "",
//         groupName: prsRes?.groupName ? prsRes?.groupName : "",
//         group:
//           prsRes?.groupName && prsRes?.groupId
//             ? { label: prsRes?.groupName, value: prsRes?.groupId }
//             : "",
//         location: {
//           // latitude: prsRes?.location.latitude
//           //   ? prsRes?.location?.latitude
//           //   : "",
//           // longitude: prsRes?.location?.longitude
//           //   ? prsRes?.location?.longitude
//           //   : "",
//           address: prsRes?.location?.address ? prsRes?.location?.address : "",
//           city: prsRes?.location?.city ? prsRes?.location?.city : "",
//           country: prsRes?.location?.country ? prsRes?.location?.country : "",
//           name: prsRes?.location?.name ? prsRes?.location?.name : "",
//           state: prsRes?.location?.state ? prsRes?.location?.state : "",
//           street: prsRes?.location?.street ? prsRes?.location?.street : "",
//         },
//         alternateName: prsRes?.alternateName ? prsRes?.alternateName : "",
//         citation: prsRes?.citation ? prsRes?.citation : "",
//         sameAs: prsRes?.sameAs ? prsRes?.sameAs : "",
//         description: prsRes?.description ? prsRes?.description : "",
//       };

//       if (prsRes && prsRes?.creators && Array.isArray(prsRes?.creators)) {
//         templateData.creators = fromArrayToString(prsRes?.creators);
//       }
//       if (prsRes && prsRes?.keywords && Array.isArray(prsRes?.keywords)) {
//         templateData.keyword = fromArrayToString(prsRes?.keywords);
//       }

//       if (
//         prsRes?.temporalCoverage?.startDate &&
//         new Date(prsRes?.temporalCoverage?.startDate).toString() ===
//           "Invalid Date"
//       ) {
//         errors.from = "Start date is invalid";
//       }
//       if (
//         prsRes?.temporalCoverage?.endDate &&
//         new Date(prsRes?.temporalCoverage?.endDate).toString() ===
//           "Invalid Date"
//       ) {
//         errors.to = "End date is invalid";
//       }
//       if (prsRes?.temporalCoverage?.isOngoing) {
//         const isOngoing = prsRes?.temporalCoverage?.isOngoing
//           .toString()
//           .toLowerCase();
//         if (isOngoing === "true") {
//           prsRes.temporalCoverage.isOngoing = true;
//         } else if (isOngoing === "false") {
//           prsRes.temporalCoverage.isOngoing = true;
//         } else {
//           errors.isOngoing = "Ongoing type is invalid";
//         }
//       }
//       // if (
//       //   prsRes?.temporalCoverage?.isOngoing &&
//       //   prsRes?.temporalCoverage?.isOngoing.toString().toLowerCase() ===
//       //     "false"
//       // ) {
//       //   prsRes.temporalCoverage.isOngoing = false;
//       // }

//       templateData.year = {
//         from:
//           prsRes?.temporalCoverage?.startDate && errors.from === undefined
//             ? new Date(prsRes?.temporalCoverage?.startDate)
//             : "",
//         to:
//           prsRes?.temporalCoverage?.endDate && errors.to === undefined
//             ? new Date(prsRes?.temporalCoverage?.endDate)
//             : "",
//         currWork: prsRes?.temporalCoverage?.isOngoing
//           ? prsRes?.temporalCoverage?.isOngoing
//           : false,
//       };

//       if (prsRes?.location?.latitude && isNaN(prsRes?.location?.latitude)) {
//         errors.lat = "Latitude is invalid";
//       }
//       if (prsRes?.location?.longitude && isNaN(prsRes?.location?.longitude)) {
//         errors.lng = "Longitude is invalid";
//       }

//       templateData.location = {
//         ...templateData.location,
//         latitude:
//           prsRes?.location.latitude && errors.lat === undefined
//             ? prsRes?.location?.latitude
//             : "",
//         longitude:
//           prsRes?.location?.longitude && errors.lng === undefined
//             ? prsRes?.location?.longitude
//             : "",
//       };

//       // console.log(templateData, errors)
//       setState({ ...prevState, ...templateData, errors: errors });
//       // this.setState({ ...prevSstate, ...templateData, errors: errors });

//       // return ({ templateData, errors })
//     };
//   }
// };

export const templateBasicFields = (fields) => {
  const basicFields = {
    name: fields?.name ? fields?.name : "",
    groupId: fields?.groupId ? fields?.groupId : "",
    groupName: fields?.groupName ? fields?.groupName : "",
    group:
      fields?.groupName && fields?.groupId
        ? { label: fields?.groupName, value: fields?.groupId }
        : "",
    location: {
      address: fields?.location?.address ? fields?.location?.address : "",
      city: fields?.location?.city ? fields?.location?.city : "",
      country: fields?.location?.country ? fields?.location?.country : "",
      name: fields?.location?.name ? fields?.location?.name : "",
      state: fields?.location?.state ? fields?.location?.state : "",
      street: fields?.location?.street ? fields?.location?.street : "",
    },
    alternateName: fields?.alternateName ? fields?.alternateName : "",
    citation: fields?.citation ? fields?.citation : "",
    sameAs: fields?.sameAs ? fields?.sameAs : "",
    description: fields?.description ? fields?.description : "",
  };

  return basicFields;
};

export const templateYearFields = (fields, errors) => {
  const yearFields = {
    from:
      fields?.temporalCoverage?.startDate && errors.from === undefined
        ? new Date(fields?.temporalCoverage?.startDate)
        : "",
    to:
      fields?.temporalCoverage?.endDate && errors.to === undefined
        ? new Date(fields?.temporalCoverage?.endDate)
        : "",
    currWork: fields?.temporalCoverage?.isOngoing
      ? fields?.temporalCoverage?.isOngoing
      : false,
  };

  return yearFields;
};

export const templateLocationFields = (fields, location, errors) => {
  const locationFields = {
    ...location,
    latitude:
      fields?.location.latitude && errors.lat === undefined
        ? fields?.location?.latitude
        : "",
    longitude:
      fields?.location?.longitude && errors.lng === undefined
        ? fields?.location?.longitude
        : "",
  };

  return locationFields;
};

export const DATASET_GET_DATASETS_REQUEST = 'DATASET_GET_DATASETS_REQUEST';
export const DATASET_GET_DATASETS_SUCCESS = 'DATASET_GET_DATASETS_SUCCESS';
export const DATASET_GET_DATASETS_FAILED = 'DATASET_GET_DATASETS_FAILED';

export const DATASET_GET_DATASETS_SEARCH_RESULTS_SUCCESS = 'DATASET_GET_DATASETS_SEARCH_RESULTS_SUCCESS';
export const AFTER_CHANGE_PERMISSION_DATASET_GET_DATASETS_SEARCH_RESULTS_SUCCESS = 'AFTER_CHANGE_PERMISSION_DATASET_GET_DATASETS_SEARCH_RESULTS_SUCCESS';
export const DATASET_CLOSE_DATASET_SEARCH_ALERT_SUCCESS = 'DATASET_CLOSE_DATASET_SEARCH_ALERT_SUCCESS';

export const DATASET_CREATE_DATASET_REQUEST = 'DATASET_CREATE_DATASET_REQUEST';
export const DATASET_CREATE_DATASET_SUCCESS = 'DATASET_CREATE_DATASET_SUCCESS';
export const DATASET_CREATE_DATASET_FAILED = 'DATASET_CREATE_DATASET_FAILED';
 
export const DATASET_DELETE_DATASET_REQUEST = 'DATASET_DELETE_DATASET_REQUEST';
export const DATASET_DELETE_DATASET_SUCCESS = 'DATASET_DELETE_DATASET_SUCCESS';
export const DATASET_DELETE_DATASET_FAILED = 'DATASET_DELETE_DATASET_FAILED';

export const DATASET_ARCHIVE_DATASET_REQUEST = 'DATASET_ARCHIVE_DATASET_REQUEST';
export const DATASET_ARCHIVE_DATASET_SUCCESS = 'DATASET_ARCHIVE_DATASET_SUCCESS';
export const DATASET_ARCHIVE_DATASET_FAILED = 'DATASET_ARCHIVE_DATASET_FAILED';

export const DATASET_GET_DATASET_DETAILS_REQUEST = 'DATASET_GET_DATASET_DETAILS_REQUEST';
export const DATASET_GET_DATASET_DETAILS_SUCCESS = 'DATASET_GET_DATASET_DETAILS_SUCCESS';
export const DATASET_GET_DATASET_DETAILS_FAILED = 'DATASET_GET_DATASET_DETAILS_FAILED';

export const DATASET_GET_DATASET_PROPERTIES_REQUEST = 'DATASET_GET_DATASET_PROPERTIES_REQUEST';
export const DATASET_GET_DATASET_PROPERTIES_SUCCESS = 'DATASET_GET_DATASET_PROPERTIES_SUCCESS';
export const DATASET_GET_DATASET_PROPERTIES_FAILED = 'DATASET_GET_DATASET_PROPERTIES_FAILED';

export const DATASET_REGISTER_DATASET_REQUEST = 'DATASET_REGISTER_DATASET_REQUEST';
export const DATASET_REGISTER_DATASET_SUCCESS = 'DATASET_REGISTER_DATASET_SUCCESS';
export const DATASET_REGISTER_DATASET_FAILED = 'DATASET_REGISTER_DATASET_FAILED';

export const DATASET_ADD_NEW_METADATA_REQUEST = 'DATASET_ADD_NEW_METADATA_REQUEST';
export const DATASET_ADD_NEW_METADATA_SUCCESS = 'DATASET_ADD_NEW_METADATA_SUCCESS';
export const DATASET_ADD_NEW_METADATA_FAILED = 'DATASET_ADD_NEW_METADATA_FAILED';

export const DATASET_DOWNLOAD_METADATA_REQUEST = 'DATASET_DOWNLOAD_METADATA_REQUEST';
export const DATASET_DOWNLOAD_METADATA_SUCCESS = 'DATASET_DOWNLOAD_METADATA_SUCCESS';
export const DATASET_DOWNLOAD_METADATA_FAILED = 'DATASET_DOWNLOAD_METADATA_FAILED';

export const DATASET_UPLOAD_DATA_REQUEST = 'DATASET_UPLOAD_DATA_REQUEST';
export const DATASET_UPLOAD_DATA_SUCCESS = 'DATASET_UPLOAD_DATA_SUCCESS';
export const DATASET_UPLOAD_DATA_FAILED = 'DATASET_UPLOAD_DATA_FAILED';

export const DATASET_CLEAN_DATASET_REQUEST = 'DATASET_CLEAN_DATASET_REQUEST';
export const DATASET_CLEAN_DATASET_SUCCESS = 'DATASET_CLEAN_DATASET_SUCCESS';
export const DATASET_CLEAN_DATASET_FAILED = 'DATASET_CLEAN_DATASET_FAILED';

export const DATASET_PUBLISH_DATASET_REQUEST = 'DATASET_PUBLISH_DATASET_REQUEST';
export const DATASET_PUBLISH_DATASET_SUCCESS = 'DATASET_PUBLISH_DATASET_SUCCESS';
export const DATASET_PUBLISH_DATASET_FAILED = 'DATASET_PUBLISH_DATASET_FAILED';

export const DATASET_APPEND_NEW_DATA_TO_DATASET = 'DATASET_APPEND_NEW_DATA_TO_DATASET';
export const DATASET_APPEND_NEW_LINK_TO_DATASET = 'DATASET_APPEND_NEW_LINK_TO_DATASET';
export const DATASET_APPEND_NEW_METADATA_TO_DATASET = 'DATASET_APPEND_NEW_METADATA_TO_DATASET';
export const DATASET_SELECT_A_DATASET = 'DATASET_SELECT_A_DATASET';

export const DATASET_GET_MORE_DATA_REQUEST = 'DATASET_GET_MORE_DATA_REQUEST';
export const DATASET_GET_MORE_DATA_SUCCESS = 'DATASET_GET_MORE_DATA_SUCCESS';
export const DATASET_GET_MORE_DATA_FAILED = 'DATASET_GET_MORE_DATA_FAILED';

export const DATASET_GET_LESS_DATA_SUCCESS = 'DATASET_GET_LESS_DATA_SUCCESS';

export const DATASET_SYNC_DATASET_REQUEST = 'DATASET_SYNC_DATASET_REQUEST';
export const DATASET_SYNC_DATASET_SUCCESS = 'DATASET_SYNC_DATASET_SUCCESS';
export const DATASET_SYNC_DATASET_FAILED = 'DATASET_SYNC_DATASET_FAILED';

export const DATASET_UPDATE_CURRENT_FOLDER_FILES_REQUEST = 'DATASET_UPDATE_CURRENT_FOLDER_FILES_REQUEST';
export const DATASET_UPDATE_CURRENT_FOLDER_FILES_SUCCESS = 'DATASET_UPDATE_CURRENT_FOLDER_FILES_SUCCESS';
export const DATASET_UPDATE_CURRENT_FOLDER_FILES_FAILED = 'DATASET_UPDATE_CURRENT_FOLDER_FILES_FAILED';

export const DATASET_UPDATE_ACTIVE_ACCORDION = 'DATASET_UPDATE_ACTIVE_ACCORDION';

export const DATASET_DATA_COPY_FILES_TO_CLIPBOARD = 'DATASET_DATA_COPY_FILES_TO_CLIPBOARD';

export const DATASET_UPDATE_CURRENT_FOLDER = 'DATASET_UPDATE_CURRENT_FOLDER';

export const DATASET_UPDATE_CURRENT_FILEMAP = 'DATASET_UPDATE_CURRENT_FILEMAP';

export const DATASET_SHOW_ADD_DATASET_MODAL = 'DATASET_SHOW_ADD_DATASET_MODAL';
export const DATASET_HIDE_ADD_DATASET_MODAL = 'DATASET_HIDE_ADD_DATASET_MODAL';

export const DATASET_SHOW_REGISTER_DATASET_MODAL = 'DATASET_SHOW_REGISTER_DATASET_MODAL';
export const DATASET_HIDE_REGISTER_DATASET_MODAL = 'DATASET_HIDE_REGISTER_DATASET_MODAL';

export const DATASET_HANDLE_LIKE_REQUEST = 'DATASET_HANDLE_LIKE_REQUEST';
export const DATASET_HANDLE_LIKE_SUCCESS = 'DATASET_HANDLE_LIKE_SUCCESS';
export const DATASET_HANDLE_LIKE_FAILED = 'DATASET_HANDLE_LIKE_FAILED';

export const DATASET_SHOW_SUBMIT_METADATA_ALERT_MODAL = 'DATASET_SHOW_SUBMIT_METADATA_ALERT_MODAL';
export const DATASET_HIDE_SUBMIT_METADATA_ALERT_MODAL = 'DATASET_HIDE_SUBMIT_METADATA_ALERT_MODAL';

export const DATASET_RESET_METADATA_TAB_CHANGE_FLAG = 'DATASET_RESET_METADATA_TAB_CHANGE_FLAG';
export const DATASET_SET_METADATA_TAB_CHANGE_FLAG = 'DATASET_SET_METADATA_TAB_CHANGE_FLAG';

export const DATASET_SHOW_DATASTSET_SHARE_MODAL = 'DATASET_SHOW_DATASTSET_SHARE_MODAL';
export const DATASET_HIDE_DATASET_SHARE_MODAL = 'DATASET_HIDE_DATASET_SHARE_MODAL';

export const DATASET_LOAD_SHAREABLE_USERS_REQUEST = 'DATASET_LOAD_SHAREABLE_USERS_REQUEST';
export const DATASET_LOAD_SHAREABLE_USERS_SUCCESS = 'DATASET_LOAD_SHAREABLE_USERS_SUCCESS';
export const DATASET_LOAD_SHAREABLE_USERS_FAILED = 'DATASET_LOAD_SHAREABLE_USERS_FAILED';

export const DATASET_LOAD_SHAREABLE_GROUP_REQUEST = 'DATASET_LOAD_SHAREABLE_GROUP_REQUEST'; 
export const DATASET_LOAD_SHAREABLE_GROUP_SUCCESS = 'DATASET_LOAD_SHAREABLE_GROUP_SUCCESS';
export const DATASET_LOAD_SHAREABLE_GROUP_FAILED = 'DATASET_LOAD_SHAREABLE_GROUP_FAILED';

export const DATASET_SHARE_WITH_USER_REQUEST = 'DATASET_SHARE_WITH_USER_REQUEST';
export const DATASET_SHARE_WITH_USER_SUCCESS = 'DATASET_SHARE_WITH_USER_SUCCESS';
export const DATASET_SHARE_WITH_USER_FAILED = 'DATASET_SHARE_WITH_USER_FAILED';

export const DATASET_UNSHARE_WITH_USER_SUCCESS = 'DATASET_UNSHARE_WITH_USER_SUCCESS';

export const DATASET_SHARE_WITH_GROUP_REQUEST = 'DATASET_SHARE_WITH_GROUP_REQUEST';
export const DATASET_SHARE_WITH_GROUP_SUCCESS = 'DATASET_SHARE_WITH_GROUP_SUCCESS';
export const DATASET_SHARE_WITH_GROUP_FAILED = 'DATASET_SHARE_WITH_GROUP_FAILED';

export const DATASET_UNSHARE_WITH_GROUP_SUCCESS = 'DATASET_UNSHARE_WITH_GROUP_SUCCESS';

export const DATASET_OPEN_UPLOAD_DRAWER = 'DATASET_OPEN_UPLOAD_DRAWER';
export const DATASET_CLOSE_UPLOAD_DRAWER = 'DATASET_CLOSE_UPLOAD_DRAWER';

export const DATASET_ID_NAME_PAIRS_REQUEST = 'DATASET_ID_NAME_PAIRS_REQUEST';
export const DATASET_ID_NAME_PAIRS_SUCCESS = 'DATASET_ID_NAME_PAIRS_SUCCESS';
export const DATASET_ID_NAME_PAIRS_FAILED = 'DATASET_ID_NAME_PAIRS_FAILED';

export const DATASET_VERSIONS_REQUEST = 'DATASET_VERSIONS_REQUEST';
export const DATASET_VERSIONS_SUCCESS = 'DATASET_VERSIONS_SUCCESS';
export const DATASET_VERSIONS_FAILED ='DATASET_VERSIONS_FAILED';

export const DATASET_DATA_UPLOAD_STARTED = 'DATASET_DATA_UPLOAD_STARTED';
export const DATASET_DATA_UPLOAD_COMPLETED = 'DATASET_DATA_UPLOAD_COMPLETED';

export const DATASET_DATA_UPLOAD_SUB_DIR_RESET = 'DATASET_DATA_UPLOAD_SUB_DIR_RESET';

export const DATASET_SHOW_DATASET_DETAILS_MODAL = 'DATASET_SHOW_DATASET_DETAILS_MODAL';
export const DATASET_HIDE_DATASET_DETAILS_MODAL = 'DATASET_HIDE_DATASET_DETAILS_MODAL';

export const DATASET_TITLE_EDIT_SUCCESS = 'DATASET_TITLE_EDIT_SUCCESS';
export const DATASET_TITLE_EDIT_FAILED = 'DATASET_TITLE_EDIT_FAILED';
export const SWITCH_DATASET_TO_SPECIFIC_GROUP = 'SWITCH_DATASET_TO_SPECIFIC_GROUP'
export const REMOVE_SWITCH_DATASET_TO_SPECIFIC_GROUP = 'REMOVE_SWITCH_DATASET_TO_SPECIFIC_GROUP';
export const DATASET_LOAD_SHAREABLE_USERS_GROUPS_REQUEST = 'DATASET_LOAD_SHAREABLE_USERS_GROUPS_REQUEST';
export const DATASET_LOAD_SHAREABLE_USERS_GROUPS_SUCCESS = 'DATASET_LOAD_SHAREABLE_USERS_GROUPS_SUCCESS';
export const DATASET_LOAD_SHAREABLE_USERS_GROUPS_FAILED = 'DATASET_LOAD_SHAREABLE_USERS_GROUPS_FAILED'
export const DATASET_SHARED_USERS_GROUPS_REQUEST = 'DATASET_SHARED_USERS_GROUPS_REQUEST';
export const DATASET_SHARED_USERS_GROUPS_SUCCESS = 'DATASET_SHARED_USERS_GROUPS_SUCCESS';
export const DATASET_SHARED_USERS_GROUPS_FAILED = 'DATASET_SHARED_USERS_GROUPS_FAILED'
export const REMOVE_SHARED_USERS_GROUPS = 'REMOVE_SHARED_USERS_GROUPS'
export const SHOW_MODAL_ADD_MEMBER = 'SHOW_MODAL_ADD_MEMBER';
export const HIDE_MODAL_ADD_MEMBER = 'HIDE_MODAL_ADD_MEMBER';

export const LOAD_FILTERED_USERS_SUCCESS = 'LOAD_FILTERED_USERS_SUCCESS';

export const LOAD_USERS_REQUEST = 'LOAD_USERS_REQUEST';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAILED = 'LOAD_USERS_FAILED';
export const UPDATE_SELECTED_GROUP = 'UPDATE_SELECTED_GROUP';

export const SEND_INVITATION_REQUEST = 'SEND_INVITATION_REQUEST';
export const SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS';
export const SEND_INVITATION_FAILED = 'SEND_INVITATION_FAILED';

export const USER_JOIN_REQUEST = 'USER_JOIN_REQUEST';
export const USER_JOIN_REQUEST_SUCCESS = 'USER_JOIN_REQUEST_SUCCESS';
export const USER_JOIN_REQUEST_FAILED = 'USER_JOIN_REQUEST_FAILED';

export const UPDATE_JOIN_GROUP_REQUEST = 'UPDATE_JOIN_GROUP_REQUEST';

export const LEAVE_GROUP_REQUEST = 'LEAVE_GROUP_REQUEST';
export const LEAVE_GROUP_SUCCESS = 'LEAVE_GROUP_SUCCESS';
export const LEAVE_GROUP_FAILED = 'LEAVE_GROUP_FAILED';

export const SHOW_LEAVE_ALERT = 'SHOW_LEAVE_ALERT';
export const HIDE_LEAVE_ALERT = 'HIDE_LEAVE_ALERT';

export const UPDATE_ACTIVE_ACCORDION = 'UPDATE_ACTIVE_ACCORDION';
import React, { useState } from "react";
import { Card } from "react-bootstrap";

const ChartCard = ({ setColor, imgUrl, identity, color }) => {
  const [hoverFilterView, setHoverFilterView] = useState(false);
  const [selectedFilterView, setSelecetdFilterView] = useState(false);
  const handleAddHoverCard = () => setHoverFilterView(true);

  const handleRemoveHoverCard = () => setHoverFilterView(false);
  const handleSelectedCard = (e) => {
    setColor(e.target.className.split(" ")[0]);
  };
  return (
    <Card
      onMouseEnter={handleAddHoverCard}
      onMouseLeave={handleRemoveHoverCard}
      onClick={handleSelectedCard}
      className={identity}
      style={{
        cursor: "pointer",
        marginBottom: "1em",
        marginTop: "1em",
        backgroundColor:
          ((hoverFilterView || selectedFilterView) && "#ccf5ff") || color,
        // backgroundColor: selectedFilterView && "#ccffcc"
      }}
    >
      
      <img
        className={identity}
        style={{
          height: "200px",
          width: "200px",
          display: "block",
          margin: "auto",
        }}
        src={imgUrl}
      />
    </Card>
  );
};

export default ChartCard;

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { Skeleton } from "@chakra-ui/core";
import {
  setChonkyDefaults,
  FileHelper,
  ChonkyActions,
  FullFileBrowser,
  ChonkyIconName,
  defineFileAction,
  FileContextMenu,
  FileList,
  FileNavbar,
  FileToolbar,
} from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  updateCurrentFolder,
  updateCurrentFolderFiles,
  openUploadDrawer,
  updateCurrentFileMap,
  copyFilesToClipboard,
} from "../../../store/datasetsStore/datasetActions";
import {
  faCalendarAlt,
  faFileAlt,
  faICursor,
} from "@fortawesome/free-solid-svg-icons";
import ImgsViewer from "react-images-viewer";
import PdfRendererModal from "./pdfRenderModal";
import XLSXRenderModal from "./xlsxRenderModal";
import { CHONKEY_OPEN_FOLDER } from "../../../constants/constants";

setChonkyDefaults({ iconComponent: ChonkyIconFA });

// const customChonkyActions = {
//   Rename: defineFileAction({
//     id: "rename",
//     requiresSelection: true,
//     button: {
//       name: "Rename",
//       toolbar: false,
//       contextMenu: true,
//       icon: faICursor,
//     },
//   }),
//   Copy: defineFileAction({
//     id: "copy",
//     requiresSelection: true,
//     button: {
//       name: "Copy",
//       toolbar: false,
//       contextMenu: true,
//       icon: ChonkyIconName.copy,
//     },
//   }),
//   Paste: defineFileAction({
//     id: "paste",
//     // requiresSelection: store.getState().datasets.filesAtClipboard === null,
//     button: {
//       name: "Paste",
//       toolbar: false,
//       contextMenu: true,
//       icon: ChonkyIconName.paste,
//     },
//   }),
// };

export const pasteFiles = async (files, oldFileMap, destination) => {
  // when files are moved from one folder to another using drag & drop.
  const newFileMap = { ...oldFileMap };

  // Add the files to their destination folder
  const newDestinationChildrenIds = [
    ...destination.childrenIds,
    ...files.map((f) => f.id),
  ];
  newFileMap[destination.id] = {
    ...destination,
    childrenIds: newDestinationChildrenIds,
    childrenCount: newDestinationChildrenIds.length,
  };

  // Finally, update the parent folder ID on the files from source folder
  // ID to the destination folder ID.
  files.forEach((file) => {
    newFileMap[file.id] = {
      ...file,
      parentId: destination.id,
    };
  });

  return newFileMap;
};

export const moveFiles = async (
  targetFiles,
  files,
  oldFileMap,
  source,
  destination
) => {
  // when files are moved from one folder to another using drag & drop.
  const newFileMap = { ...oldFileMap };
  const moveFileIds = new Set(
    files.map((f) => f.previous_id)
    // Object.keys(files).forEach((key) => files[key].id)
  );

  // Delete files from their source folder.
  const newSourceChildrenIds = source.childrenIds.filter(
    (id) => !moveFileIds.has(id)
  );
  newFileMap[source.id] = {
    ...source,
    childrenIds: newSourceChildrenIds,
    childrenCount: newSourceChildrenIds.length,
  };

  // Add the files to their destination folder
  const newDestinationChildrenIds = [
    ...destination.childrenIds,
    ...files.map((f) => f.id),
  ];
  newFileMap[destination.id] = {
    ...destination,
    childrenIds: newDestinationChildrenIds,
    childrenCount: newDestinationChildrenIds.length,
  };

  // Finally, update the parent folder ID on the files from source folder
  // ID to the destination folder ID.
  files.forEach((file) => {
    newFileMap[file.id] = {
      ...file,
      parentId: destination.id,
    };
  });

  return newFileMap;
};

export const renameItem = async (targetItem, oldFileMap, newData) => {
  const newFileMap = { ...oldFileMap, ...newData };

  delete newFileMap[targetItem.id];

  // Update parent folder to reference the new folder
  const parent = newFileMap[targetItem.parentId];
  newFileMap[targetItem.parentId] = {
    ...parent,
    childrenIds: parent.childrenIds.map((child) =>
      child === targetItem.id ? (child = Object.keys(newData)[0]) : child
    ),
  };

  return newFileMap;
};

export const createFolder = async (currentFolderId, oldFileMap, newData) => {
  const newFileMap = { ...oldFileMap, ...newData };

  // Update parent folder to reference the new folder
  const parent = newFileMap[currentFolderId];
  newFileMap[currentFolderId] = {
    ...parent,
    childrenCount: parent.childrenCount + 1,
    childrenIds: [...parent.childrenIds, Object.keys(newData)[0]],
  };

  return newFileMap;
};

const fileActions = [
  ChonkyActions.CreateFolder,
  ChonkyActions.DeleteFiles,
  ChonkyActions.UploadFiles,
  // customChonkyActions.Rename,
  // customChonkyActions.Copy,
  // customChonkyActions.Paste,

  ChonkyActions.MoveFilesTo,
  ChonkyActions.DuplicateFilesTo,

  ChonkyActions.ChangeSelection,

  ChonkyActions.OpenParentFolder,
  ChonkyActions.ToggleSearch,

  ChonkyActions.EnableListView,
  ChonkyActions.EnableGridView,

  ChonkyActions.OpenFiles,
  // ChonkyActions.SelectAllFiles,
  // ChonkyActions.ClearSelection,

  ChonkyActions.SortFilesByName,
  ChonkyActions.SortFilesBySize,
  ChonkyActions.SortFilesByDate,

  ChonkyActions.ToggleHiddenFiles,
  ChonkyActions.ToggleShowFoldersFirst,
];

const fileViewActions = [
  // customChonkyActions.Rename,
  // customChonkyActions.Copy,
  // customChonkyActions.Paste,

  ChonkyActions.MoveFilesTo,
  ChonkyActions.DuplicateFilesTo,

  ChonkyActions.ChangeSelection,

  ChonkyActions.OpenParentFolder,
  ChonkyActions.ToggleSearch,

  ChonkyActions.EnableListView,
  ChonkyActions.EnableGridView,

  ChonkyActions.OpenFiles,
  // ChonkyActions.SelectAllFiles,
  // ChonkyActions.ClearSelection,

  ChonkyActions.SortFilesByName,
  ChonkyActions.SortFilesBySize,
  ChonkyActions.SortFilesByDate,

  ChonkyActions.ToggleHiddenFiles,
  ChonkyActions.ToggleShowFoldersFirst,
];

class datasetData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      currImg: 0,
      pdfUrl: "",
      openPdf: false,
      openXLSX: false,
      xlsxUrl: "",
      fileName: "",
      spreadsheetFileTypes: [
        ".xla",
        ".xlc",
        ".xlm",
        ".xls",
        ".xlt",
        ".xlw",
        ".csv",
        ".xlsx",
      ],
    };
  }

  showMore = () => {
    return this.props.datasetStore.hasMoreFolderItem ? (
      <a href="/#" onClick={(e) => e.preventDefault()}>
        <span
          style={{ fontSize: "12px", position: "relative", float: "right" }}
          onClick={() =>
            this.loadCurrentFolderFiles(
              this.props.datasetStore.targetFile,
              false,
              true
            )
          }
        >
          Show More
        </span>
      </a>
    ) : null;
  };

  loadCurrentFolderFiles = (targetFile, isInitialLoading, loadMore = false) => {
    let pageNum = isInitialLoading
      ? 1
      : this.props.datasetStore.curentFolderPageNum + 1;
    this.props.updateCurrentFolderFiles(
      pageNum,
      this.props.datasetStore.numOfFolderItemsPerPage,
      isInitialLoading,
      targetFile,
      this.props.datasetStore.expandedDatasetData,
      this.props.datasetStore.selectedDatasetId,
      loadMore
    );
  };

  // loadCurrentFolderFilesAfterChanged = () => {
  //   setTimeout(() => {
  //     const fileToOpen = JSON.parse(localStorage.getItem(CHONKEY_OPEN_FOLDER));

  //     this.props.updateCurrentFolderFiles(
  //       1,
  //       this.props.datasetStore.curentFolderPageNum *
  //         this.props.datasetStore.numOfFolderItemsPerPage,
  //       true,
  //       fileToOpen,
  //       this.props.datasetStore.expandedDatasetData,
  //       this.props.datasetStore.selectedDatasetId,
  //       false
  //     );
  //   }, 2000);
  // };

  getFolderReloadInfo = () => {
    const fileToOpen = JSON.parse(localStorage.getItem(CHONKEY_OPEN_FOLDER));
    return {
      currentPage: 1,
      pagePerItems:
        this.props.datasetStore.curentFolderPageNum *
        this.props.datasetStore.numOfFolderItemsPerPage,
      initialLoading: true,
      _targetFile: fileToOpen,
      _datasetData: this.props.datasetStore.expandedDatasetData,
      selectedDsId: this.props.datasetStore.selectedDatasetId,
      hasMore: false,
    };
  };

  fileActionHandler = (e) => {
    if (e.id === ChonkyActions.OpenFiles.id) {
      console.log(e.payload)
      const { targetFile, files } = e.payload;
      const fileToOpen = targetFile ?? files[0];
      if (targetFile && targetFile.isDir) {
        localStorage.setItem(CHONKEY_OPEN_FOLDER, JSON.stringify(fileToOpen));
      }
      if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
        // update current folder in folder chain
        this.props.updateCurrentFolder(fileToOpen);

        //check if folder data need to update or not
        let currentFolder =
          this.props.datasetStore.expandedDatasetData.fileMap[fileToOpen.id];
        let shouldFileMapUpdate =
          currentFolder.childrenCount > currentFolder.childrenIds.length
            ? true
            : false;

        currentFolder.childrenCount > 0 &&
          currentFolder.childrenIds.map((children) => {
            if (
              !this.props.datasetStore.expandedDatasetData.fileMap.hasOwnProperty(
                [children]
              )
            ) {
              shouldFileMapUpdate = true;
            }
          });

        // if(shouldFileMapUpdate){
        this.loadCurrentFolderFiles(fileToOpen, true);
        // }
      } else if (fileToOpen.mimetype.includes("image")) {
        const currentImgIndex =
          this.props.datasetStore.datasetImageFiles.findIndex(
            (img) => img.id === fileToOpen.id
          );
        this.setState({
          isOpen: true,
          currImg: currentImgIndex,
        });
      } else if (fileToOpen.mimetype.includes("pdf")) {
        this.setState({
          pdfUrl: fileToOpen.url,
          openPdf: true,
        });
      } else if (this.state.spreadsheetFileTypes.includes(fileToOpen.ext)) {
        this.setState({
          openXLSX: true,
          xlsxUrl: fileToOpen.url,
          fileName: fileToOpen.name,
        });
      }
    } 
    // else if (e.id === ChonkyActions.UploadFiles.id) {
    //   this.props.openUploadDrawer(
    //     this.props.datasetStore.targetFile.url !== "/"
    //       ? this.props.datasetStore.targetFile.url
    //       : null
    //   );
    // } 
    // else if (e.id === ChonkyActions.DeleteFiles.id) {
    //   let formdata = new FormData();
    //   let targetFiles = [];
    //   let updatedFileMap = this.deleteFiles(e.state.selectedFilesForAction);
    //   e.state.selectedFilesForAction.map((file) => {
    //     targetFiles.push(file.id);
    //   });
    //   formdata.append("selected_item", JSON.stringify(targetFiles));
    //   const reloadInfo = this.getFolderReloadInfo();

    //   this.props.updateCurrentFileMap(
    //     this.props.datasetStore.selectedDatasetId,
    //     updatedFileMap,
    //     formdata,
    //     "DELETE",
    //     reloadInfo
    //   );
     
    // } 
    // else if (e.id === ChonkyActions.MoveFiles.id) {
    //   let formdata = new FormData();
    //   let targetFiles = [];
    //   let destinationId =
    //     e.payload.destination.id === -1 ? "/" : e.payload.destination.id;
    //   let updatedFileMap = {
    //     files: e.payload.files,
    //     source: e.payload.source,
    //     destination: e.payload.destination,
    //   };

    //   e.payload.files.map((file) => targetFiles.push(file.id));

    //   formdata.append("source", JSON.stringify(targetFiles));
    //   formdata.append("destination", destinationId);
    //   const reloadInfo = this.getFolderReloadInfo();
    //   this.props.updateCurrentFileMap(
    //     this.props.datasetStore.selectedDatasetId,
    //     updatedFileMap,
    //     formdata,
    //     "MOVE",
    //     reloadInfo
    //   );
      
    // } 
    // else if (e.id === ChonkyActions.CreateFolder.id) {
    //   let formdata = new FormData();
    //   const folderName = prompt("Provide the name for your new folder:");
    //   if (folderName) {
    //     formdata.append(
    //       "parent_folder",
    //       this.props.datasetStore.targetFile.url
    //     );
    //     formdata.append("new_folder", folderName);
    //     const reloadInfo = this.getFolderReloadInfo();
    //     this.props.updateCurrentFileMap(
    //       this.props.datasetStore.selectedDatasetId,
    //       this.props.datasetStore.targetFile.id,
    //       formdata,
    //       "CREATE",
    //       reloadInfo
    //     );
        
    //   }
    // } 
    // else if (e.id === customChonkyActions.Rename.id) {
    //   let formdata = new FormData();

    //   const newName = prompt(
    //     "Provide new name:",
    //     e.state.contextMenuTriggerFile.name
    //   );
    //   if (newName) {
    //     formdata.append("target_item", e.state.contextMenuTriggerFile.id);
    //     formdata.append("new_name", newName);
    //     const reloadInfo = this.getFolderReloadInfo();
    //     this.props.updateCurrentFileMap(
    //       this.props.datasetStore.selectedDatasetId,
    //       e.state.contextMenuTriggerFile,
    //       formdata,
    //       "RENAME",
    //       reloadInfo
    //     );
    //     // this.loadCurrentFolderFilesAfterChanged();
    //   }
    // }
    
    // else if (e.id === customChonkyActions.Copy.id) {
    //   this.props.copyFilesToClipboard(e.state.selectedFiles);
    // } else if (e.id === customChonkyActions.Paste.id) {
    //   if (this.props.datasetStore.filesAtClipboard) {
    //     let formdata = new FormData();
    //     let srcFiles = [];
    //     let destinationId =
    //       this.props.datasetStore.currentFolderId === -1
    //         ? "/"
    //         : this.props.datasetStore.currentFolderId;
    //     let updatedFileMap = {
    //       files: this.props.datasetStore.filesAtClipboard,
    //       destination: this.props.datasetStore.targetFile,
    //     };

    //     this.props.datasetStore.filesAtClipboard.map((file) =>
    //       srcFiles.push(file.id)
    //     );

    //     formdata.append("source", JSON.stringify(srcFiles));
    //     formdata.append("destination", destinationId);
    //     const reloadInfo = this.getFolderReloadInfo();
    //     this.props.updateCurrentFileMap(
    //       this.props.datasetStore.selectedDatasetId,
    //       updatedFileMap,
    //       formdata,
    //       "PASTE",
    //       reloadInfo
    //     );
    //     // this.loadCurrentFolderFilesAfterChanged();
    //   }
    // }
  };

  useFiles = (fileMap, currentFolderId) => {
    const currentFolder = fileMap[currentFolderId];
    const files = !currentFolder.childrenIds
      ? []
      : currentFolder.childrenIds.map((fileId) => {
          const file = fileMap[fileId];
          return file ? file : null;
        });
    return files;
  };

  useFolderChain = (fileMap, currentFolderId) => {
    const currentFolder = fileMap[currentFolderId];
    const folderChain = [currentFolder];
    let parentId = currentFolder.parentId;

    while (parentId) {
      const parentFile = fileMap[parentId];
      if (parentFile) {
        folderChain.unshift(parentFile);
        parentId = parentFile.parentId;
      } else {
        parentId = null;
      }
    }
    return folderChain;
  };

  deleteFiles = (files) => {
    // worked for deletes files using context menu or `Delete` key.
    const newFileMap = {
      ...this.props.datasetStore.expandedDatasetData.fileMap,
    };

    files.forEach((file) => {
      delete newFileMap[file.id];

      // Update the parent folder to eleminate deleted item immediately
      if (file.parentId) {
        const parent = newFileMap[file.parentId];
        const newChildrenIds = parent.childrenIds.filter(
          (id) => id !== file.id
        );
        newFileMap[file.parentId] = {
          ...parent,
          childrenIds: newChildrenIds,
          childrenCount: newChildrenIds.length,
        };
      }
    });

    return newFileMap;
  };

  gotoPrevious = (e, a) => {
    this.setState({
      currImg: this.state.currImg - 1,
    });
  };

  gotoNext = (e, a) => {
    this.setState({
      currImg: this.state.currImg + 1,
    });
  };

  closeViewer = (e, a) => {
    this.setState({
      isOpen: false,
    });
  };

  onPDFModalClosed = (isClosed) => {
    this.setState({
      openPdf: false,
    });
  };

  onXLSXModalClosed = (isClosed) => {
    this.setState({
      openXLSX: false,
    });
  };
  componentDidMount() {
    localStorage.setItem(
      CHONKEY_OPEN_FOLDER,
      JSON.stringify(this.props.datasetStore.expandedDatasetData.fileMap["-1"])
    );
  }

  renderDatasetLink = (dataLink) => {
    let creationDate = new Date(dataLink.modDate).toISOString().split("T")[0];
    let creationTime = new Date(dataLink.modDate).toLocaleTimeString();
    return (
      <Fragment>
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon
            icon={faFileAlt}
            style={{ fontSize: "5em", marginRight: "10px", color: "#337ab7" }}
          />
          <span style={{ display: "flex", flexDirection: "column" }}>
            <a target="_blank" rel="noopener noreferrer" href={dataLink.url}>
              <strong style={{ fontSize: "large" }}>{dataLink.name}</strong>
            </a>
            <span style={{ color: "slategray" }}>
              <span>
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  style={{ marginRight: "5px" }}
                />
                <strong>Added On : </strong>
                {creationDate + " " + creationTime}
              </span>
            </span>
          </span>
        </div>
        <hr />
      </Fragment>
    );
  };

  renderDatasetData = () => {
    let { currentFolderId, expandedDatasetData } = this.props.datasetStore;
    const { isShowEdit } = this.props.visualization;
    return (
      <Row style={{ height: "50vh" }}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <FullFileBrowser
            files={this.useFiles(expandedDatasetData.fileMap, currentFolderId)}
            folderChain={this.useFolderChain(
              expandedDatasetData.fileMap,
              currentFolderId
            )}
            fileActions={isShowEdit ? fileActions : fileViewActions}
            onFileAction={this.fileActionHandler}
            clearSelectionOnOutsideClick={true}
            // disableSelection={true}
            disableDefaultFileActions={[
                ChonkyActions.SelectAllFiles.id,
                ChonkyActions.ClearSelection.id,
            ]}
          >
            <FileNavbar />
            <FileToolbar />
            <FileContextMenu />
            <FileList />
          </FullFileBrowser>

          {this.showMore()}

          <ImgsViewer
            imgs={this.props.datasetStore.datasetImageFiles}
            currImg={this.state.currImg}
            isOpen={this.state.isOpen}
            onClickPrev={(e) => this.gotoPrevious(e)}
            onClickNext={(e) => this.gotoNext(e)}
            onClose={this.closeViewer}
            closeBtnTitle={"Close"}
            leftArrowTitle={"previous"}
            rightArrowTitle={"next"}
          />
          <PdfRendererModal
            show={this.state.openPdf}
            onModalClosed={this.onPDFModalClosed}
            pdfUrl={this.state.pdfUrl}
          />
          <XLSXRenderModal
            show={this.state.openXLSX}
            onModalClosed={this.onXLSXModalClosed}
            fileUrl={this.state.xlsxUrl}
            fileName={this.state.fileName}
          />
        </Col>
      </Row>
    );
  };

  render() {
    let { isDetailsViewDataLoading } = this.props.datasetStore;
    return (
      <Fragment>
        {this.props.datasetStore.expandedDatasetLink.map((aDataLink) => {
          return this.renderDatasetLink(aDataLink);
        })}
        {this.renderDatasetData()}
        {isDetailsViewDataLoading && (
          <Fragment>
            <Skeleton height="3vh" my="1vh" />
            <Skeleton height="3vh" my="1vh" />
            <Skeleton height="3vh" my="1vh" />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    datasetStore: state.datasets,
    visualization: state.visualization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openUploadDrawer: (subDir) => dispatch(openUploadDrawer(subDir)),
    updateCurrentFileMap: (
      selectedDatasetId,
      fileMap,
      targetItem,
      opType,
      reloadInfo
    ) =>
      dispatch(
        updateCurrentFileMap(
          selectedDatasetId,
          fileMap,
          targetItem,
          opType,
          reloadInfo
        )
      ),
    updateCurrentFolder: (currentFolderId) =>
      dispatch(updateCurrentFolder(currentFolderId)),
    updateCurrentFolderFiles: (
      pageNum,
      numOfFolderItems,
      isInitialLoading,
      currentFolder,
      expandedDatasetData,
      selectedDatasetId,
      loadMore
    ) =>
      dispatch(
        updateCurrentFolderFiles(
          pageNum,
          numOfFolderItems,
          isInitialLoading,
          currentFolder,
          expandedDatasetData,
          selectedDatasetId,
          loadMore
        )
      ),
    copyFilesToClipboard: (files) => dispatch(copyFilesToClipboard(files)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(datasetData);

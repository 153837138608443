import React from "react";
import { Row, Col, Card } from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import PieChartComp from "./configFilter/pieChart";
import BarChartComp from "./configFilter/barChart";
import ConfigSearch from "./configSearch";
import { filterConfig } from "../../store/searchStore/searchActions";
import { connect } from "react-redux";

class NewFilter extends React.Component {
  render() {
    return (
      <div className="add-filter-icon">
        {this.props.searchStore.filterConfig === true && <ConfigSearch appendFilter={this.props.appendFilter} />}
        <PlusSquareOutlined
          onClick={(e) => this.props.filterConfig()}
          style={{ fontSize: "10vw", color: "#08c", cursor: "pointer" }}
        />
        <span
          style={{
            fontWeight: "bold",
            fontSize: "large",
            color: "slategray",
          }}
        >
          Add New Filter
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchStore: state.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    filterConfig: () => dispatch(filterConfig()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewFilter);

import * as expandedResourceActionTypes from "./expandedResourcesActionType";

const initialState = {
  expandedResourcesName: null,
  expandedResourcesId: null,
  expandedResources: null,
  expandedResourcesDetails: null,
  expandedResourcesLoading: true,
  expandedResourcesDetailsLoading: true,
  resourcesType: null,
  showExpandedDataTemplateModal: false,
  expandedResourcesGroupId: null,

  // for dataset oprration
  expandedGroupName: null,
  liked: false,
  like: null,
  pid: null,
  url: null,
  view: null,
  access: null,
  groupType: null,
  description: null,
  expandedResourcesType: null,
  expandedResourcesStatus: null,
  expandedDatsetDetails: null,
  expandedDatasetData: null,
  expandedDatasetLink: [],
  expandedDatasetMetadata: null,
  totalNoOfData: 0,
  totalNoOfMetadata: 0,
  datasetImageFiles: [],
  currentFolderId: null,
  targetFile: null,
  totalNoOfCurrentFolderItem: 0,
  hasMoreFolderItem: false,
  curentFolderPageNum: 0,
  isPropertiesDetailsLoading: false,
  numOfFolderItemsPerPage: 20,
  expandedMetadataTabChanged: false,
  showExpandedSubmitMetadataAlert: false,
  filesAtClipboard: null,

  // Data upload operation
  openUploadDrawer: false,
  datasetIdNamePairs: [],
  datasetVersions: [],
  isDatasetIdNamePairLoading: false,
  isDatasetVersionsLoading: false,
  subDirUrl: null,
  isDataUploading: false,
};

const expandedResourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case expandedResourceActionTypes.GET_EXPANDED_RESOURCES_REQUEST:
      return {
        ...initialState,
        expandedResourcesLoading: true,
      };

    case expandedResourceActionTypes.GET_EXPANDED_RESOURCES:
      return {
        ...state,
        expandedResourcesName: action.payload.name,
        expandedResourcesId: action.payload.Id,
        resourcesType: action.payload.resourcesType,
        expandedResourcesStatus: action.payload.status,
        expandedResourcesType: action.payload.type,
        expandedGroupName: action.payload.group,
        liked: action.payload.liked,
        like: action.payload.like,
        pid: action.payload.pid,
        url: action.payload.url,
        view: action.payload.view,
        access: action.payload.access,
        groupType: action.payload.groupType,
        description: action.payload.description,
        expandedResources: null,
        expandedResourcesDetails: null,
        expandedResourcesGroupId: action.payload.groupId,
      };

    case expandedResourceActionTypes.GET_EXPANDED_RESOURCES_SUCCESS:
      return {
        ...state,
        expandedResourcesName: action.payload.name,
        expandedResourcesId: action.payload.Id,
        resourcesType: action.payload.resourcesType,
        expandedResources: action.payload.data,
        expandedResourcesStatus: null,
        expandedResourcesLoading: false,
        expandedResourcesDetails: null,
      };
    case expandedResourceActionTypes.GET_EXPANDED_RESOURCES_FAIL:
      return {
        ...state,
        expandedResourcesLoading: false,
        expandedResourcesDetailsLoading: false,
      };
    case expandedResourceActionTypes.GET_EXPANDED_RESOURCES_DETAILS_REQUEST:
      return {
        ...state,
        expandedResourcesDetailsLoading: true,
      };
    case expandedResourceActionTypes.GET_EXPANDED_RESOURCES_DETAILS_SUCCESS:
      return {
        ...state,
        expandedResourcesDetails: { ...action.payload },
        expandedResourcesDetailsLoading: false,
      };
    case expandedResourceActionTypes.GET_EXPANDED_RESOURCES_DETAILS_FAIL:
      return {
        ...state,
        expandedResourcesDetails: { ...action.payload },
        expandedResourcesDetailsLoading: false,
      };
    case expandedResourceActionTypes.APPEND_EXPANDED_RESOURCES_META_DATA:
      return {
        ...state,
        expandedResourcesDetails: {
          ...state.expandedResourcesDetails,
          dataTemplates: [
            ...state.expandedResourcesDetails.dataTemplates,
            action.payload,
          ],
        },
      };

    case expandedResourceActionTypes.EDIT_EXPANDED_RESOURCES_DESCRIPTION:
      return {
        ...state,
        expandedResourcesDetails: {
          ...state.expandedResourcesDetails,
          properties: {
            ...state.expandedResourcesDetails.properties,
            description: action.payload,
          },
        },
      };

    case expandedResourceActionTypes.EDIT_EXPANDED_RESOURCES_PARENT_GROUP:
      return {
        ...state,
        expandedResources: {
          ...state.expandedResources,
          parent_group: action.payload.label,
        },
        expandedResourcesDetails: {
          ...state.expandedResourcesDetails,
          properties: {
            ...state.expandedResourcesDetails.properties,
            id: action.value,
            parentGroup: action.payload.label,
          },
        },
      };

    case expandedResourceActionTypes.EDIT_EXPANDED_RESOURCES_ADMINISTRATIVE_GROUP:
      return {
        ...state,
        expandedResources: {
          ...state.expandedResources,
          type: "my_group",
        },
      };

    case expandedResourceActionTypes.EDIT_EXPANDED_RESOURCES_ADMIN:
      return {
        ...state,
        expandedResources: { ...state.expandedResources, isAdmin: false },
        expandedResourcesDetails: {
          ...state.expandedResourcesDetails,
          properties: {
            ...state.expandedResourcesDetails.properties,
            adminId: action.payload.adminId,
            admin: action.payload.admin,
          },
        },
      };

    case expandedResourceActionTypes.EDIT_EXPANDED_RESOURCES_PERMISSION:
      return {
        ...state,
        expandedResourcesDetails: {
          ...state.expandedResourcesDetails,
          generalPermission: {
            ...action.payload,
          },
        },
      };

    case expandedResourceActionTypes.REMOVE_GET_EXPANDED_RESOURCES:
      return {
        ...initialState,
      };
    case expandedResourceActionTypes.GET_EXPANDED_DATSET_DETAILS_REQUEST:
      return {
        ...state,
        expandedResources: null,
        expandedResourcesDetails: null,
      };

    case expandedResourceActionTypes.GET_EXPANDED_DATSET_DETAILS_SUCCESS:
      return {
        ...state,
        expandedResourcesDetailsLoading: false,
        expandedDatsetDetails: action.payload.properties,
        expandedDatasetData: action.payload.data,
        expandedDatasetLink: action.payload.link,
        expandedDatasetMetadata: action.payload.metadatas,
        totalNoOfData: action.payload.totalData,
        totalNoOfMetadata: action.payload.totalMetadata,
        datasetImageFiles: action.payload.datasetImgFiles,

        currentFolderId: action.payload.data.rootFolderId,
        targetFile: action.payload.data.fileMap[-1],
        totalNoOfCurrentFolderItem: action.payload.data.itemCount
          ? action.payload.data.itemCount
          : null,
        hasMoreFolderItem: action.payload.data.hasMore
          ? action.payload.data.hasMore
          : false,
        curentFolderPageNum: action.payload.data.pageNum
          ? action.payload.data.pageNum
          : 0,
      };
    case expandedResourceActionTypes.GET_EXPANDED_DATSET_DETAILS_FAIL:
      return {
        ...state,
        expandedResourcesDetailsLoading: false,
      };

    case expandedResourceActionTypes.GET_EXPANDED_DATASET_PROPERTIES_REQUEST:
      return {
        ...state,
        isPropertiesDetailsLoading: true,
      };

    case expandedResourceActionTypes.GET_EXPANDED_DATASET_PROPERTIES_SUCCESS:
      let newDatsetDetails = state.expandedDatsetDetails.map((metadata) => {
        return metadata.id === action.payload.metadataId
          ? action.payload.res.data
          : metadata;
      });
      return {
        ...state,
        expandedDatsetDetails: newDatsetDetails,
        isPropertiesDetailsLoading: false,
      };

    case expandedResourceActionTypes.GET_EXPANDED_DATASET_PROPERTIES_FAILED:
      return {
        ...state,
        isPropertiesDetailsLoading: false,
      };

    case expandedResourceActionTypes.EXAPANDED_DATASET_SET_METADATA_TAB_CHANGE_FLAG:
      return {
        ...state,
        expandedMetadataTabChanged: false,
      };
    case expandedResourceActionTypes.EXPANDED_DATASET_SHOW_SUBMIT_METADATA_ALERT_MODAL:
      return {
        ...state,
        showExpandedSubmitMetadataAlert: true,
      };
    case expandedResourceActionTypes.EXPANDED_DATASET_HIDE_SUBMIT_METADATA_ALERT_MODAL:
      return {
        ...state,
        showExpandedSubmitMetadataAlert: false,
      };
    case expandedResourceActionTypes.EXPANDED_DATASET_RESET_METADATA_TAB_CHANGE_FLAG:
      return {
        ...state,
        expandedMetadataTabChanged: true,
      };

    //pagination in folder
    case expandedResourceActionTypes.EXPANDED_DATASET_UPDATE_CURRENT_FOLDER:
      return {
        ...state,
        currentFolderId: action.payload.folder.id,
        targetFile: action.payload.folder,
      };
    case expandedResourceActionTypes.EXPANDED_DATASET_UPDATE_CURRENT_FOLDER_FILES_REQUEST:
      return {
        ...state,
        totalNoOfCurrentFolderItem: null,
        hasMoreFolderItem: false,
        curentFolderPageNum: 0,
        expandedDatasetData: action.payload
          ? action.payload.expandedDatasetData
          : state.expandedDatasetData,
      };

    case expandedResourceActionTypes.EXPANDED_DATASET_UPDATE_CURRENT_FOLDER_FILES_SUCCESS:
      return {
        ...state,
        expandedDatasetData: action.payload.expandedDatasetData,
        datasetImageFiles: action.payload.datasetImgFiles,
        totalNoOfCurrentFolderItem: action.payload.response.itemCount,
        hasMoreFolderItem: action.payload.response.hasMore,
        curentFolderPageNum: action.payload.response.pageNum,
      };

    case expandedResourceActionTypes.EXAPNDED_DATASET_UPDATE_CURRENT_FOLDER_FILES_FAILED:
      return {
        ...state,
        expandedDatasetData: action.payload.expandedDatasetData,
        totalNoOfCurrentFolderItem: null,
        hasMoreFolderItem: false,
        curentFolderPageNum: 0,
        error: action.payload.error,
      };

    case expandedResourceActionTypes.EXPANDED_DATASET_UPDATE_CURRENT_FILEMAP:
      return {
        ...state,
        expandedDatasetData: {
          ...state.expandedDatasetData,
          fileMap: action.payload.fileMap,
        },
      };
    case expandedResourceActionTypes.EXPANDED_DATASET_DATA_COPY_FILES_TO_CLIPBOARD:
      return {
        ...state,
        filesAtClipboard: action.payload.files,
      };

    case expandedResourceActionTypes.EXPANDED_DATASET_CLOSE_UPLOAD_DRAWER:
      return {
        ...state,
        openUploadDrawer: false,
      };

    case expandedResourceActionTypes.EXPANDED_DATASET_OPEN_UPLOAD_DRAWER:
      return {
        ...state,
        openUploadDrawer: true,
        subDirUrl: action.payload ?? null,
      };

    case expandedResourceActionTypes.EXPANDED_DATASET_ID_NAME_PAIRS_REQUEST:
      return {
        ...state,
        isDatasetIdNamePairLoading: true,
      };
    case expandedResourceActionTypes.EXPANDED_DATASET_ID_NAME_PAIRS_SUCCESS:
      return {
        ...state,
        isDatasetIdNamePairLoading: false,
        datasetIdNamePairs: action.payload,
      };
    case expandedResourceActionTypes.EXPANDED_DATASET_ID_NAME_PAIRS_FAILED:
      return {
        ...state,
        isDatasetIdNamePairLoading: false,
      };

    case expandedResourceActionTypes.EXPANDED_DATASET_VERSIONS_REQUEST:
      return {
        ...state,
        isDatasetVersionsLoading: true,
      };
    case expandedResourceActionTypes.EXPANDED_DATASET_VERSIONS_SUCCESS:
      return {
        ...state,
        isDatasetVersionsLoading: false,
        datasetVersions: action.payload,
      };
    case expandedResourceActionTypes.EXPANDED_DATASET_VERSIONS_FAILED:
      return {
        ...state,
        isDatasetVersionsLoading: false,
      };
    case expandedResourceActionTypes.EXPANDED_DATASET_DATA_UPLOAD_STARTED:
      return {
        ...state,
        isDataUploading: true,
      };
    case expandedResourceActionTypes.EXPANDED_DATASET_DATA_UPLOAD_COMPLETED:
      return {
        ...state,
        isDataUploading: false,
      };
    case expandedResourceActionTypes.EXPANDED_DATASET_APPEND_NEW_LINK_TO_DATASET:
      if (!state.expandedDatasetLink) {
        state.expandedDatasetLink = [];
      }
      return {
        ...state,
        expandedDatasetLink: [...state.expandedDatasetLink, action.payload],
      };
    case expandedResourceActionTypes.EXPANDED_DATASET_DATA_UPLOAD_SUB_DIR_RESET:
      return {
        ...state,
        subDirUrl: null,
      };

    case expandedResourceActionTypes.EXPANDED_DATASET_APPEND_NEW_METADATA_TO_DATASET:
      let newExpandedDatsetDetails = [];
      state.expandedDatsetDetails === undefined
        ? newExpandedDatsetDetails.push(action.payload)
        : (newExpandedDatsetDetails = [
            ...state.expandedDatsetDetails,
            action.payload,
          ]);

      return {
        ...state,
        expandedDatsetDetails: newExpandedDatsetDetails,
      };

    case expandedResourceActionTypes.UPDATE_EXPANDED_DATASET_STATUS:
      return {
        ...state,
        expandedResourcesStatus: action.payload.status,
      };
    case expandedResourceActionTypes.REMOVE_EXPANDED_RESOURCES:
      return { ...initialState };

    case expandedResourceActionTypes.EDIT_EXPANDED_GROUP_TITLE_SUCCESS:
      return { ...state, expandedResourcesName: action.payload.groupTitle };
    case expandedResourceActionTypes.EXPANDED_DATASET_TITLE_EDIT_SUCCESS:
      return { ...state, expandedResourcesName: action.payload.datasetTitle };
    case expandedResourceActionTypes.EXPANDED_DATASET_HANDLE_LIKE_SUCCESS:
      return {
        ...state,
        like: action.payload.numOfLikes,
        liked: action.payload.liked,
      };

    case expandedResourceActionTypes.REMOVE_EXPANDED_RESOURCES_GROUP_MEMEBER:
      const filteredGroupMembers =
        state.expandedResourcesDetails.members.filter(
          (member, index) => member.userId !== action.payload.memberId
        );

      return {
        ...state,
        expandedResources: {
          ...state.expandedResources,
          members: filteredGroupMembers.length,
        },
        expandedResourcesDetails: {
          ...state.expandedResourcesDetails,
          members: filteredGroupMembers,
        },
        // groups: updatedGroup,
      };
    default:
      return state;
  }
};

export default expandedResourcesReducer;

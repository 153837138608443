import React from "react";
import RandomColor from "randomcolor";
import { Row, Col, Card, Button, Alert, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp as faThumbsUpFill,
  faShareAlt,
  faEye,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import { connect } from "react-redux";
import {
  updateSelectedFilterValueSelections,
  deleteFilter,
  updateFilterTitle,
} from "../../../store/searchStore/searchActions";
import Editable from "react-bootstrap-editable";

const data = [
  { name: "json", value: 2 },
  { name: "png", value: 3 },
  { name: "jpg", value: 2 },
  { name: "json", value: 5 },
  { name: "sql", value: 8 },
  { name: "octet", value: 2 },
  { name: "zip", value: 3 },
  { name: "pdf", value: 25 },
  { name: "js", value: 5 },
  { name: "docs", value: 20 },
  { name: "text", value: 3 },
];

class BarChartComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      barChartData: this.props.chartData.map((data) => {
        return { name: data.label, value: data.value };
      }),
      colors: RandomColor({
        hue: this.props.color,
        count: this.props.chartData.length,
      }),
    };
  }

  onBarClick = (e, index) => {
    const selectedMymeTypesIndex =
      this.props.searchStore.mimeTypesSelectedIndex;
    const currentActivatedCropsIndex = selectedMymeTypesIndex.includes(index)
      ? selectedMymeTypesIndex.filter((activeIndex) => activeIndex !== index)
      : [...selectedMymeTypesIndex, index];
    const selectedMymeTypes = this.props.searchStore.searchFilters.mimeTypes;
    const currentActivatedMymeTypes = selectedMymeTypes.includes(e.name)
      ? selectedMymeTypes.filter((mymeType) => mymeType !== e.name)
      : [...selectedMymeTypes, e.name];
    this.props.updateSelectedFilterValueSelections(
      {
        mimeTypes: currentActivatedMymeTypes,
        selectedMymeTypesIndex: currentActivatedCropsIndex,
      },
      "mimeTypes"
    );
  };

  deleteFilterHandler = () => {
    console.log(this.props.filterId);
    this.props.deleteFilter(this.props.filterId);
  };

  editFilterTitle = (value) => {
    this.props.updateFilterTitle(value, this.props.filterId);
  };

  render() {
    return (
      <Card
        size="small"
        bordered={false}
        title={
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "5fr 1fr",
              alignItems: "center",
            }}
          >
            <Editable
              editText={<FontAwesomeIcon icon={faPen} />}
              initialValue={this.props.name}
              mode="inline"
              renderCancelElement={null}
              renderConfirmElement={null}
              onSubmit={(value) => this.editFilterTitle(value)}
              showText
              type="textfield"
            />

            <Row>
              <Col></Col>
              <Col style={{ marginLeft: "30px" }}>
                <Button danger onClick={this.deleteFilterHandler}>
                  <img
                    style={{ height: "20px", cursor: "pointer" }}
                    src="https://img.icons8.com/color/48/000000/delete-forever.png"
                  />
                </Button>
              </Col>
            </Row>
          </div>
        }
        style={{ width: "38em" }}
      >
        <ResponsiveContainer
          width="100%"
          height={this.props.height || 250}
          className="barChart"
        >
          <BarChart
            data={
              this.state.barChartData.length > 0
                ? this.state.barChartData
                : data
            }
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Bar
              // activeIndex={this.state.activeIndex}
              dataKey="value"
              // fill="#8884d8"
              stroke="none"
              label={{ position: "top" }}
              onClick={this.onBarClick}
            >
              {this.state.barChartData.map((entry, index) => {
                //  this.state.colors[this.state.colors.length-1] = index
                let color = this.state.colors[index];
                console.log(color);
                // color[color.length -1] = `${index}`
                return (
                  <Cell
                    key={`cell-${index}`}
                    className={
                      this.props.searchStore.mimeTypesSelectedIndex.includes(
                        index
                      )
                        ? "barchart-active"
                        : ""
                    }
                    fill={color}
                  />
                );
              })}
              {this.state.barChartData.length === 0 &&
                data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    className={
                      this.props.searchStore.mimeTypesSelectedIndex.includes(
                        index
                      )
                        ? "barchart-active"
                        : ""
                    }
                    fill={this.state.colors[index % 20]}
                  />
                ))}
            </Bar>
            <Legend
            // formatter={this.renderColorfulLegendText}
            // content={this.renderColorfulLegendText}
            />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchStore: state.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedFilterValueSelections: (selectedOptions, filterType) =>
      dispatch(
        updateSelectedFilterValueSelections(selectedOptions, filterType)
      ),
    deleteFilter: (filterId) => dispatch(deleteFilter(filterId)),
    updateFilterTitle: (name, filterId) =>
      dispatch(updateFilterTitle(name, filterId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarChartComp);

export const setMidPanelHeight = (topNavHeight, windowHeight) => (dispatch) => {
  dispatch({
    type: "SET_MID_PANEL_HEIGHT",
    payload: windowHeight - (topNavHeight + 150),
  });
};

export const setFilterPanelHeight = (filterHeight) => (dispatch) => {
  dispatch({
    type: "SET_FILTER_PANEL_HEIGHT",
    payload: filterHeight,
  });
};

export const showHideSearchBar = (showHide) => (dispatch) =>
  dispatch({ type: "SHOW_HIDE_SEARCH_BAR", payload: showHide });

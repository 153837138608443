import React from "react";
import { Link } from "react-router-dom";
import MarkerCluster from "./MarkerCluster";
import { Map, TileLayer } from "react-leaflet";
import "leaflet-area-select";
import AreaSelect from "./AreaSelect";
import {
  datasetLocationInMap,
  updateSelectedFilterValueSelections,
  removeMapArea,
} from "../../../store/searchStore/searchActions";
import { connect } from "react-redux";
import { Button } from "antd";

export const locationDDL = [
  { value: "All", label: "All" },
  { value: "Alberta", label: "Alberta" },
  { value: "British Columbia", label: "British Columbia" },
  { value: "Manitoba", label: "Manitoba" },
  { value: "Ontario", label: "Ontario" },
  { value: "Quebec", label: "Quebec" },
  { value: "Saskatchewan", label: "Saskatchewan" },
];

export const fakeMarkers = [
  {
    coordinate: [55.0, -115.0],
    address: "Usborne No. 370, Alberta, Canada",
    state: "Alberta",
    name: "Fake datatset 1",
    group: "Fake group 1",
  },
  {
    coordinate: [53.726669, -127.647621],
    address: "Usborne No. 330, British Columbia, Canada",
    state: "British Columbia",
    name: "Fake datatset 2",
    group: "Fake group 2",
  },
  {
    coordinate: [56.415211, -98.739075],
    address: "Usborne No. 340, Manitoba, Canada",
    state: "Manitoba",
    name: "Fake datatset 3",
    group: "Fake group 3",
  },
  {
    coordinate: [45.327011, -79.21656],
    address: "Usborne No. 350, Ontario, Canada",
    state: "Ontario",
    name: "Fake datatset 4",
    group: "Fake group 4",
  },
  {
    coordinate: [46.829853, -71.254028],
    address: "Usborne No. 360, Quebec, Canada",
    state: "Quebec",
    name: "Fake datatset 5",
    group: "Fake group 5",
  },
  {
    coordinate: [52.158348, -106.584851],
    address: "Usborne No. 310, Saskatchewan, Lanigan, SK S0K 2M0, Canada",
    state: "Saskatchewan",
    name: "Fake datatset 6",
    group: "Fake group 6",
  },
  {
    coordinate: [52.458448, -106.584851],
    address: "Usborne No. 320, Saskatchewan, Lanigan, SK S0K 2M0, Canada",
    state: "Saskatchewan",
    name: "Fake datatset 7",
    group: "Fake group 7",
  },
];

class MapView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: fakeMarkers,
    };
  }

  componentDidMount() {
    if (
      this.props.searchStore.datasetLocations &&
      !this.props.searchStore.selectedLocation
    ) {
      // this.props.updateSelectedFilterValueSelections(
      //   this.props.searchStore.datasetLocations[0],
      //   "location"
      // );
      this.props.datasetLocationInMap(
        this.props.searchStore.datasetLocations[0]
      );
    }
  }

  handleMarkerSelection = (markerValue) => {
    // this.props.searchStore.filteredMarkers.map(({dataset_id}) => {
    //   location.label === markerValue && this.props.updateSelectedFilterValueSelections(dataset_id, "id");
    // })

    this.props.updateSelectedFilterValueSelections(markerValue, "id");
  };

  render() {
    const { searchArea, selectedLocation } =
      this.props.searchStore.searchFilters;
    const { areaTracker } = this.props.searchStore;
    return (
      <div className={this.props.height && "map"}>
        <Map
          center={[55.0, -106.0]}
          zoom={2}
          maxZoom={18}
          style={{ width: "100%", height: "236px" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {this.props.searchStore.markers.length > 0 && (
            <MarkerCluster
              markers={this.props.searchStore.markers}
              handleMarkerSelection={this.handleMarkerSelection}
              tracker={this.state.tracker}
            />
          )}
          {/* {selectedLocation && selectedLocation.label === "All" && */}
          <AreaSelect tracker={areaTracker} />
          {/* } */}
        </Map>
        {/* <div style={{ marginTop: "5px", marginBottom: "0px", float: "right" }}>
          <Button
            type="primary"
            danger
            className="mr-2"
            disabled={searchArea === null ? true : false}
            onClick={() => {
              this.props.removeMapArea();
              this.setState({ tracker: !this.state.tracker });
            }}
          >
            Clear
          </Button>
          <Button type="primary" disabled={searchArea === null ? true : false}>
            Apply
          </Button>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchStore: state.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedFilterValueSelections: (selectedOptions, filterType) =>
      dispatch(
        updateSelectedFilterValueSelections(selectedOptions, filterType)
      ),
    datasetLocationInMap: (loactions) =>
      dispatch(datasetLocationInMap(loactions)),

    removeMapArea: () => dispatch(removeMapArea()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapView);

import React from "react";
import { Modal } from "react-bootstrap";
import XLSX from "xlsx";
import { Skeleton } from "antd";
import 'antd/dist/antd.css';

export default class xlsxRenderModal extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading: true
        }
    }

    onModalClose = () => {
        this.props.onModalClosed();
    }

    displayHandler = () => {

        const filePath = this.props.fileUrl;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", filePath, true);
        xhr.overrideMimeType('text\/plain; charset=x-user-defined');
        xhr.onloadstart = () => {
            this.setState({
                isLoading: true
            })
        }
        xhr.onload = () => {
            const data = xhr.responseText;
            const dummyFile = new File([], 'sample.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const reader = new FileReader();
            reader.onload = function (e) {
                var wb;
                wb = XLSX.read(data, { type: 'binary' });
                const sheetName = wb.SheetNames[0];
                const htmldata = XLSX.utils.sheet_to_html(wb.Sheets[sheetName], {id: 'xlsx-table" class="table table-bordered table-striped table-hover'});
                document.querySelector('#xlsx-render').innerHTML = htmldata;
            };
            reader.readAsBinaryString(dummyFile);
            reader.onloadend = () => {
                this.setState({
                    isLoading:false
                })
            }
        };
        xhr.send(null);
    }

    render() {
        return(
            <Modal show={this.props.show} onHide={this.onModalClose} onShow={this.displayHandler} size='xl'>
                <Modal.Header>
                    <h3>{this.props.fileName}</h3>
                </Modal.Header>
                <Modal.Body>
                    <Skeleton active loading={this.state.isLoading}/>
                    <Skeleton active loading={this.state.isLoading}/>
                    <div id="xlsx-render" className='table-responsive table-responsive-sm'>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

}
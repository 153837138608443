import React from "react";
import { Modal } from "react-bootstrap";
// import PDFViewer from 'pdf-viewer-reactjs';

export default class PdfRendererModal extends React.Component {

    onModalClosed = () =>{
        this.props.onModalClosed(true);
    }
    render() {
        return(
            <Modal show={this.props.show} onHide= {this.onModalClosed} size='lg'>
                <Modal.Body>
                    {/* <PDFViewer
                        document={{
                            url: this.props.pdfUrl,
                            scale: 2
                        }}
                        hideRotation={true}
                        hideZoom={true}
                        navigation={{
                            css: {
                                navbarWrapper: 'navbarWrapper',
                                previousPageBtn: 'pdf-reader-nav-btn',
                                nextPageBtn: 'pdf-reader-nav-btn'
                            }
                        }}
                    /> */}
                </Modal.Body>
            </Modal>
        )
    }
}
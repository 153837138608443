import * as groupMemberActionTypes from "./groupMemberActionTypes";

const initialState = {
  showAddMemberModal: false,
  users: [],
  filteredUsers: [],
  selectedUsers: [],
  isUserOptionsLoading: false,
  isExpandedGroupReqSent: false,
  error: null,
  selectedGroup: 0,
  isSendingInvite: false,
  showLeaveAlert: false,
  activeAccordion: null,
};

const groupMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case groupMemberActionTypes.SHOW_MODAL_ADD_MEMBER:
      return {
        ...state,
        showAddMemberModal: true,
      };

    case groupMemberActionTypes.HIDE_MODAL_ADD_MEMBER:
      return {
        ...state,
        showAddMemberModal: false,
      };
    case groupMemberActionTypes.LOAD_USERS_REQUEST:
      return {
        ...state,
        isUserOptionsLoading: true,
      };

    case groupMemberActionTypes.LOAD_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        isUserOptionsLoading: false,
      };

    case groupMemberActionTypes.LOAD_USERS_FAILED:
      return {
        ...state,
        isUserOptionsLoading: false,
        error: action.payload.error,
      };

    case groupMemberActionTypes.LOAD_FILTERED_USERS_SUCCESS:
      let filteredUsers = state.users.filter(({ name }) => {
        name = name.toLowerCase();
        return name.includes(action.payload.searchText.toLowerCase());
      });

      return {
        ...state,
        filteredUsers: filteredUsers,
      };

    case groupMemberActionTypes.UPDATE_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.payload.selectedGroup,
      };

    case groupMemberActionTypes.SEND_INVITATION_REQUEST:
      return {
        ...state,
        isSendingInvite: true,
      };

    case groupMemberActionTypes.SEND_INVITATION_SUCCESS:
      return {
        ...state,
        isSendingInvite: false,
        users: updateInvitationStatus(state.users, action.payload),
        filteredUsers: updateInvitationStatus(
          state.filteredUsers,
          action.payload
        ),
      };

    case groupMemberActionTypes.SEND_INVITATION_FAILED:
      return {
        ...state,
        isSendingInvite: false,
        error: action.payload.error,
      };

    case groupMemberActionTypes.USER_JOIN_REQUEST_SUCCESS:
      return {
        ...state,
        isExpandedGroupReqSent: true,
      };

    case groupMemberActionTypes.UPDATE_JOIN_GROUP_REQUEST:
      return {
        ...state,
        isExpandedGroupReqSent: action.payload.isRequested,
      };
    case groupMemberActionTypes.SHOW_LEAVE_ALERT:
      return {
        ...state,
        showLeaveAlert: true,
        selectedGroup: action.payload.selectedGroup,
      };
    case groupMemberActionTypes.HIDE_LEAVE_ALERT:
      return {
        ...state,
        showLeaveAlert: false,
      };
    case groupMemberActionTypes.UPDATE_ACTIVE_ACCORDION:
      return {
        ...state,
        activeAccordion: action.payload.groupId,
      };
    default:
      return state;
  }
};

const updateInvitationStatus = (users, payload) => {
  let updatedUsers = users.map((user) => {
    return user.id === payload.userId
      ? {
          ...user,
          invited: payload.invited,
          invitation_status: payload.invitation_status,
        }
      : user;
  });
  return updatedUsers;
};

export default groupMemberReducer;

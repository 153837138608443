import * as searchActiontypes from "./searchActionTypes";
import {
  loadFilters,
  addNewFilter,
  dltFilter,
  updtFilter,
  loadMapLoactions,
} from "../../dataaccess/searchDataAccess";
import {
  locationDDL,
  fakeMarkers,
} from "../../components/discreteComponents/map/mapView";
import {
  parseFilterValues,
  parseFilterTypes,
} from "../../helperMethods/filterParser";
import { NotificationManager } from "react-notifications";

// Filter types

export const filterTypes = {
  fileType: "FileType",
  year: "Year",
  variety: "Variety",
  location: "Location",
  crops: "Crops",
  mimeTypes: "MimeTypes",
};

Object.freeze(filterTypes);

//Actions

export const actionGetFiltersRequest = () => {
  return {
    type: searchActiontypes.GET_FILTERS_REQUEST,
  };
};

const actionGetFiltersSuccess = (payload) => {
  return {
    type: searchActiontypes.GET_FILTERS_SUCCESS,
    payload: payload,
  };
};

export const actionGetFiltersFailed = (payload) => {
  return {
    type: searchActiontypes.GET_FILTERS_FAILED,
    payload: payload,
  };
};

const actionUpdateSearchText = (payload) => {
  return {
    type: searchActiontypes.UPDATE_SEARCH_TEXT,
    payload: payload,
  };
};

const actionUpdateSelectedMimetypes = (payload) => {
  return {
    type: searchActiontypes.UPDATE_SELECTED_MIMETYPES,
    payload: payload,
  };
};

const actionUpdateSelectedYears = (payload) => {
  return {
    type: searchActiontypes.UPDATE_SELECTED_DATASETYEARS,
    payload: payload,
  };
};

const actionUpdateSelectedLocation = (payload) => {
  return {
    type: searchActiontypes.UPDATE_SELECTED_DATASET_LOCATION,
    payload: payload,
  };
};

const actionUpdateSelectedCrops = (payload) => {
  return {
    type: searchActiontypes.UPDATE_SELECTED_CROPS_DATASETS,
    payload: payload,
  };
};

const actionUpdateSelectedMimeTypes = (payload) => {
  return {
    type: searchActiontypes.UPDATE_SELECTED_MIME_TYPES_DATASETS,
    payload: payload,
  };
};

const actionUpdateSelectedDatasetId = (payload) => {
  return {
    type: searchActiontypes.UPDATE_SELECTED_DATASET_ID,
    payload: payload,
  };
};

const actionHitSearch = () => {
  return {
    type: searchActiontypes.HIT_SEARCH,
  };
};

const actionSearchModal = () => {
  return {
    type: searchActiontypes.HIDE_SHOW_SEARCH_MODAL,
  };
};

const actionSearchDropDownFilter = () => {
  return {
    type: searchActiontypes.SEARCH_DROP_DOWN_FILTER,
  };
};

const actionSearchDropDownFilterPageGuide = () => {
  return {
    type: searchActiontypes.SEARCH_DROP_DOWN_FILTER_PAGE_GUIDE,
  };
};

const actionUpdateSelectedFilterType = (payload) => {
  return {
    type: searchActiontypes.UPDATE_SELECTED_FILTER_TYPE,
    payload: payload,
  };
};

const actionSetFilterValueSelections = (payload) => {
  return {
    type: searchActiontypes.SET_FILTER_SELECTIONS,
    payload: payload,
  };
};

const actionUpdateSelectedFilterValueSelections = (payload) => {
  return {
    type: searchActiontypes.UPDATE_SELECTED_FILTER_VALUE_SELECTIONS,
    payload: payload,
  };
};

const actionInitYearFilter = (payload) => {
  return {
    type: searchActiontypes.INIT_YEAR_FILTER,
    payload: payload,
  };
};

const actionHandleYearUpdate = (payload) => {
  return {
    type: searchActiontypes.HANDLE_YEAR_UPDATE,
    payload: payload,
  };
};
//Action Creators

export const updateSearchText = (searchText) => {
  return (dispatch) => {
    dispatch(actionUpdateSearchText({ searchText: searchText }));
  };
};

// const updateSelectedMimetypes = (selectedMimetypes) => {
//     let mimeFilters = [];
//     if(selectedMimetypes){
//         selectedMimetypes.forEach(selectedMimetype => {
//             mimeFilters.push(selectedMimetype.value);
//         });
//     }
//     selectedMimetypes = {selectedMimetypes: selectedMimetypes, mimeFilters: mimeFilters};

//     return (dispatch) => {
//         dispatch(actionUpdateSelectedMimetypes(selectedMimetypes))
//     }
// }

// const updateSelectedYears = (selectedYears) => {
//     let yearFilters = [];
//     if(selectedYears){
//         selectedYears.forEach(selectedYear => {
//             yearFilters.push(selectedYear.value);
//         });
//     }
//     selectedYears = {selectedYears: selectedYears, yearFilters: yearFilters};

//     return (dispatch) => {
//         dispatch(actionUpdateSelectedYears(selectedYears));
//     }
// }

export const hitSearch = () => {
  return (dispatch) => {
    dispatch(actionHitSearch());
  };
};

export const filterConfigAction = () => {
  return { type: searchActiontypes.FILTER_CONFIG };
};

export const getFilters = () => {
  return (dispatch) => {
    dispatch(actionGetFiltersRequest());
    loadFilters()
      .then((response) => {
        // if (response.success) {
        let mimetypes = response.data.filetype; //parseFilterValues(response.data.filetype);
        let years = response.data.year; //parseFilterValues(response.data.year);
        let location = response.data.location;
        const crops = response.data.crop;
        const keywords = response.data.keyword;
        let filterTypes = parseFilterTypes(response.data);
        const filters = response.data.filters;
        localStorage.setItem(
          "domain_year",
          `${years[0].label}${years[years.length - 1].label}`
        );

        dispatch(
          actionGetFiltersSuccess({
            mimetypes: mimetypes,
            datasetYears: years,
            location: location,
            crops,
            keywords,
            filterTypes: filterTypes,
            filters,
          })
        );
        // return;
        // } else {
        //   NotificationManager.error("Failed to load filters", "Error!");
        // }
      })
      .catch((err) => {
        console.log(err.response);
        NotificationManager.error(
          err.response.data.error_message || "Failed to load filters",
          "Error!"
        );
      });
  };
};

export const getMapLocations = () => (dispatch) => {
  loadMapLoactions().then((response) => {
    dispatch({
      type: searchActiontypes.FETCH_STATIC_MARKER,
      payload: response.res.data,
    });
  });
};

export const updateSelectedFilterType = (filterType) => {
  return (dispatch) => {
    dispatch(actionUpdateSelectedFilterType(filterType));
    dispatch(actionSetFilterValueSelections(filterType.value));
  };
};

export const initYearFilter = (yearRange, count, yearData) => {
  return (dispatch) => {
    dispatch(actionInitYearFilter({ yearRange, count, yearData }));
  };
};

export const handleYearUpdate = (value) => {
  return (dispatch) => {
    dispatch(actionHandleYearUpdate(value));
  };
};

export const datasetLocationInMap = (selectedOptions) => (dispatch) =>
  dispatch({ type: searchActiontypes.RENDER_MAP, payload: selectedOptions });

export const updateSelectedFilterValueSelections = (
  selectedOptions,
  selectedFilterType
) => {
  return (dispatch) => {
    switch (selectedFilterType) {
      case "fileType":
        let mimeFilters = [];
        if (selectedOptions) {
          selectedOptions.forEach((selectedMimetype) => {
            mimeFilters.push(selectedMimetype.value);
          });
        }
        let selectedMimetypes = {
          selectedMimetypes: selectedOptions,
          mimeFilters: mimeFilters,
        };
        dispatch(actionUpdateSelectedMimetypes(selectedMimetypes));
        dispatch(actionHitSearch());
        break;

      case "year":
        let yearFilters = selectedOptions;
        let selectedYears = {
          selectedYears: selectedOptions,
          yearFilters: yearFilters,
        };

        dispatch(actionUpdateSelectedYears(selectedYears));
        dispatch(handleYearUpdate({ yearValues: selectedOptions }));
        dispatch(actionHitSearch());
        break;

      case "variety":
        break;

      case "location":
        dispatch(
          actionUpdateSelectedLocation({ selectedLocation: selectedOptions })
        );
        // dispatch(actionHitSearch());
        break;
      case "crops":
        dispatch(
          actionUpdateSelectedCrops({
            crops: selectedOptions.crops,
            selectedCropsIndex: selectedOptions.selectedCropsIndex,
          })
        );
        dispatch(actionHitSearch());
        break;
      case "mimeTypes":
        dispatch(
          actionUpdateSelectedMimeTypes({
            mimeTypes: selectedOptions.mimeTypes,
            selectedIndex: selectedOptions.selectedMymeTypesIndex,
          })
        );
        dispatch(actionHitSearch());
        break;
      case "id":
        dispatch(
          actionUpdateSelectedDatasetId({
            selectedDatasetId: selectedOptions.id,
            selectedLocation: {
              label: selectedOptions.location,
              value: selectedOptions.location,
            },
          })
        );
      default:
        break;
    }
    dispatch(actionUpdateSelectedFilterValueSelections(selectedOptions));
  };
};

export const removeFilters = (filterType) => {
  return (dispatch, getState) => {
    switch (filterType) {
      case filterTypes.fileType:
        let selectedMimetypes = { selectedMimetypes: [], mimeFilters: [] };
        dispatch(actionUpdateSelectedMimetypes(selectedMimetypes));
        dispatch(actionHitSearch());
        break;

      case filterTypes.year:
        let yearUpdate = {
          yearUpdate: getState().search.yearDomain,
          yearValues: getState().search.yearDomain,
        };
        dispatch(actionHandleYearUpdate(yearUpdate));
        dispatch(actionHitSearch());
        break;

      case filterTypes.variety:
        break;

      case filterTypes.location:
        dispatch(
          updateSelectedFilterValueSelections(
            getState().search.datasetLocations[0],
            "location"
          )
        );
        break;
      case filterTypes.crops:
        dispatch(
          updateSelectedFilterValueSelections(
            { crops: [], selectedCropsIndex: [] },
            "crops"
          )
        );
        break;
      case filterTypes.mimeTypes:
        dispatch(
          updateSelectedFilterValueSelections(
            { mimeTypes: [], selectedMymeTypesIndex: [] },
            "mimeTypes"
          )
        );
        break;
      default:
        break;
    }
  };
};

export const searchModal = () => {
  return (dispatch) => {
    dispatch(actionSearchModal());
  };
};

export const searchDropDownFilter = () => {
  return (dispatch) => {
    dispatch(actionSearchDropDownFilter());
  };
};

export const searchDropDownFilterPageGuide = () => {
  return (dispatch) => {
    dispatch(actionSearchDropDownFilterPageGuide());
  };
};

export const filterConfig = () => (dispatch) => dispatch(filterConfigAction());

export const actionAddFilterRequest = () => {
  return { type: searchActiontypes.ADD_FILTER_REQUEST };
};
export const actionAddFilterSuccess = (payload) => {
  return { type: searchActiontypes.ADD_FILTER_SUCCESS, payload };
};
export const actionAddFilterFail = () => {
  return { type: searchActiontypes.ADD_FILTER_FAIL };
};
export const addFilter = (newFilter, authToken = null) => {
  return (dispatch) => {
    dispatch(actionAddFilterRequest());
    addNewFilter(newFilter, authToken)
      .then((response) => {
        if (response.success) {
          dispatch(actionAddFilterSuccess(response.res.data));
          setTimeout(() => {
            NotificationManager.success("Filter added successfully", "Success");
          }, 100);
        } else {
          dispatch(actionAddFilterFail());
          NotificationManager.error("Failed to add filter", "Error!");
        }
      })
      .catch((err) => {
        dispatch(actionAddFilterFail());
        NotificationManager.error("Failed to add filter", "Error!");
      });
  };
};

export const actionDeleteFilterRequest = () => {
  return { type: searchActiontypes.DELETE_FILTER_REQUEST };
};
export const actionDeleteFilterSuccess = (payload) => {
  return { type: searchActiontypes.DELETE_FILTER_SUCCESS, payload };
};
export const actionDeleteFilterFail = () => {
  return { type: searchActiontypes.DELETE_FILTER_FAIL };
};
export const deleteFilter = (filterId) => {
  return (dispatch) => {
    dispatch(actionDeleteFilterRequest());
    dltFilter(filterId)
      .then((response) => {
        if (response.success) {
          dispatch(actionDeleteFilterSuccess(filterId));
          setTimeout(() => {
            NotificationManager.success(
              "Filter deleted successfully",
              "Success"
            );
          }, 100);
        } else {
          dispatch(actionDeleteFilterFail());
          NotificationManager.error("Failed to delete filter", "Error!");
        }
      })
      .catch((err) => {
        dispatch(actionDeleteFilterFail());
        NotificationManager.error("Failed to add filter", "Error!");
      });
  };
};

export const actionUpdateFilterTitleRequest = () => {
  return { type: searchActiontypes.UPDATE_FILTER_TITLE_REQUEST };
};
export const actionUpdateFilterTitleSuccess = (payload) => {
  return { type: searchActiontypes.UPDATE_FILTER_TITLE_SUCCESS, payload };
};
export const actionUpdateFilterTitleFail = () => {
  return { type: searchActiontypes.UPDATE_FILTER_TITLE_FAIL };
};
export const updateFilterTitle = (name, filterId) => {
  return (dispatch) => {
    dispatch(actionUpdateFilterTitleRequest());
    updtFilter(name, filterId)
      .then((response) => {
        if (response.success) {
          dispatch(actionUpdateFilterTitleSuccess(response.res.data));
          setTimeout(() => {
            NotificationManager.success(
              "Filter Updated successfully",
              "Success"
            );
          }, 100);
        } else {
          dispatch(actionUpdateFilterTitleFail());
          NotificationManager.error("Failed to Update filter", "Error!");
        }
      })
      .catch((err) => {
        dispatch(actionUpdateFilterTitleFail());
        NotificationManager.error("Failed to add filter", "Error!");
      });
  };
};

export const cancleFilter = () => (dispatch) =>
  dispatch({ type: searchActiontypes.CANCLE_FILTER });

export const selectMapArea = (area) => (dispatch) =>
  dispatch({ type: searchActiontypes.ADD_MAP_AREA, payload: area });

export const removeMapArea = () => (dispatch) =>
  dispatch({ type: searchActiontypes.REMOVE_MAP_AREA });

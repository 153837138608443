import React from 'react';
import ReactDOM from 'react-dom';

import "antd/dist/antd.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import './css/custom-grids.css';
import './index.css';

import App from './components/app';
import {Provider} from 'react-redux';
import store from './store/store';
 
ReactDOM.render(
 <Provider store = {store}>
      <App/>        
 </Provider>,
 document.getElementById('root')
);



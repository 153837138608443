import * as groupTypes from "./groupActionTypes";

const initialState = {
  showAddEditGroupModal: false,
  showDataTemplateModal: false,
  createDataset: false,
  isListInitLoading: false,
  isDetailsLoading: false,
  isUserAdmin: null,
  selectedGroupId: 0,
  selectedGroupForDetail: null,
  selectedGroupIsUserType: false,

  group: {},
  groups: [],

  pageNum: 0,
  numOfItems: 35,
  hasMore: false,

  groupsList: [],
  metadataList: [],
  expandedGroupDetails: null,
  expandedGroupGeneralPermission: null,
  expandedGroupUserPermission: null,
  expandedGroupDataTemplateDetails: null,
  expandeGroupMembersDetails: null,
  expandedGroupDatasets: [],
  parentGroups: [],
  metadataTemplate: [],

  error: null,
  groupsListError: null,
  metadataListError: null,

  numberOfSearchResults: null,
  showGroupDetailModal: false,
  uploadDataTemplateFromDataset: false,
};

const groupReducer = (state = initialState, action) => {
  switch (action.type) {
    case groupTypes.GET_PARENT_GROUPS_REQUEST:
      return {
        ...state,
      };

    case groupTypes.GET_PARENT_GROUPS_SUCCESS:
      return {
        ...state,
        parentGroups: action.payload.data,
      };

    case groupTypes.GET_PARENT_GROUPS_FAILED:
      return {
        ...state,
        error: action.payload.error,
      };

    case groupTypes.GET_GROUPS_REQUEST:
      return {
        ...state,
        groups: [],
        isListInitLoading: true,
        numberOfSearchResults: null,
        hasMore: false,
      };

    case groupTypes.GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: [...state.groups, ...action.payload.data],
        pageNum: JSON.parse(action.payload.pageNum),
        hasMore: action.payload.hasMore,
        isListInitLoading: false,
      };

    case groupTypes.GET_GROUPS_FAILED:
      return {
        ...state,
        isListInitLoading: false,
        numberOfSearchResults: null,
        error: action.payload.error,
      };

    case groupTypes.GET_GROUP_DETAILS_REQUEST:
      return {
        ...state,
        isDetailsLoading: true,
        error: null,
      };

    case groupTypes.GET_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        expandedGroupDetails: action.payload.properties,
        expandedGroupGeneralPermission: action.payload.generalPermission,
        expandedGroupUserPermission: action.payload.userPermission,
        expandedGroupDataTemplateDetails: action.payload.dataTemplates,
        expandeGroupMembersDetails: action.payload.members,
        isUserAdmin: action.payload.isAdmin,
        expandedGroupDatasets: action.payload.datasets,
        isDetailsLoading: false,
      };

    case groupTypes.GET_GROUP_DETAILS_FAILED:
      return {
        ...state,
        expandedGroupDetails: null,
        expandedGroupGeneralPermission: null,
        expandedGroupUserPermission: null,
        expandedGroupDataTemplateDetails: null,
        expandeGroupMembersDetails: null,
        isUserAdmin: null,
        isDetailsLoading: false,
        error: "Failed to group load details!", //action.payload.message
      };

    case groupTypes.SELECT_A_GROUP:
      return {
        ...state,
        selectedGroupId: action.payload.selectedGroupId,
      };

    case groupTypes.GET_GROUPS_SEARCH_RESULTS_REQUEST:
      return {
        ...state,
        groups: [],
        isListInitLoading: true,
        hasMore: false,
      };

    case groupTypes.GET_GROUPS_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        groups: [...state.groups, ...action.payload.data],
        isListInitLoading: false,
        hasMore: action.payload.hasMore,
        pageNum: JSON.parse(action.payload.pageNum),
      };

    case groupTypes.GET_GROUPS_SEARCH_RESULTS_FAILED:
      return {
        ...state,
        isListInitLoading: false,
        error: action.payload.error,
      };

    case groupTypes.GET_SWITCHED_GROUPS_REQUEST:
      return {
        ...state,
        groups: [],
        isListInitLoading: true,
        hasMore: false,
      };

    case groupTypes.GET_SWITCHED_GROUPS_SUCCESS:
      return {
        ...state,
        groups: [...state.groups, ...action.payload.data],
        isListInitLoading: false,
        hasMore: action.payload.hasMore,
        pageNum: JSON.parse(action.payload.pageNum),
      };

    case groupTypes.GET_SWITCHED_GROUPS_FAILED:
      return {
        ...state,
        isListInitLoading: false,
        error: action.payload.error,
      };

    case groupTypes.DISMISS_NUMBER_OF_GROUP_RESULTS:
      return {
        ...state,
        numberOfSearchResults: null,
      };

    case groupTypes.SET_NUMBER_OF_GROUP_RESULTS:
      return {
        ...state,
        numberOfSearchResults: action.payload.numberOfSearchResults,
      };

    case groupTypes.EDIT_GROUP_TITLE_SUCCESS:
      let updatedGroups = state.groups.map((group) => {
        return group.id === action.payload.groupId
          ? { ...group, name: action.payload.groupTitle }
          : group;
      });
      return {
        ...state,
        groups: updatedGroups,
      };

    case groupTypes.EDIT_GROUP_DESCRIPTION_SUCCESS:
      let modifiedGroups = state.groups.map((group) => {
        return group.id === action.payload.groupId
          ? { ...group, description: action.payload.groupDescrption }
          : group;
      });
      return {
        ...state,
        groups: modifiedGroups,
        expandedGroupDetails: {
          ...state.expandedGroupDetails,
          description: action.payload.groupDescrption,
        },
      };

    case groupTypes.EDIT_GROUP_ADMIN_SUCCESS:
      let correctedGroups = state.groups.map((group) => {
        return group.id === action.payload.groupId
          ? { ...group, isAdmin: false }
          : group;
      });
      return {
        ...state,
        groups: correctedGroups,
        isUserAdmin: false,
        expandedGroupDetails: action.payload.updatedGroupDetails,
      };

    case groupTypes.EDIT_PARENT_GROUP_SUCCESS:
      let _updatedGroup = state.groups.map((group) => {
        return group.id === action.payload.groupId
          ? {
              ...group,
              parent_group: action.payload.updatedGroupDetails.parentGroup,
            }
          : group;
      });

      return {
        ...state,
        groups: _updatedGroup,
        expandedGroupDetails: action.payload.updatedGroupDetails,
      };

    case groupTypes.EDIT_GROUP_PERMISSION_SUCCESS:
      return {
        ...state,
        expandedGroupGeneralPermission:
          action.payload.updatedGroupPermissionDetails,
      };

    case groupTypes.APPEND_NEW_METADATA:
      return {
        ...state,
        expandedGroupDataTemplateDetails: [
          ...state.expandedGroupDataTemplateDetails,
          action.payload,
        ],
      };

    case groupTypes.SHOW_MODAL_ADD_EDIT_GROUP:
      return {
        ...state,
        showAddEditGroupModal: true,
        createDataset: action.payload,
      };

    case groupTypes.HIDE_MODAL_ADD_EDIT_GROUP:
      return {
        ...state,
        showAddEditGroupModal: false,
        createDataset: false,
      };

    case groupTypes.SHOW_MODAL_DATA_TEMPLATE:
      return {
        ...state,
        showDataTemplateModal: true,
      };

    case groupTypes.HIDE_MODAL_DATA_TEMPLATE:
      return {
        ...state,
        showDataTemplateModal: false,
      };

    case groupTypes.SHOW_GROUP_DETAILS_MODAL:
      return {
        ...state,
        showGroupDetailModal: true,
        selectedGroupId: action.payload.selectedGroupHeader.id,
        selectedGroupForDetail: action.payload.selectedGroupHeader,
        selectedGroupIsUserType: action.payload.isUserGroup,
      };

    case groupTypes.HIDE_GROUP_DETAILS_MODAL:
      return {
        ...state,
        showGroupDetailModal: false,
        selectedGroupId: 0,
        selectedGroupIsUserType: false,
      };

    case groupTypes.GROUP_MEMBER_REMOVAL:
      state.expandeGroupMembersDetails.forEach((member, index) => {
        if (member.userId === action.payload.memberId)
          state.expandeGroupMembersDetails.splice(index, 1);
      });
      let updatedGroup = state.groups.map((group) => {
        return group.id === action.payload.groupId
          ? { ...group, members: group.members - 1 }
          : group;
      });

      return {
        ...state,
        expandeGroupMembersDetails: [...state.expandeGroupMembersDetails],
        groups: updatedGroup,
      };
    case groupTypes.UPDATE_OR_DELETE_GROUPS:
      return {
        ...state,
        groupTypes: action.payload,
      };
    case groupTypes.UPLOAD_DATA_TEMPLATE_FROM_DATSET:
      return {
        ...state,
        uploadDataTemplateFromDataset: !state.uploadDataTemplateFromDataset,
      };

    default:
      return state;
  }
};

export default groupReducer;

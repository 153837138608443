import { END_TOUR, MID_TOUR, SECOND_TOUR_STEP, START_TOUR } from './tourActionTypes';



const tourReducer = (state = {tourStatus: false, steps: 0, showDropdownFilter: false}, action) => {

    switch (action.type) {
        case START_TOUR:
            return {
                ...state,
                tourStatus: action.payload
            }
            case SECOND_TOUR_STEP:
                return{
                    ...state,
                    showDropdownFilter: action.payload,
            }
            case MID_TOUR:
                return {
                    ...state,
                    steps: action.payload,
                    // showDropdownFilter: false
                }

        case END_TOUR:
            return {
                ...state,
                tourStatus: action.payload,
                showDropdownFilter: false,
                steps: 0,
            }

        default:
            return state;
    }
}

export default tourReducer;
export const UPDATE_SEARCH_TEXT = "UPDATE_SEARCH_TEXT";

export const GET_FILTERS_REQUEST = "GET_FILTERS_REQUEST";
export const GET_FILTERS_SUCCESS = "GET_FILTERS_SUCCESS";
export const GET_FILTERS_FAILED = "GET_FILTERS_FAILED";

export const HIT_SEARCH = "HIT_SEARCH";
export const RENDER_MAP = "RENDER_MAP";

export const UPDATE_MIMETYPES = "UPDATE_MIMETYPES";

export const INIT_YEAR_FILTER = "INIT_YEAR_FILTER";
export const HANDLE_YEAR_UPDATE = "HANDLE_YEAR_UPDATE";

export const UPDATE_DATASETYEARS = "UPDATE_DATASETYEARS";

export const UPDATE_SELECTED_MIMETYPES = "UPDATE_SELECTED_MIMETYPES";

export const UPDATE_SELECTED_DATASETYEARS = "UPDATE_SELECTED_DATASETYEARS";

export const UPDATE_SELECTED_DATASET_LOCATION =
  "UPDATE_SELECTED_DATASET_LOCATION";
export const UPDATE_MAP_MARKERS = "UPDATE_MAP_MARKERS";
export const FETCH_STATIC_MARKER = "FETCH_STATIC_MARKER";

export const UPDATE_SELECTED_VARIETIES = "UPDATE_SELECTED_VARIETIES";

export const UPDATE_SELECTED_FILTER_TYPE = "UPDATE_SELECTED_FILTER_TYPE";

export const SET_FILTER_SELECTIONS = "SET_FILTER_SELECTIONS";
export const UPDATE_SELECTED_DATASET_ID = "UPDATE_SELECTED_DATASET_ID";

export const UPDATE_SELECTED_FILTER_VALUE_SELECTIONS =
  "UPDATE_SELECTED_FILTER_VALUE_SELECTIONS";
export const UPDATE_SELECTED_CROPS_DATASETS = "UPDATE_SELECTED_CROPS_DATASETS";
export const UPDATE_SELECTED_MIME_TYPES_DATASETS =
  "UPDATE_SELECTED_MIME_TYPES_DATASETS";
export const HIDE_SHOW_SEARCH_MODAL = "HIDE_SHOW_SEARCH_MODAL";
export const SEARCH_DROP_DOWN_FILTER = "SEARCH_DROP_DOWN_FILTER";
export const SEARCH_DROP_DOWN_FILTER_PAGE_GUIDE =
  "SEARCH_DROP_DOWN_FILTER_PAGE_GUIDE";
export const FILTER_CONFIG = "FILTER_CONFIG";
export const ADD_FILTER_REQUEST = "ADD_FILTER_REQUEST";
export const ADD_FILTER_SUCCESS = "ADD_FILTER_SUCCESS";
export const ADD_FILTER_FAIL = "ADD_FILTER_FAIL";

export const DELETE_FILTER_REQUEST = "DELETE_FILTER_REQUEST";
export const DELETE_FILTER_SUCCESS = "DELETE_FILTER_SUCCESS";
export const DELETE_FILTER_FAIL = "DELETE_FILTER_FAIL";
export const UPDATE_FILTER_TITLE_REQUEST = "UPDATE_FILTER_TITLE_REQUEST";
export const UPDATE_FILTER_TITLE_SUCCESS = "UPDATE_FILTER_TITLE_SUCCESS";
export const UPDATE_FILTER_TITLE_FAIL = "UPDATE_FILTER_TITLE_FAIL";

export const ADD_FILTER_CONFIG_TYPE = "ADD_FILTER_CONFIG_TYPE";
export const FILTER_CONFIG_VIEW = "FILTER_CONFIG_VIEW";
export const CANCLE_FILTER = "CANCLE_FILTER";
export const ADD_MAP_AREA = "ADD_MAP_AREA";
export const REMOVE_MAP_AREA = "REMOVE_MAP_AREA";
export const VIEW_MAP_AREA = "VIEW_MAP_AREA"

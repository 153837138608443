export const jbrowseUrl = process.env.REACT_APP_URL_JBROWSE;

export const DATASET_STATUS = {
  REGISTERED: "Registered",
  PUBLISHED: "Published",
};

export const EXPANDED_RESOURCES_NAME = "expandedResourcesName";

//@api url
export const GET_EXPANDED_RESOURCES_URL = "/api/group/";

export const DATASET_TYPE = {
  MY_DATASET: "My dataset",
  MY_GROUP_DATASET: "My group dataset",
  PUBLISHED: "Published"
};

// Chonkey Browser

export const CHONKEY_OPEN_FOLDER_EXPANDED_TAB =
  "CHONKEY_OPEN_FOLDER_EXPANDED_TAB";
export const CHONKEY_OPEN_FOLDER = "CHONKEY_OPEN_FOLDER";
export const EMAIL_REGX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DATSET_SHARE_PERMISSIONS = [
  { label: "Editor", value: 2 },
  { label: "Viewer", value: 1 },
  // { label: "Commenter", value: "commenter" },
];

export const ACCEESS_PERMISION = {
  NO_PERMISSION: 0,
  VIEWER: 1,
  WRITER: 2,
  OWNER:3
}


import * as groupMemberActionTypes from "./groupMemberActionTypes";
import * as groupsDataAccess from "../../dataaccess/groupsDataAccess";
import { loadGroups } from "./groupActions";
import { NotificationManager } from 'react-notifications';


//Actions

const actionShowAddMemmberModal = () => {
    return {
        type: groupMemberActionTypes.SHOW_MODAL_ADD_MEMBER
    }
}

const actionHideAddMemmberModal = () => {
    return {
        type: groupMemberActionTypes.HIDE_MODAL_ADD_MEMBER
    }
}

const actionLoadUsersRequest = () => {
    return {
        type: groupMemberActionTypes.LOAD_USERS_REQUEST
    }
}

const actionLoadUsersSuccess = (payload) => {
    return {
        type: groupMemberActionTypes.LOAD_USERS_SUCCESS,
        payload: payload
    }
}

const actionLoadUsersFailed = (payload) => {
    return {
        type: groupMemberActionTypes.LOAD_USERS_FAILED,
        payload: payload
    }
}

const actionLoadFilteredUsersSuccess = (payload) => {
    return {
        type: groupMemberActionTypes.LOAD_FILTERED_USERS_SUCCESS,
        payload: payload
    }
}

const actionUpdateSelectedGroup = (payload) => {
    return {
        type: groupMemberActionTypes.UPDATE_SELECTED_GROUP,
        payload: payload
    }
}

const actionSendInvitationRequest = () => {
    return {
        type : groupMemberActionTypes.SEND_INVITATION_REQUEST
    }
}

const actionSendInvitationSuccess = (payload) => {
    return {
        type: groupMemberActionTypes.SEND_INVITATION_SUCCESS,
        payload: payload
    }
}

const actionSendInvitationFailed = (payload) => {
    return {
        type: groupMemberActionTypes.SEND_INVITATION_FAILED,
        payload: payload
    }
}

const actionJoinGroupRequest = () => {
    return {
        type: groupMemberActionTypes.USER_JOIN_REQUEST
    }
}

const actionJoinGroupRequestSuccess = (payload) => {
    return {
        type: groupMemberActionTypes.USER_JOIN_REQUEST_SUCCESS,
        payload: payload
    }
}

const actionJoinGroupRequestFailed = (payload) => {
    return {
        type: groupMemberActionTypes.USER_JOIN_REQUEST_FAILED,
        payload: payload
    }
}

const updateGroupReqSentSuccess = (payload) => {
    return {
        type: groupMemberActionTypes.UPDATE_JOIN_GROUP_REQUEST,
        payload: payload
    }
}

const actionShowLeaveAlert = (payload) => {
    return {
        type: groupMemberActionTypes.SHOW_LEAVE_ALERT,
        payload: payload
    }
}

const actionHideLeaveAlert = () => {
    return{
        type: groupMemberActionTypes.HIDE_LEAVE_ALERT
    }
}

const actionUpdateActiveAccordion = (payload) => {
    return {
        type: groupMemberActionTypes.UPDATE_ACTIVE_ACCORDION,
        payload: payload
    }
}
//Action Creators 

export const showAddMemmberModal = () => {
    return (dispatch) => {
        dispatch(actionShowAddMemmberModal());
    }
}

export const hideAddMemmberModal = () => {
    return (dispatch) => {
        dispatch(actionHideAddMemmberModal());
    }
}

export const loadUsers = (groupId) => {
    return (dispatch) => {
        dispatch(actionLoadUsersRequest());
        dispatch(actionUpdateSelectedGroup({selectedGroup: groupId}));
        groupsDataAccess.loadUsers(groupId)
        .then(response => {
            if (response.success) {
                dispatch(actionLoadUsersSuccess({users: response.res.data}));
                dispatch(actionLoadFilteredUsersSuccess({searchText: ''}));
            }
            else{
                dispatch(actionLoadUsersFailed({error: response.res.error}));
            }
        })
    }
}

export const loadFilteredUsers = (searchText = '') => {
    return (dispatch) => {
        dispatch(actionLoadFilteredUsersSuccess({searchText}))
    }
}

export const sendInvitation = (userId, groupId) => {
    return (dispatch) => {
        dispatch(actionSendInvitationRequest());
        groupsDataAccess.sendInvitation(userId, groupId)
        .then(response => {
            if (response.success) {
                dispatch(actionSendInvitationSuccess(response.res.data));
            } else {
                dispatch(actionSendInvitationFailed({error: response.res}));
            }
        })
    }
}

export const joinGroupRequest = (groupId) => {
    return (dispatch) => {
        dispatch(actionJoinGroupRequest());
        dispatch(actionUpdateSelectedGroup({selectedGroup: groupId}));
        groupsDataAccess.joinGroupRequest(groupId)
        .then(response => {
            if (response.success) {
                if(response.res.data.joinRequest){
                    dispatch(actionJoinGroupRequestSuccess());
                }
            }
            else{
                dispatch(actionJoinGroupRequestFailed({error: response.res.error}));
            }
        })
    }
}

export const updateGroupReqSent = (isRequested) => {
    return (dispatch) => {
        dispatch(updateGroupReqSentSuccess({isRequested: isRequested}));
    }
}

export const leaveGroup = (groupId) => {
    return (dispatch, getState) => {
        groupsDataAccess.leaveGroup(groupId)
        .then(response => {
            if (response.success) {
                if(response.res.data.isLeft){
                    NotificationManager.success('Group leave Success!', 'Success!');
                    dispatch(loadGroups(1, getState().groups.numOfItems, true));
                    dispatch(updateActiveAccordion(null))
                }
                else{
                    NotificationManager.error('Admin cannot leave the group!', 'Error!');
                }
            } else {
                NotificationManager.error('Leave group failed!', 'Error!');
            }
        })
    }
}

export const showLeaveAlert = (groupId) => {
    return (dispatch) => {
        dispatch(actionShowLeaveAlert({selectedGroup: groupId}));
    }
}

export const hideLeaveAlert = () => {
    return (dispatch) => {
        dispatch(actionHideLeaveAlert());
    } 
}

export const updateActiveAccordion = (groupId) => {
    return (dispatch) => {
        dispatch(actionUpdateActiveAccordion({groupId: groupId}));
    }
}
const initialState = {
  isShowBorder: false,
  isShowEdit: false,
  midPanelHeight: 0,
  filterPanelHeight: 0,
  toggleSearchBar: true,
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MID_PANEL_HEIGHT":
      return { ...state, midPanelHeight: action.payload };
    case "SET_FILTER_PANEL_HEIGHT":
      return { ...state, filterPanelHeight: action.payload };
    case "SHOW_HIDE_SEARCH_BAR":
      return { ...state, toggleSearchBar: action.payload };
    default:
      return state;
  }
};

export default uiReducer;

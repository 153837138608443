import React from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import { connect } from "react-redux";
import { updateSelectedFilterValueSelections } from "../../store/searchStore/searchActions";

const colors = scaleOrdinal(schemeCategory10).range();

const data = [
  { name: "Oryza sativa", value: 2 },
  { name: "Zea mays", value: 3 },
  { name: "Glycine max", value: 2 },
  { name: "Hordeum vulgare", value: 5 },
  { name: "Brassica napus", value: 8 },
  { name: "Helianthus annuus", value: 2 },
  { name: "Manihot esculenta", value: 3 },
  { name: "Avena sativa", value: 2 },
  { name: "Secale cereale", value: 5 },
  { name: "Sesamum indicum", value: 8 },
  { name: "Malus pumila", value: 2 },
  { name: "Lycopersicon esculentum", value: 3 },
  { name: "Triticum aestivum", value: 2 },
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    name,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {/* {payload.name} */}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >
        {value} {value > 0 ? "items" : "item"}
      </text>

      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );
};

class PieChartComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: [],
      pieChartData: this.props.searchStore.crops.map((crop) => {
        return { name: crop.label, value: crop.value };
      }),
    };
  }

  onPieClick = (e, index) => {
    const selectedCropsIndex = this.props.searchStore.selectedCropsIndex;
    const currentActivatedCropsIndex = selectedCropsIndex.includes(index)
      ? selectedCropsIndex.filter((activeIndex) => activeIndex !== index)
      : [...selectedCropsIndex, index];
    const selectedCrops = this.props.searchStore.searchFilters.crops;
    const currentActivatedCrops = selectedCrops.includes(e.name)
      ? selectedCrops.filter((crop) => crop !== e.name)
      : [...selectedCrops, e.name];
    this.props.updateSelectedFilterValueSelections(
      {
        crops: currentActivatedCrops,
        selectedCropsIndex: currentActivatedCropsIndex,
      },
      "crops"
    );
  };

  render() {
    return (
      <ResponsiveContainer width="100%" height={this.props.height || 250}>
        <PieChart width={250} height={250}>
          <Pie
            activeIndex={this.props.searchStore.selectedCropsIndex}
            activeShape={renderActiveShape}
            data={
              this.state.pieChartData.length > 0
                ? this.state.pieChartData
                : data
            }
            labelLine={false}
            cx={this.props.cx || 200}
            innerRadius={this.props.innerRadius || 60}
            outerRadius={this.props.outerRadius || 80}
            fill="#8884d8"
            dataKey="value"
            onClick={this.onPieClick}
          >
            {this.state.pieChartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
            {this.state.pieChartData.length === 0 &&
              data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
          </Pie>
          <Legend
            align="right"
            layout="vertical"
            wrapperStyle={{ height: "100%", overflowX: "auto" }}
          />
          <Tooltip content={true} />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchStore: state.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedFilterValueSelections: (selectedOptions, filterType) =>
      dispatch(
        updateSelectedFilterValueSelections(selectedOptions, filterType)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieChartComp);

import { combineReducers } from "redux";
import groupReducer from "./groupsStore/groupReducer";
import datasetReducer from "./datasetsStore/datasetReducer";
import authReducer from "./authStore/authReducer";
import searchReducer from "./searchStore/searchReducer";
import groupMemberReducer from "./groupsStore/groupMemberReducer";
import notificationReducer from "./notificationStore/notificationReducer";
import userReducer from "./userStore/userReducer";
import softwareReducer from "./softwareStore/softwareReducer";
import tourReducer from "./tourStore/tourReducer";
import expandedResourcesReducer from "./expandedResourceStore/expandedResourcesReducer";
import locationReducer from "./locationStore/locationReducer";
import uiReducer from "./uiStore/uiReducer";
const rootReducer = combineReducers({
  groups: groupReducer,
  datasets: datasetReducer,
  auth: authReducer,
  search: searchReducer,
  groupMember: groupMemberReducer,
  notifications: notificationReducer,
  user: userReducer,
  software: softwareReducer,
  tour: tourReducer,
  expandedResources: expandedResourcesReducer,
  location: locationReducer,
  visualization: uiReducer,
});

export default rootReducer;

import { END_TOUR, MID_TOUR, SECOND_TOUR_STEP, START_TOUR } from "./tourActionTypes"

export const actionTourStart = () => {
    return {
        type: START_TOUR,
        payload: true
    }
} 

export const actionTourMid = (steps) => {
    return {
        type: MID_TOUR,
        payload: steps
    }
} 
export const actionTourSecondStep = (dropdownStatus) => {
    return {
        type: SECOND_TOUR_STEP,
        payload: dropdownStatus
    }
}
export const actionTourEnd = () => {
    return {
        type: END_TOUR,
        payload: false
    }
} 